import React from "react";
import { Box, IconButton, Typography } from "@material-ui/core";
// Local
import { EditIcon, FullScreenDialog } from "../../../components";
import {
  connectView,
  employeeReadOnly,
  personalAddress,
  ProfileActions,
} from "../../../state";
import { PersonalAddressForm } from "./forms";

function _PersonalAddressCard({
  actions: { getProfile },
  employeeReadOnly,
  personalAddress: { street, city, state, zip },
}) {
  const [editing, setEditing] = React.useState(false);

  const onClickEdit = React.useCallback(
    e => {
      e.preventDefault();
      setEditing(true);
    },
    [setEditing],
  );

  const onCancelEdit = React.useCallback(() => {
    setEditing(false);
  }, [setEditing]);

  const onCompletedEdit = React.useCallback(() => {
    setEditing(false);
    getProfile();
  }, [setEditing, getProfile]);

  return (
    <Box boxShadow={3} style={{ minHeight: 160, padding: 16, fontSize: 14 }}>
      <Typography
        variant="h6"
        style={{ display: "inline-block", fontSize: 16, marginBottom: 20 }}
      >
        Legal address
      </Typography>
      {!employeeReadOnly && (
        <IconButton
          aria-label="Edit"
          title="Edit"
          style={{ float: "right" }}
          onClick={onClickEdit}
        >
          <EditIcon fontSize="small" color="primary" />
        </IconButton>
      )}
      <br />
      {street ?? ""}
      {street && <br />}
      {city ?? ""}, {state ?? ""} {zip ?? ""}
      <FullScreenDialog onClose={onCancelEdit} open={editing}>
        <PersonalAddressForm
          onCancel={onCancelEdit}
          onComplete={onCompletedEdit}
        />
      </FullScreenDialog>
    </Box>
  );
}

export const PersonalAddressCard = connectView(
  _PersonalAddressCard,
  state => ({
    ...employeeReadOnly(state),
    ...personalAddress(state),
  }),
  [ProfileActions],
);
