import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  theme => ({
    avatar: {
      color: "#fff",
      margin: 16,
      marginBottom: 0,
      height: 80,
      width: 80,
      float: "left",
    },
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    tabIndicator: {
      backgroundColor: theme.palette.primary.light,
    },
    userName: {
      display: "inline-block",
      fontSize: 28,
      marginBottom: 12,
      marginLeft: 16,
      marginTop: 24,
    },
  }),
  {
    classNamePrefix: "ProfilePage",
  },
);
