import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  theme => {
    const mainColor = theme.palette.primary.main;

    return {
      root: {
        display: "flex",
        justifyContent: "center",
      },
      card: {
        backgroundColor: mainColor,
        color: theme.palette.getContrastText(mainColor),
        padding: 36,
        cursor: "pointer",
        borderRadius: 4,
        textAlign: "center",
        minWidth: 356,
        minHeight: 188,
      },
    }
  },
  {
    classNamePrefix: "ChooseProfilePage",
  },
);
