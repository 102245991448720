import { createMuiTheme } from "@material-ui/core";

/**
 * Default theme for the auth area for mobile screens, based on the
 * [Materiaul UI default theme](https://material-ui.com/customization/default-theme/)
 */
export const authMobileTheme = createMuiTheme({
  palette: {
    type: "dark",
    primary: { main: "#ffffff" },
    secondary: { main: "#6FA200" },
  },
  overrides: {
    MuiButton: {
      contained: {
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
      },
    },
    MuiButtonGroup: {
      contained: {
        boxShadow: "none",
        border: "1px solid rgba(0,0,0,0.16)",
      },
    },
  },
});
