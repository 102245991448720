import {
  authDelete,
  authGet,
  authPost,
  authPut,
  getPhoneNumbersOnly,
} from "../../lib";
import { AuthActions } from "../auth/actions";
import { UIActions } from "../ui/actions";

const type = {
  PROFILE_EMCONTACT_CREATE_REQUEST: "PROFILE_EMCONTACT_CREATE_REQUEST",
  PROFILE_EMCONTACT_CREATE_FAILURE: "PROFILE_EMCONTACT_CREATE_FAILURE",
  PROFILE_EMCONTACT_CREATE_SUCCESS: "PROFILE_EMCONTACT_CREATE_SUCCESS",

  PROFILE_EMCONTACT_DELETE_REQUEST: "PROFILE_EMCONTACT_DELETE_REQUEST",
  PROFILE_EMCONTACT_DELETE_FAILURE: "PROFILE_EMCONTACT_DELETE_FAILURE",
  PROFILE_EMCONTACT_DELETE_SUCCESS: "PROFILE_EMCONTACT_DELETE_SUCCESS",

  PROFILE_EMCONTACT_GET_REQUEST: "PROFILE_EMCONTACT_GET_REQUEST",
  PROFILE_EMCONTACT_GET_FAILURE: "PROFILE_EMCONTACT_GET_FAILURE",
  PROFILE_EMCONTACT_GET_SUCCESS: "PROFILE_EMCONTACT_GET_SUCCESS",

  PROFILE_EMCONTACT_UPDATE_REQUEST: "PROFILE_EMCONTACT_UPDATE_REQUEST",
  PROFILE_EMCONTACT_UPDATE_FAILURE: "PROFILE_EMCONTACT_UPDATE_FAILURE",
  PROFILE_EMCONTACT_UPDATE_SUCCESS: "PROFILE_EMCONTACT_UPDATE_SUCCESS",

  PROFILE_EMCONTACT_RELTYPES_REQUEST: "PROFILE_EMCONTACT_RELTYPES_REQUEST",
  PROFILE_EMCONTACT_RELTYPES_FAILURE: "PROFILE_EMCONTACT_RELTYPES_FAILURE",
  PROFILE_EMCONTACT_RELTYPES_SUCCESS: "PROFILE_EMCONTACT_RELTYPES_SUCCESS",

  PROFILE_EMCONTACTS_REQUEST: "PROFILE_EMCONTACTS_REQUEST",
  PROFILE_EMCONTACTS_FAILURE: "PROFILE_EMCONTACTS_FAILURE",
  PROFILE_EMCONTACTS_SUCCESS: "PROFILE_EMCONTACTS_SUCCESS",

  PROFILE_GET_REQUEST: "PROFILE_GET_REQUEST",
  PROFILE_GET_FAILURE: "PROFILE_GET_FAILURE",
  PROFILE_GET_SUCCESS: "PROFILE_GET_SUCCESS",

  PROFILE_ADDRESS_UPDATE_REQUEST: "PROFILE_ADDRESS_UPDATE_REQUEST",
  PROFILE_ADDRESS_UPDATE_FAILURE: "PROFILE_ADDRESS_UPDATE_FAILURE",
  PROFILE_ADDRESS_UPDATE_SUCCESS: "PROFILE_ADDRESS_UPDATE_SUCCESS",

  PROFILE_CONTACT_UPDATE_REQUEST: "PROFILE_CONTACT_UPDATE_REQUEST",
  PROFILE_CONTACT_UPDATE_FAILURE: "PROFILE_CONTACT_UPDATE_FAILURE",
  PROFILE_CONTACT_UPDATE_SUCCESS: "PROFILE_CONTACT_UPDATE_SUCCESS",
};

export const ProfileActions = {
  type,

  changeAccountPassword(data) {
    return async dispatch => {
      dispatch(UIActions.setUILoading(true));
      const resp = await authPut("/api/my/profile/change-password", data);
      dispatch(UIActions.setUILoading(false));
      if (resp.error) {
        dispatch(UIActions.showError());
      }
    };
  },
  /**
   * Creates a new emergency contact.
   * @param {EmergencyContact} emergencyContact
   * @return {{ data:CreateEmergencyContactResult }}
   */
  createEmergencyContact(emergencyContact) {
    emergencyContact = {
      ...emergencyContact,
      homePhone: getPhoneNumbersOnly(emergencyContact.homePhone),
    };
    return async dispatch => {
      /** @type {CreateEmergencyContactData} */
      const postData = {
        emergencyContact,
      };
      dispatch({ type: type.PROFILE_EMCONTACT_CREATE_REQUEST });
      dispatch(UIActions.setUILoading(true));
      const resp = await authPost(`/api/my/emergency-contacts`, postData);
      /** @type {CreateEmergencyContactResult} */
      const data = resp.data;
      dispatch(UIActions.setUILoading(false));
      if (resp.error) {
        dispatch(UIActions.showError());
      }
      dispatch({ type: type.PROFILE_EMCONTACT_CREATE_SUCCESS, data });
      return {
        data,
      };
    };
  },
  /**
   * Deletes a emergency contact.
   * @param {number} id
   */
  deleteEmergencyContact(id) {
    return async dispatch => {
      dispatch(UIActions.setUILoading(true));
      dispatch({ type: type.PROFILE_EMCONTACT_DELETE_REQUEST });
      const resp = await authDelete(`/api/my/emergency-contacts/${id}`);
      dispatch(UIActions.setUILoading(false));
      if (resp.error) {
        dispatch(UIActions.showError());
      }
      dispatch({ type: type.PROFILE_EMCONTACT_DELETE_SUCCESS });
    };
  },
  /**
   * Gets a single emergency contact from the server.
   * @return {{ data:GetEmergencyContactData }}
   */
  getEmergencyContact(id) {
    return async dispatch => {
      dispatch({ type: type.PROFILE_EMCONTACT_GET_REQUEST });
      dispatch(UIActions.setUILoading(true));

      const resp = await authGet(`/api/my/emergency-contacts/${id}`);
      const { data, error } = resp;

      dispatch(UIActions.setUILoading(false));
      if (error) {
        dispatch(UIActions.showError());
      }
      dispatch({
        type: type.PROFILE_EMCONTACT_GET_SUCCESS,
        data,
        error,
      });
      return resp;
    };
  },
  getEmergencyContactRelationshipTypes() {
    return async dispatch => {
      dispatch({ type: type.PROFILE_EMCONTACT_RELTYPES_REQUEST });
      const resp = await authGet(
        "/api/my/emergency-contacts/relationship-types",
      );
      const { data, error } = resp;
      if (error) {
        dispatch(UIActions.showError());
      }
      dispatch({ type: type.PROFILE_EMCONTACT_RELTYPES_SUCCESS, data, error });
      return resp;
    };
  },
  /**
   * @returns {Promise<{ data: EmergencyContactsData }>}
   */
  getEmergencyContacts() {
    return async dispatch => {
      dispatch({ type: type.PROFILE_EMCONTACTS_REQUEST });
      dispatch(UIActions.setUILoading(true));

      // CONSIDER: Maybe cache the list of relationship types.
      dispatch(ProfileActions.getEmergencyContactRelationshipTypes());

      const resp = await authGet("/api/my/emergency-contacts");
      const { data, error } = resp;

      dispatch(UIActions.setUILoading(false));
      if (error) {
        dispatch(UIActions.showError());
      }
      dispatch({ type: type.PROFILE_EMCONTACTS_SUCCESS, data, error });
      return resp;
    };
  },
  /**
   * Updates a emergency contact.
   * @param {EmergencyContact} emergencyContact
   */
  updateEmergencyContact(emergencyContact) {
    emergencyContact = {
      ...emergencyContact,
      homePhone: getPhoneNumbersOnly(emergencyContact.homePhone),
    };
    return async (dispatch, getState) => {
      const { id } = emergencyContact;
      /** @type {{profile:{emergencyContact:EmergencyContact}}} */
      const {
        profile: { emergencyContact: original },
      } = getState();
      /** @type {UpdateEmergencyContactData} */
      const data = {
        original,
        updated: emergencyContact,
      };
      dispatch({ type: type.PROFILE_EMCONTACT_UPDATE_REQUEST });
      dispatch(UIActions.setUILoading(true));
      const resp = await authPut(`/api/my/emergency-contacts/${id}`, data);
      dispatch(UIActions.setUILoading(false));
      if (resp.error) {
        dispatch(UIActions.showError());
      }
      dispatch({ type: type.PROFILE_EMCONTACT_UPDATE_SUCCESS });
    };
  },

  /**
   * @returns {Promise<{ data: Profile }>}
   */
  getProfile() {
    return async dispatch => {
      dispatch({ type: type.PROFILE_GET_REQUEST });
      dispatch(UIActions.setUILoading(true));
      const resp = await authGet("/api/my/profile");
      const { data, error } = resp;
      dispatch(UIActions.setUILoading(false));
      if (error) {
        dispatch(UIActions.showError());
      }
      dispatch({ type: type.PROFILE_GET_SUCCESS, data, error });
      return resp;
    };
  },
  getProfileCount() {
    return async dispatch => {
      const resp = await authGet("/api/my/profile/count");
      const { data, error } = resp;
      if (error) {
        console.error(error);
        return 0;
      }
      dispatch({ type: AuthActions.type.LOGIN_UPDATE, data });
      return data.profileCount;
    };
  },
  getProfilesList() {
    return async dispatch => {
      const resp = await authGet("/api/my/profile/list");
      const { data, error } = resp;
      if (error) {
        console.error(error);
        return [];
      }
      return data.profiles;
    };
  },
  /**
   * @param {ProfileAddress} data
   */
  updateProfileAddress(data) {
    return async dispatch => {
      dispatch({ type: type.PROFILE_ADDRESS_UPDATE_REQUEST });
      dispatch(UIActions.setUILoading(true));
      const resp = await authPut("/api/my/profile/address", data);
      dispatch(UIActions.setUILoading(false));
      if (resp.error) {
        dispatch(UIActions.showError());
      }
      dispatch({ type: type.PROFILE_ADDRESS_UPDATE_SUCCESS });
    };
  },
  /**
   * @param {ProfileContactUpdate} data
   */
  updateProfileContact(data) {
    data = {
      ...data,
      homePhone: getPhoneNumbersOnly(data.homePhone),
      cellPhone: getPhoneNumbersOnly(data.cellPhone),
    };
    return async dispatch => {
      dispatch({ type: type.PROFILE_CONTACT_UPDATE_REQUEST });
      dispatch(UIActions.setUILoading(true));
      const resp = await authPut("/api/my/profile/contact", data);
      dispatch(UIActions.setUILoading(false));
      if (resp.error) {
        dispatch(UIActions.showError());
      }
      dispatch({ type: type.PROFILE_CONTACT_UPDATE_SUCCESS });
    };
  },
};

export const GenderChoices = {
  "0": {
    id: 0,
    text: "Male",
  },
  "1": {
    id: 1,
    text: "Female",
  },
  "3": {
    id: 3,
    text: "Unspecified",
  },
};

/**
 * @typedef {object} EmergencyContactsData
 * @property {EmergencyContact[]} emergencyContacts
 *
 * @typedef {object} EmergencyContact
 * @property {int} id
 * @property {string} name
 * @property {string} email
 * @property {string} homePhone
 * @property {string} relationship
 *
 * @typedef {object} Profile
 * @property {ProfileAddress} address
 * @property {ProfileBio} bio
 * @property {ProfileContact} contact
 *
 * @typedef {object} ProfileAddress
 * @property {string} street
 * @property {string} city
 * @property {string} state
 * @property {string} zip
 *
 * @typedef {object} ProfileBio
 * @property {string} bDay
 * @property {number} gender
 * @property {string} genderText
 * @property {string} hired
 * @property {string} ssn
 *
 * @typedef {object} ProfileContact
 * @property {string} homeEmail
 * @property {string} homePhone
 * @property {string} cellPhone
 *
 * @typedef {object} CreateEmergencyContactData
 * @property {EmergencyContact} emergencyContact
 *
 * @typedef {object} CreateEmergencyContactResult
 * @property {number} id
 *
 * @typedef {object} GetEmergencyContactData
 * @property {EmergencyContact} emergencyContact
 *
 * @typedef {object} LoadEmergencyContactsData
 * @property {EmergencyContact[]} emergencyContacts
 *
 * @typedef {object} UpdateEmergencyContactData
 * @property {EmergencyContact} original
 * @property {EmergencyContact} updated
 *
 */
