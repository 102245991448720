import React from "react";
import {
  Button,
  Card,
  // Checkbox,
  FormControl,
  // FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
// Local
import {
  ButtonSwitch,
  // DialogSaveCancelButtons,
  PopoverHelpIcon,
} from "../../../components";
// import {
//   // boolYesNo,
//   // isYes,
//   // useInputCheck,
//   // useInputValue,
//   // useInputTargetValue,
//   // useOnMount,
//   // useObjectState,
// } from "../../../lib";
import {
  // connectView,
  FilingStatuses,
  // TaxActions,
  // taxesW4,
} from "../../../state";
import { useMobile } from "../../../themes";
import { useStyles } from "./EditW4Form.styles";

const filingStatusMenuItems = Object.keys(FilingStatuses).map(status => (
  <MenuItem key={status} value={status}>
    {FilingStatuses[status]}
  </MenuItem>
));

function _EditW4Form({
  // actions: { getTaxesW4, reviewTaxesW4, updateTaxesW4 },
  // onCancel,
  // onComplete,
  // taxesW4,
  errFields,
  formData,
  setFormProps,
}) {
  const classes = useStyles();
  // #region State
  const {
    filingStatus,
    isExempt,
    multiJobOption2c,
    dependentsAmount,
    otherIncome,
    deductions,
    amountExtra,
    optionalAdj,
    childAmount,
    otherDepAmount,
  } = formData;
  const isMobile = useMobile();
  // #endregion
  // #region Effects
  React.useEffect(() => {
    setFormProps({
      dependentsAmount:
        (parseFloat(childAmount) || 0) + (parseFloat(otherDepAmount) || 0),
    });
  }, [childAmount, otherDepAmount, setFormProps]);

  React.useEffect(() => {
    if (!optionalAdj) {
      setFormProps({
        otherIncome: "0",
        deductions: "0",
        amountExtra: "0",
      });
    }
  }, [optionalAdj, setFormProps]);

  React.useEffect(() => {
    if (
      parseFloat(otherIncome) ||
      parseFloat(deductions) ||
      parseFloat(amountExtra)
    ) {
      setFormProps({ optionalAdj: true });
    }
  }, [otherIncome, deductions, amountExtra, setFormProps]);
  // #endregion
  // #region Events

  const onChangeFilingStatus = React.useCallback(
    e => setFormProps({ filingStatus: e.target.value }),
    [setFormProps],
  );
  const onChangeIsExempt = React.useCallback(
    e => setFormProps({ isExempt: e.currentTarget.value }),
    [setFormProps],
  );
  const onChangeMultiJobOption2c = React.useCallback(
    e => setFormProps({ multiJobOption2c: e.currentTarget.value }),
    [setFormProps],
  );
  const onChangeChildAmount = React.useCallback(
    e => setFormProps({ childAmount: e.currentTarget.value }),
    [setFormProps],
  );
  const onChangeOtherDepAmount = React.useCallback(
    e => setFormProps({ otherDepAmount: e.currentTarget.value }),
    [setFormProps],
  );
  const onChangeDependentsAmount = React.useCallback(
    e => setFormProps({ dependentsAmount: e.currentTarget.value }),
    [setFormProps],
  );
  const onChangeOptionalAdj = React.useCallback(
    e => setFormProps({ optionalAdj: e.currentTarget.checked }),
    [setFormProps],
  );
  const onChangeOtherIncome = React.useCallback(
    e => setFormProps({ otherIncome: e.currentTarget.value }),
    [setFormProps],
  );
  const onChangeDeductions = React.useCallback(
    e => setFormProps({ deductions: e.currentTarget.value }),
    [setFormProps],
  );
  const onChangeAmountExtra = React.useCallback(
    e => setFormProps({ amountExtra: e.currentTarget.value }),
    [setFormProps],
  );

  // #endregion

  // console.log("FORM DATA: ", formData);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body1">
          Need help filling this out? Instructions can be found on form W-4
          <br />
          <a
            href="https://www.irs.gov/pub/irs-pdf/fw4.pdf"
            target="BrandsW4Help"
            style={{ textDecoration: "none" }}
          >
            View instructions
          </a>
          {" or use the "}
          <a
            href="https://www.irs.gov/individuals/tax-withholding-estimator"
            target="BrandsW4Estimator"
            style={{ textDecoration: "none" }}
          >
            IRS Withholding Estimator
          </a>
          .
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="filing-status">Filing status</InputLabel>
          <Select
            value={filingStatus}
            onChange={onChangeFilingStatus}
            autoFocus={!isMobile}
          >
            {filingStatusMenuItems}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.formControl}>
          <InputLabel
            style={{
              marginBottom: 10,
              display: "flex",
              alignItems: "center",
            }}
          >
            <span>Exempt from federal tax withholdings?</span>
            <PopoverHelpIcon id="exempt-help" children={exemptHelp} />
          </InputLabel>
          <ButtonSwitch
            aria-label="Exemption choice"
            value={isExempt}
            onChange={onChangeIsExempt}
          >
            <Button value="yes">Yes</Button>
            <Button value="no">No</Button>
          </ButtonSwitch>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.formControl}>
          <InputLabel
            style={{
              marginBottom: 10,
              display: "flex",
              alignItems: "center",
            }}
          >
            <span>
              Multiple Job Tax Rate -{" "}
              <small>
                <em>Step 2 option (c)</em>
              </small>
            </span>
            <PopoverHelpIcon id="exempt-help" children={multijobHelp} />
          </InputLabel>
          <ButtonSwitch
            aria-label="Multiple Job Tax Rate - Step 2 option (c)"
            value={multiJobOption2c}
            onChange={onChangeMultiJobOption2c}
          >
            <Button value="yes">Yes</Button>
            <Button value="no">No</Button>
          </ButtonSwitch>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Card className={classes.card1}>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <div
              style={{
                marginTop: -10,
                marginBottom: 10,
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography variant="subtitle1" style={{ marginTop: 6 }}>
                Claim Dependents
              </Typography>
              <PopoverHelpIcon id="addtl-help" children={dependentsHelp} />
            </div>
            <FormLabel style={{ fontSize: 12, margin: 10 }}>
              Multiply the number of qualifying children under age 17 by $2,000
            </FormLabel>
            <TextField
              label=""
              className={classes.formControlRight}
              value={childAmount}
              onChange={onChangeChildAmount}
              type="number"
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
            <FormLabel style={{ fontSize: 12, margin: 10 }}>
              Multiply the number of other dependents by $500
            </FormLabel>
            <TextField
              label=""
              className={classes.formControlRight}
              value={otherDepAmount}
              onChange={onChangeOtherDepAmount}
              type="number"
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </div>
          <TextField
            label="Dependents Tax Credit"
            value={dependentsAmount}
            onChange={onChangeDependentsAmount}
            type="number"
            className={classes.formControl}
            style={{ marginTop: 0 }}
            margin="normal"
            error={!!errFields.dependentsAmount}
            helperText={errFields.dependentsAmount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.card2}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="subtitle1" style={{ marginTop: 6 }}>
              Other Adjustments (optional)
            </Typography>
            <Switch checked={optionalAdj} onChange={onChangeOptionalAdj} />
          </div>
          {/* <div style={{ display: optionalAdj ? "block" : "none" }}> */}
          <div>
            <TextField
              label="Other Income"
              value={otherIncome}
              onChange={onChangeOtherIncome}
              type="number"
              className={classes.formControl}
              margin="normal"
              error={!!errFields.otherIncome}
              helperText={errFields.otherIncome}
              // disabled={!optionalAdj}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <PopoverHelpIcon
                      id="addtl-help"
                      children={otherIncomeHelp}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Deductions"
              value={deductions}
              onChange={onChangeDeductions}
              type="number"
              className={classes.formControl}
              margin="normal"
              error={!!errFields.deductions}
              helperText={errFields.deductions}
              // disabled={!optionalAdj}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <PopoverHelpIcon
                      id="addtl-help"
                      children={deductionsHelp}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Additional amount withheld"
              value={amountExtra}
              onChange={onChangeAmountExtra}
              type="number"
              className={classes.formControl}
              margin="normal"
              error={!!errFields.amountExtra}
              helperText={errFields.amountExtra}
              // disabled={!optionalAdj}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <PopoverHelpIcon id="addtl-help" children={addtlHelp} />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <p>&nbsp;</p>
      </Grid>
    </>
  );
}

export const EditW4Form = React.memo(_EditW4Form);

const addtlHelp = (
  <Typography variant="caption">
    <strong>Step 4 (c)</strong> Enter any additional tax you want withheld each{" "}
    <strong>pay period</strong>.
  </Typography>
);

const dependentsHelp = (
  <>
    <Typography variant="caption">
      <strong>Step 3</strong> If your income will be $200,000 or less ($400,000
      or less if married filing jointly):
    </Typography>
    <ul>
      <li>
        <Typography variant="caption">
          Multiply the number of qualifying children under age 17 by $2,000.
        </Typography>
      </li>
      <li>
        <Typography variant="caption">
          Multiply the number of other dependents by $500.
        </Typography>
      </li>
      <li>
        <Typography variant="caption">
          Add the amounts and enter the total here.
        </Typography>
      </li>
    </ul>
  </>
);

const exemptHelp = (
  <>
    <Typography variant="caption">
      <strong>Exemption from withholding.</strong> You may claim exemption from
      withholding if you meet both of the following conditions:
    </Typography>
    <ul>
      <li>
        <Typography variant="caption">
          You had no federal income tax liability last year.
        </Typography>
      </li>
      <li>
        <Typography variant="caption">
          You expect to have no federal income tax liability this year.
        </Typography>
      </li>
    </ul>
  </>
);

const multijobHelp = (
  <>
    <Typography variant="caption">
      <strong>Step 2 (c)</strong> Complete this step if you:
    </Typography>
    <ol>
      <li>
        <Typography variant="caption">
          hold more than one job at a time, or
        </Typography>
      </li>
      <li>
        <Typography variant="caption">
          are married filing jointly and your spouse also works. The correct
          amount of withholding depends on income earned from all of these jobs.
        </Typography>
      </li>
    </ol>
    <Typography variant="caption">
      If there are only two jobs total, you may answer <strong>Yes</strong>. Do
      the same on Form W-4 for the other job. This option is accurate for jobs
      with similar pay; otherwise, more tax than necessary may be withheld.
    </Typography>
  </>
);

const otherIncomeHelp = (
  <Typography variant="caption">
    <strong>Step 4 (a)</strong> Other income (not from jobs). If you want tax
    withheld for other income you expect this year that won’t have withholding,
    enter the amount of other income here. This may include interest, dividends,
    and retirement income.
  </Typography>
);

const deductionsHelp = (
  <Typography variant="caption">
    <strong>Step 4 (b)</strong> If you expect to claim deductions other than the
    standard deduction and want to reduce your withholding, use the Deductions
    Worksheet on page 3 of the linked instructions above and enter the result
    here.
  </Typography>
);
