import React from "react";
import { Box, IconButton, Typography } from "@material-ui/core";
// Local
import { EditIcon, FullScreenDialog } from "../../../components";
import { formatPhone } from "../../../lib";
import {
  connectView,
  employeeReadOnly,
  personalContact,
  ProfileActions,
} from "../../../state";
import { ContactForm } from "./forms";

function _PersonalContactCard({
  actions: { getProfile },
  employeeReadOnly,
  personalContact: { homeEmail, homePhone, cellPhone },
}) {
  const [editing, setEditing] = React.useState(false);

  const onClickEdit = React.useCallback(
    e => {
      e.preventDefault();
      setEditing(true);
    },
    [setEditing],
  );

  const onCancelEdit = React.useCallback(() => {
    setEditing(false);
  }, [setEditing]);

  const onCompletedEdit = React.useCallback(() => {
    setEditing(false);
    getProfile();
  }, [setEditing, getProfile]);

  return (
    <Box boxShadow={3} style={{ minHeight: 160, padding: 16, fontSize: 14 }}>
      <Typography
        variant="h6"
        style={{ display: "inline-block", fontSize: 16, marginBottom: 20 }}
      >
        Contact info
      </Typography>
      {!employeeReadOnly && (
        <IconButton
          aria-label="Edit"
          title="Edit"
          style={{ float: "right" }}
          onClick={onClickEdit}
        >
          <EditIcon fontSize="small" color="primary" />
        </IconButton>
      )}
      <br />
      <span style={{ color: "rgba(0,0,0,0.38)", marginRight: 38 }}>Email</span>
      {homeEmail ?? ""}
      <div style={{ height: 10 }}>&nbsp;</div>
      <span style={{ color: "rgba(0,0,0,0.38)", marginRight: 32 }}>Phone</span>
      {formatPhone(homePhone)}
      <div style={{ height: 10 }}>&nbsp;</div>
      <span style={{ color: "rgba(0,0,0,0.38)", marginRight: 30 }}>Mobile</span>
      {formatPhone(cellPhone)}
      <FullScreenDialog onClose={onCancelEdit} open={editing}>
        <ContactForm onCancel={onCancelEdit} onComplete={onCompletedEdit} />
      </FullScreenDialog>
    </Box>
  );
}

export const PersonalContactCard = connectView(
  _PersonalContactCard,
  state => ({
    ...employeeReadOnly(state),
    ...personalContact(state),
  }),
  [ProfileActions],
);
