import { makeStyles } from "@material-ui/core";

const dataCell = {
  display: "inline-block",
  paddingTop: 16,
  paddingBottom: 6,
};

const mdataCell = {
  display: "inline-block",
};

export const useStyles = makeStyles(
  theme => ({
    button: {
      color: theme.palette.primary.light,
    },
    titlebar: {
      display: "flex",
      justifyContent: "space-between",
      "& h4": {
        display: "inline-block",
        padding: 0,
      },
    },
    titleCtrl: {
      display: "inline-block",
      marginTop: 10,
      "& button": {
        marginLeft: 10,
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: -5,
      },
    },
    title: {
      fontSize: 28,
      margin: 0,
      [theme.breakpoints.up("sm")]: {
        marginTop: 12,
      },
    },
    filtersDesktop: {
      display: "flex",
      justifyContent: "flex-start",
      // alignContent: "center",
      alignItems: "baseline",
      paddingBottom: 38,
    },
    money: {
      color: theme.palette.secondary.light,
    },
    label: {
      color: theme.palette.grey[700],
    },

    mdataRowCard: {},

    mdataRowBox: {
      borderRadius: 3,
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    mdataCellIcon: {
      ...mdataCell,
      display: "flex",
      alignItems: "end",
      marginRight: 12,
    },

    mdataCellRow2: {
      display: "grid",
      gridTemplateColumns: "45px auto 1fr 1fr",
    },
    mdataCellHours: {
      paddingRight: 16,
    },
    mdataCellTakeHome: {
      borderLeft: "1px solid #aaaaaa",
      borderRight: "1px solid #aaaaaa",
      paddingLeft: 16,
      paddingRight: 16,
      textAlign: "center",
    },
    mdataCellButtons: {
      display: "inline-block",
    },

    mdataCellGross: {
      paddingLeft: 16,
    },

    // ## Desktop:

    dataCell,
  }),
  {
    classNamePrefix: "PayHistoryPageTableSmall",
  },
);
