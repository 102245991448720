import { TimeKeepingPage } from "./TimeKeepingPage";

export const TimeKeepingPages = {
    index: {
        anon: false,
        path: "/time-keeping",
        title: "Time Keeping",
        view: TimeKeepingPage,
        type: "PAGE_TIME_KEEPING"
    },
};
export default TimeKeepingPage;

export const TimeKeepingArea = {
    pages: TimeKeepingPages,
};
