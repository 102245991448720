import { AuthActions } from "../auth/actions";
import { PayHistoryActions } from "./actions";
/**
 * Pay History state.
 * @example
 * {
 *  details: {
 *   id: "51-35-152-1",
 *   prNum: 152,
 *   checkDate: "2019-08-06T00:00:00",
 *   checkType: "NORMAL",
 *   hours: 40,
 *   gross: 640,
 *   deds: 75,
 *   taxes: 83.66,
 *   dirDeposit: 0,
 *   net: 481.34,
 *   checkAmount: 481.34,
 *   checkNumber: 140,
 *   checkCounter: 1,
 *   rate: 50.00,
 *   ytdGross: 8500.00,
 *   ytdNet: 7333.86,
 *   deductions: [{PayHistoryDetailsDeds}, ...],
 *   taxDetails: [{PayHistoryDetailsTaxYTD}, ...],
 *  },
 *  list: [
 *    {
 *     id: "51-35-152-1",
 *     prNum: 152,
 *     checkDate: "2019-08-06T00:00:00",
 *     checkType: "NORMAL",
 *     hours: 40,
 *     gross: 640,
 *     deds: 75,
 *     taxes: 83.66,
 *     dirDeposit: 0,
 *     net: 481.34,
 *     checkAmount: 481.34,
 *     checkNumber: 140,
 *     checkCounter: 1,
 *    },
 *    ...
 *  ]
 * }
 */
export const PayHistoryState = {
  name: "payHist",
  persist: true,
  defaults: {},
  handlers: {
    [AuthActions.type.LOGOUT_SUCCESS](state, action) {
      return PayHistoryState.defaults;
    },
    [PayHistoryActions.type.PAYHIST_GET_SUCCESS](state, action) {
      if (action.error) {
        return state;
      }
      const { data } = action;
      return {
        ...state,
        details: {
          ...data,
        },
      };
    },
    /**
     * @param {any} state
     * @param {{
     *    data:import("./actions").ListPayHistoryData,
     *    start:number
     * }} action
     */
    [PayHistoryActions.type.PAYHIST_LIST_SUCCESS](state, action) {
      if (action.error) {
        return state;
      }
      const {
        data: { maybeHasMore, payHist = [] },
        start,
      } = action;
      const { list = [] } = state;
      return {
        ...state,
        // Append to list if loading more, else start fresh list.
        list: start > 0 ? [...list, ...payHist] : payHist,
        maybeHasMore,
        // Setup the start row for the next page of results.
        // No more items? Reload from the start.
        start: !maybeHasMore ? 0 : start + payHist.length,
      };
    },
  },
};
