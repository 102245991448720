import { TaxWithholdingsPage } from "./TaxWithholdingsPage";

export const TaxWithholdingsPages = {
  index: {
    anon: false,
    path: "/tax-withholdings",
    title: "Tax Withholdings",
    type: "PAGE_TAXWITHHOLDINGS",
    view: TaxWithholdingsPage,
    nonContractors: true,
  },
};
export default TaxWithholdingsPages;

export const TaxWithholdingsArea = {
  pages: TaxWithholdingsPages,
};
