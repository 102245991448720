import { AuthActions } from "../auth/actions";
import { ProfileActions } from "./actions";
/**
 * Profile state.
 * @example
 * {
 *  address: {
 *    street: "123 John St 3A",
 *    city: "Brooklyn",
 *    state: "NY",
 *    zip: "11206"
 *  },
 *  bio: {
 *    bDay: "1974-01-05T00:00:00",
 *    gender: 0,
 *    genderText: "Male",
 *    ssn: "584-98-5631",
 *    hired: "1979-01-03T00:00:00"
 *  },
 *  contact: {
 *    homeEmail: "mendyb@brandspaycheck.com",
 *    homePhone: "9174998598",
 *    cellPhone: "3479871639"
 *  }
 *  emergencyContact: {
 *    id: 2013234
 *    name: "Full Name",
 *    email: "email@address.com",
 *    homePhone: "2125230342",
 *    relationship: "Wife",
 *  },
 *  emergencyContactRelationshipTypes: ["Wife", "Husband", ...],
 *  emergencyContacts: [
 *    {
 *       id: 2013234
 *       name: "Full Name",
 *       email: "email@address.com",
 *       homePhone: "2125230342",
 *       relationship: "Wife",
 *    }
 *  ],
 * }
 */
export const ProfileState = {
  name: "profile",
  persist: true,
  defaults: {},
  handlers: {
    [AuthActions.type.LOGOUT_SUCCESS](state, action) {
      return ProfileState.defaults;
    },
    [ProfileActions.type.PROFILE_EMCONTACT_GET_SUCCESS](
      state,
      { data, error },
    ) {
      if (error) {
        return state;
      }
      return {
        ...state,
        ...data,
      };
    },
    [ProfileActions.type.PROFILE_EMCONTACT_RELTYPES_SUCCESS](
      state,
      { data, error },
    ) {
      if (error) {
        return state;
      }
      return {
        ...state,
        ...data,
      };
    },
    [ProfileActions.type.PROFILE_EMCONTACTS_SUCCESS](state, { data, error }) {
      if (error) {
        return state;
      }
      return {
        ...state,
        ...data,
      };
    },
    [ProfileActions.type.PROFILE_GET_SUCCESS](state, { data, error }) {
      if (error) {
        return state;
      }
      return {
        ...state,
        ...data,
      };
    },
  },
};
