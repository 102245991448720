import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  theme => ({
    formControl: {
      marginTop: 35,
      minWidth: 120,
      width: "100%",
    },
  }),
  {
    classNamePrefix: "SignStateW4",
  },
);
