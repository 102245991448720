/**
 * Returns an `onChange` handler that calls the given updater function with the
 * boolean `checked` value.
 * @param {(value:boolean)=>void} updater
 */
export function handleCheck(updater) {
  /** @param {import("react").SyntheticEvent<HTMLInputElement>} e */
  function onChange(e) {
    updater(e.currentTarget.checked);
  }
  return onChange;
}
/**
 * Returns an `onChange` handler that calls the given updater function with the
 * input `value`.
 * @param {(value:any)=>void} updater
 */
export function handleInputValue(updater) {
  /** @param {import("react").SyntheticEvent<HTMLInputElement>} e */
  function onChange(e) {
    updater(e.currentTarget.value);
  }
  return onChange;
}
