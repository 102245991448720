import React from "react";
import { Grid, FormLabel } from "@material-ui/core";
// Local
import { isYes, useOnMount } from "../../../lib";
import { getStateTaxLocals } from "../../../state";
import { useMobile } from "../../../themes";

// window.PDFJS.workerSrc = process.env.PUBLIC_URL + "/pdf.worker.js";

function _ReviewStateW4({
  actions: { reviewStateTaxesW4 },
  taxesWorkState,
  formData,
}) {
  const isMobile = useMobile("sm");
  const [downloadURL, setDownloadURL] = React.useState();

  async function getDownloadURL() {
    const result = await reviewStateTaxesW4({
      stateAbbr: taxesWorkState.stateAbbr,
      filingStatus: formData.filingStatus,
      isExempt: isYes(formData.isExempt),
      dependents: formData.dependents,
      amountExtra: formData.amountExtra,
      locals: getStateTaxLocals(taxesWorkState, formData),
    });
    if (!result.error) {
      setDownloadURL(result.downloadURL);
    }
  }

  useOnMount(() => {
    getDownloadURL();
  });

  return (
    <Grid
      item
      xs={12}
      style={{
        textAlign: "center",
        height: "100%",
      }}
    >
      <FormLabel>REVIEW YOUR W4</FormLabel>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          paddingTop: 20,
        }}
      >
        {!downloadURL ? (
          "Loading..."
        ) : (
          <object
            type="application/pdf"
            data={downloadURL + "#toolbar=0&view=FitH&page=1"}
            title="Review W4"
            style={{
              width: isMobile ? "100%" : "200%",
              height: "100%",
            }}
          />
        )}
      </div>
    </Grid>
  );
}

export const ReviewStateW4 = React.memo(_ReviewStateW4);
