import { makeStyles } from "@material-ui/core";

// export const useStyles = makeStyles(
//   theme => ({
//     table: {
//       //
//     },
//   }),
//   {
//     classNamePrefix: "AccountsTableMobile",
//   },
// );

export const useRowStyles = makeStyles(
  theme => ({
    root: {
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
      marginTop: 16,
      paddingBottom: 10,
    },
    header: {
      display: "flex",
      alignItems: "space-between",
      paddingBottom: 6,
    },
    headerAmount: {
      flex: 8,
      marginTop: 10,
      color: "#6FA200",
      whiteSpace: "nowrap",
      display: "flex",
      alignItems: "center",
    },
    headerTools: {
      flex: 1,
      whiteSpace: "nowrap",
      "& button": {
        padding: 6,
      },
    },
    accountName: {
      whiteSpace: "nowrap",
      paddingBottom: 6,
    },
    bankAndAccountNum: {
      whiteSpace: "nowrap",
      paddingBottom: 6,
    },
    amountChip: {
      marginLeft: 8,
    },
    amountText: {
      minWidth: 50,
      wordWrap: "break-word",
    },
  }),
  {
    classNamePrefix: "AccountRowMobile",
  },
);
