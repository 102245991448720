const Environments = {
  development: "development",
};

let {
  NODE_ENV = Environments.development,
  REACT_APP_API_URL,
  REACT_APP_COMPANY_SITE_URL,
  REACT_APP_ERRORS_DSN,
  /** True to report errors during development. */
  REACT_APP_ERRORS_DEV,
  REACT_APP_PERSIST_KEY,
  REACT_APP_PROD_HOSTNAME,
  REACT_APP_SITE_TITLE,
  REACT_APP_VERSION,
} = process.env;

const __DEV__ = NODE_ENV === Environments.development;
const __PROD__ = window.location.hostname === REACT_APP_PROD_HOSTNAME;

REACT_APP_ERRORS_DEV = REACT_APP_ERRORS_DEV === "true";

export {
  __DEV__,
  __PROD__,
  NODE_ENV,
  REACT_APP_API_URL,
  REACT_APP_COMPANY_SITE_URL,
  REACT_APP_ERRORS_DSN,
  REACT_APP_ERRORS_DEV,
  REACT_APP_PERSIST_KEY,
  REACT_APP_PROD_HOSTNAME,
  REACT_APP_SITE_TITLE,
  REACT_APP_VERSION,
};
