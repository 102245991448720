import { AnnualDocumentsPage } from "./AnnualDocumentsPage";

export const DocumentsPages = {
  index: {
    anon: false,
    path: "/documents/:year?",
    title: "Annual Documents",
    type: "PAGE_DOCUMENTS_ANNUALS",
    view: AnnualDocumentsPage,
  },
};
export default DocumentsPages;

export const DocumentsArea = {
  pages: DocumentsPages,
};
