import { authGet, authPost, Navigation } from "../../lib";
import { REACT_APP_API_URL } from "../../config";
import { UIActions } from "../ui/actions";

const type = {
  PAYHIST_GET_REQUEST: "PAYHIST_GET_REQUEST",
  PAYHIST_GET_FAILURE: "PAYHIST_GET_FAILURE",
  PAYHIST_GET_SUCCESS: "PAYHIST_GET_SUCCESS",

  PAYHIST_LIST_REQUEST: "PAYHIST_LIST_REQUEST",
  PAYHIST_LIST_FAILURE: "PAYHIST_LIST_FAILURE",
  PAYHIST_LIST_SUCCESS: "PAYHIST_LIST_SUCCESS",
};

/** EssPayType enum, as represented in a query string. */
const PayTypeString = {
  all: "0",
  pay: "1",
  other: "2",
};

export const PayHistoryActions = {
  type,

  downloadPayHistoryDocument(id) {
    return async dispatch => {
      const resp = await authPost(`/api/my/pay-history/${id}/download`);
      /** @type {{downloadURL:string}} */
      const data = resp.data;
      if (resp.error) {
        dispatch(
          UIActions.showError(
            "The Pay stub isn't available yet.\n" +
              "Please check back in a few minutes.",
          ),
        );
        return;
      }
      const downloadURL = `${REACT_APP_API_URL}${data.downloadURL}`;
      var opened = window.open(downloadURL, "_blank");
      // WORKAROUND: Window not opening due to popup blocking (Safari/iOS),
      // perhaps only when downloading from a different URL/port.
      // Navigating to the download URL in the same window seems to work.
      if (!opened) {
        window.location.assign(downloadURL);
      }
    };
  },

  getPayHistory(id) {
    return async dispatch => {
      dispatch({ type: type.PAYHIST_GET_REQUEST });
      dispatch(UIActions.setUILoading(true));
      const resp = await authGet(`/api/my/pay-history/${id}`);
      const { data, error } = resp;
      dispatch(UIActions.setUILoading(false));
      if (error) {
        dispatch(UIActions.showError());
      }
      dispatch({
        type: type.PAYHIST_GET_SUCCESS,
        data,
        error,
      });
      return resp;
    };
  },

  listPayHistory(reload, search) {
    return async (dispatch, getState) => {
      let {
        payHist: { start = 0 },
      } = getState();
      if (reload) {
        start = 0;
      }
      let searchStr = "";
      if (search) {
        const payType = payTypeParam(search);
        if (payType !== PayTypeString.all) {
          searchStr += `&payType=${payType}`;
        }
        if (search.fromDate) {
          searchStr += `&fromDate=${search.fromDate.toISOString()}`;
        }
        if (search.toDate) {
          searchStr += `&toDate=${search.toDate.toISOString()}`;
        }
        const navSearchStr = "?" + searchStr.substr(1);
        if (navSearchStr !== Navigation.location.search) {
          Navigation.redirect(`/pay-history/${navSearchStr}`);
        }
      }
      dispatch({ type: type.PAYHIST_LIST_REQUEST });
      dispatch(UIActions.setUILoading(true));
      const resp = await authGet(
        `/api/my/pay-history?start=${start}${searchStr}`,
      );
      const { data, error } = resp;
      dispatch(UIActions.setUILoading(false));
      if (error) {
        dispatch(UIActions.showError());
      }
      dispatch({
        type: type.PAYHIST_LIST_SUCCESS,
        data,
        start,
        error,
      });
      return resp;
    };
  },
};

/**
 * Converts pay type query string param value to boolean.
 * @param {string} value
 * @param {"all" | "other" | "pay"} type
 */
export function payTypeBool(value, type) {
  return !value || value === PayTypeString.all || value === PayTypeString[type];
}
/**
 * Converts boolean values to a pay type query string param value.
 * @param {{pay:boolean,other:boolean}} values
 */
export function payTypeParam(values) {
  return values.pay && values.other
    ? PayTypeString.all
    : values.pay
    ? PayTypeString.pay
    : values.other
    ? PayTypeString.other
    : PayTypeString.all;
}

/**
 * @typedef {object} PayHistoryDetails
 * @property {string} id
 * @property {number} prNum
 * @property {string} checkDate
 * @property {string} checkType
 * @property {number} hours
 * @property {number} gross
 * @property {number} deds
 * @property {number} taxes
 * @property {number} dirDeposit
 * @property {number} net
 * @property {number} checkAmount
 * @property {number} checkNumber
 * @property {number} checkCounter
 * @property {number} rate
 * @property {number} ytdGross
 * @property {number} ytdNet
 * @property {PayHistoryDetailsDeds[]} deductions
 * @property {PayHistoryDetailsTaxYTD[]} taxDetails
 *
 * @typedef {object} PayHistoryDetailsDeds
 * @property {number} prNum Example: 152
 * @property {number} chkCounter Example: 1
 * @property {number} sort Example: 1
 * @property {string} descr Example: "10-Declining Loan"
 * @property {number} amount Example: 7
 *
 * @typedef {object} PayHistoryDetailsTaxYTD
 * @property {number} prNum
 * @property {number} chkCounter
 * @property {number} gross
 * @property {string} descr
 * @property {number} taxable
 * @property {number} current
 * @property {number} yTD
 * @property {number} type
 * @property {string} abbrv
 * @property {string} cat
 * @property {number} yTDNet
 * @property {number} rowNum
 *
 * @typedef {object} PayHistoryItem
 * @property {string} id
 * @property {number} prNum
 * @property {string} checkDate
 * @property {string} checkType
 * @property {number} hours
 * @property {number} gross
 * @property {number} deds
 * @property {number} taxes
 * @property {number} dirDeposit
 * @property {number} net
 * @property {number} checkAmount
 * @property {number} checkNumber
 * @property {number} checkCounter
 * @property {"Regular" | "Other"} type
 *
 * @typedef {object} ListPayHistoryData
 * @property {boolean} maybeHasMore
 * @property {PayHistoryItem[]} payHist
 *
 */
