import React from "react";
import { Box, Typography } from "@material-ui/core";

export const TabInfo = {
  personal: {
    idx: 0,
    path: undefined,
  },
  contacts: {
    idx: 1,
    path: "contacts",
  },
  security: {
    idx: 2,
    path: "security",
  },
};

export const TabIndex = Object.keys(TabInfo).reduce(
  (obj, key) => ({
    ...obj,
    [TabInfo[key].idx]: key,
  }),
  {},
);

export function tabA11yProps(index) {
  return {
    id: `profile-tab-${index}`,
    "aria-controls": `profile-tabpanel-${index}`,
  };
}

export function TabPanel(props) {
  const { children, index, showing, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={!showing}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}
