import React from "react";
import { Grid, Typography } from "@material-ui/core";
// Local
import { HidePaySwitch } from "../../components";
import { useOnMount } from "../../lib";
import { connectView, TaxActions, taxesWorkState } from "../../state";
import { useStyles } from "./TaxWithholdingsPage.styles";
import { FederalTaxCard } from "./federal";
import { StateTaxCard } from "./state";

function _TaxWithholdingsPage({ actions: { getTaxesW4 }, taxesWorkState }) {
  const classes = useStyles();

  useOnMount(() => {
    getTaxesW4();
  });
  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={classes.titlebar}>
          <Typography className={classes.title} variant="h4">
            Tax withholdings - W4
          </Typography>
          <HidePaySwitch />
        </div>
      </Grid>
      <Grid item lg={5} xs={12}>
        <FederalTaxCard classes={classes} />
      </Grid>
      <Grid item lg={1} xs={12}>
        &nbsp;
      </Grid>
      <Grid item lg={5} xs={12}>
        {taxesWorkState && <StateTaxCard classes={classes} />}
      </Grid>
    </Grid>
  );
}

export const TaxWithholdingsPage = connectView(
  _TaxWithholdingsPage,
  taxesWorkState,
  [TaxActions],
);
