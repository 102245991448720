import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  theme => ({
    formControl: {
      margin: theme.spacing(1),
      marginTop: 35,
      minWidth: 120,
    },
    formControl100: {
      margin: theme.spacing(1),
      marginTop: 35,
      minWidth: 120,
      width: "100%",
    },
    largerBtn: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 40,
      paddingRight: 40,
    },
  }),
  {
    classNamePrefix: "ContactForm",
  },
);
