import React from "react";
import { Box, Button, Divider, Fab, Grid, Typography } from "@material-ui/core";
// Local
import {
  AddIcon,
  ErrorIcon,
  FullScreenDialog,
  HidePaySwitch,
} from "../../components";
import { addIf, useOnMount } from "../../lib";
import {
  connectView,
  DDActions,
  DDErrors,
  ddAccounts,
  ddAccountsAllDisabled,
  ddAccountsAnyDisabledByPriority,
  ddAccountsTotalPercent,
  employeeReadOnly,
  nextDDAccountId,
  uiLoading,
  UIActions,
} from "../../state";
import { useMobile } from "../../themes";
import { useStyles } from "./AccountsPage.styles";
import {
  AccountsTable,
  AccountsTableMobile,
  EditAccountForm,
  EditAmountsForm,
  ReorderAccountsForm,
} from "./components";

const err_enable_accounts =
  'Any accounts listed after "Entire remaining net" are automatically ' +
  "disabled. To activate the account, click Prioritize below to reorder the " +
  "accounts.Then select the account and click Enable.";

/**
 * @typedef {object} AccountsPageProps
 * @property {import("../../state").DDAccount[]} ddAccounts
 * @property {(value:number)=> string} renderAmount
 *
 * @param {AccountsPageProps} props
 */
function _AccountsPage({
  actions: { deleteDDAccount, listDDAccounts, showNotification },
  ddAccounts,
  ddAccountsAllDisabled,
  ddAccountsAnyDisabledByPriority,
  ddAccountsTotalPercent,
  employeeReadOnly,
  nextDDAccountId,
  uiLoading,
}) {
  const classes = useStyles();
  const isMobile = useMobile();
  const AccountsTableComponent = isMobile ? AccountsTableMobile : AccountsTable;
  // #region State
  const [error, setError] = React.useState("");
  const [editingAmounts, editAmounts] = React.useState(false);
  /** @type {[number|null,(id:number|null)=>void]} */
  const [editingAccountId, editAccountId] = React.useState(null);
  const editingAccount = editingAccountId !== null;
  const [reorderingAccounts, reorderAccounts] = React.useState(false);

  React.useEffect(() => {
    if (ddAccountsTotalPercent < 1 && !ddAccountsAllDisabled) {
      setError(DDErrors.accountPercentLessThan100);
    } else if (ddAccountsAnyDisabledByPriority) {
      setError(err_enable_accounts);
    } else {
      setError("");
    }
  }, [
    ddAccountsAllDisabled,
    ddAccountsAnyDisabledByPriority,
    ddAccountsTotalPercent,
    setError,
  ]);
  // #endregion
  // #region Input Handlers
  /** @param {import("../../state").DDAccount} account */
  async function onDelete(account) {
    if (
      !window.confirm(
        "Are you sure that you want to delete the following account?\n\n" +
          addIf(account.bankName, " / ") +
          account.accountType +
          " - " +
          account.accountNumber +
          (account.name ? " - " + account.name : ""),
      )
    ) {
      return;
    }
    await deleteDDAccount(account.id);
    listDDAccounts();
  }
  /** @param {import("../../state").DDAccount} account */
  function onEdit(account) {
    editAccountId(account.id);
  }
  function onClickCreateAccount() {
    if (ddAccounts && ddAccounts.length >= 4) {
      showNotification("Limit of 4 accounts reached.");
      return;
    }
    editAccountId(0);
  }
  function onCancelEditAccount() {
    editAccountId(null);
  }
  function onClickEditAmounts() {
    editAmounts(true);
  }
  function onCancelEditAmounts() {
    editAmounts(false);
  }
  function onAmountsUpdated() {
    editAmounts(false);
    listDDAccounts();
  }
  function onAccountsUpdated() {
    editAccountId(null);
    listDDAccounts();
  }
  function onCancelReorderAccounts() {
    reorderAccounts(false);
  }
  function onReorderedAccounts() {
    reorderAccounts(false);
    listDDAccounts();
  }
  function onClickReorderAccounts() {
    reorderAccounts(true);
  }
  // #endregion
  // #region Lifecycle Events
  useOnMount(() => {
    listDDAccounts();
  });
  // #endregion
  return (
    <Grid container>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <div className={classes.titlebar}>
            <Typography className={classes.title} variant="h4">
              Direct deposit
            </Typography>
            <HidePaySwitch />
          </div>
          <Box className={classes.divider}>
            <Divider />
          </Box>
          {!employeeReadOnly && (
            <Fab
              color="secondary"
              aria-label="Add"
              className={classes.fab}
              onClick={onClickCreateAccount}
            >
              <AddIcon />
            </Fab>
          )}
        </Grid>
      </Grid>
      {!uiLoading && ddAccounts.length < 1 && (
        <Grid item xs={12} className={classes.noResultsRow}>
          <Typography variant="subtitle1">
            You don't have any accounts setup. Click the plus sign to add one!
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <AccountsTableComponent
          accounts={ddAccounts}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.errorContent}>
          {error ? (
            <>
              <ErrorIcon />
              &nbsp;
              <span>{error}</span>
            </>
          ) : (
            <span>&nbsp;</span>
          )}
        </div>
      </Grid>
      <Grid item xs={12}>
        {ddAccounts.length > 0 && !employeeReadOnly && (
          <Button
            variant="contained"
            className={classes.editAmountsBtn}
            color="primary"
            size="large"
            onClick={onClickEditAmounts}
          >
            EDIT AMOUNTS
          </Button>
        )}{" "}
        {ddAccounts.length > 0 && !employeeReadOnly && (
          <Button
            variant="contained"
            className={classes.editAmountsBtn}
            color="primary"
            size="large"
            onClick={onClickReorderAccounts}
          >
            PRIORITIZE ACCOUNTS
          </Button>
        )}
        <FullScreenDialog onClose={onCancelEditAmounts} open={editingAmounts}>
          <EditAmountsForm
            onCancel={onCancelEditAmounts}
            onComplete={onAmountsUpdated}
          />
        </FullScreenDialog>
        <FullScreenDialog onClose={onCancelEditAccount} open={editingAccount}>
          <EditAccountForm
            id={editingAccountId}
            onCancel={onCancelEditAccount}
            onComplete={onAccountsUpdated}
            title={
              editingAccountId === 0
                ? "Create account #" + nextDDAccountId
                : "Edit account # " + editingAccountId
            }
          />
        </FullScreenDialog>
        <FullScreenDialog
          onClose={onCancelReorderAccounts}
          open={reorderingAccounts}
        >
          <ReorderAccountsForm
            onCancel={onCancelReorderAccounts}
            onComplete={onReorderedAccounts}
          />
        </FullScreenDialog>
      </Grid>
    </Grid>
  );
}

export const AccountsPage = connectView(
  _AccountsPage,
  state => ({
    ...ddAccounts(state),
    ...ddAccountsAllDisabled(state),
    ...ddAccountsAnyDisabledByPriority(state),
    ...ddAccountsTotalPercent(state),
    ...employeeReadOnly(state),
    ...nextDDAccountId(state),
    ...uiLoading(state),
  }),
  [DDActions, UIActions],
);
