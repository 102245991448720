import React from "react";
import { Button, Grid, FormLabel, Typography } from "@material-ui/core";
// Local

function _CompletedStateW4({ formData }) {
  const { signedW4DownloadURL } = formData;
  return (
    <Grid item xs={12}>
      <div style={{ textAlign: "center", minHeight: 500 }}>
        <br />
        <Typography variant="h2">All done!</Typography>
        <br />
        <FormLabel>
          Your State W-4 has been submitted to your employer
        </FormLabel>
        <br />
        <Button
          href={signedW4DownloadURL}
          target="BrandsW4Signed"
          variant="contained"
          style={{ marginTop: 20 }}
        >
          Download your signed State W-4
        </Button>
      </div>
    </Grid>
  );
}

export const CompletedStateW4 = React.memo(_CompletedStateW4);
