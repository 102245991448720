import React, { useEffect, useState } from "react";
import { UIActions } from "../../state/ui/actions";
import { authGet } from "../../lib/AuthRequest";
import { useDispatch } from "react-redux";
import { Navigation } from "../../lib";
import { Hidden } from "@material-ui/core";

export function TimeKeepingPage() {
  const dispatch = useDispatch();
  const [swipeClockUrl, setSwipeClockUrl] = useState("");

  useEffect(() => {
    let intervalId;
    const getScUrl = async () => {
      try {
        dispatch(UIActions.setUILoading(true));
        const resp = await authGet("/api/my/swipeClock/SSO");
        const { data, error } = resp;
        setSwipeClockUrl(data.url);
        intervalId = setInterval(() => {
          Navigation.redirect("/");
        }, 1000 * 60 * 20);
      } catch (e) {
        dispatch(UIActions.showError());
      } finally {
        dispatch(UIActions.setUILoading(false));
      }
    };
    getScUrl();
    return () => clearInterval(intervalId);
  }, []);

  const getAppBarHeigh = () => {
    return document.getElementById("mainAppBar")?.clientHeight || 0;
  };

  return (
    <div
      id="scIframe"
      style={{
        height: `calc(100vh - ${getAppBarHeigh()}px)`,
        width: "100%",
        overflow: "hidden",
        marginTop: `${getAppBarHeigh()}px`,
      }}
    >
      <iframe
        style={{ height: "100%", width: "100%", border: "none" }}
        title="Swipe Clock"
        src={swipeClockUrl}
      />
    </div>
  );
}
