import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  theme => ({
    w4box: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginTop: 24,
      padding: 8,
      [theme.breakpoints.up("lg")]: {
        minHeight: 480,
        minWidth: 350,
        maxWidth: 460,
      },
    },
    table: {
      "& td": {
        borderBottom: "none",
      },
    },
    title: {
      fontSize: 28,
      margin: 0,
      marginTop: 12,
    },
    titlebar: {
      display: "flex",
      justifyContent: "space-between",
      "& label": {
        marginTop: 10,
      },
    },
    subtitle: {
      margin: 16,
    },
    subtitlebar: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    iconCell: {
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 0,
      width: 50,
      color: "#6FA200",
      "& > svg": {
        fontSize: 64,
        stroke: "#6FA200",
        strokeWidth: 0.5,
      },
      "& > span": {
        margin: 0,
        display: "inline-block",
        position: "relative",
        top: 42,
        left: 21,
      },
    },
    nameCell: {
      width: 10,
      whiteSpace: "nowrap",
    },
    toolCell: {
      width: 10,
      whiteSpace: "nowrap",
    },
    valueCell: {
      textAlign: "right",
      whiteSpace: "nowrap",
      color: "rgba(0,0,0,0.6)",
    },
    twoLineCell: {
      paddingTop: 4,
      paddingBottom: 4,
    },
    updateButton: {
      marginLeft: 8,
      marginTop: "auto",
    },
  }),
  {
    classNamePrefix: "TaxWithholdingsPage",
  },
);
