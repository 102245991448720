import React from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
// Local
import { HidePaySwitch, NetPayDonutChart } from "../../components";
import { formatDate, formatDateLong, formatHours, useOnMount } from "../../lib";
import {
  PayHistoryActions,
  connectView,
  payHistDetails,
  renderAmount,
} from "../../state";
import { useMobile } from "../../themes";
import { PaystubTaxTable } from "./components";
import { useStyles } from "./PayHistoryDetailsPage.styles";

/**
 * @typedef {object} PayHistoryDetailsPageProps
 * @property {import("../../state").PayHistoryActions} actions
 * @property {import("../../state").PayHistoryDetails} payHistDetails
 * @property {(value:number)=>string} renderAmount
 *
 * @param {PayHistoryDetailsPageProps} props
 */
function _PayHistoryDetailsPage({
  actions: { downloadPayHistoryDocument, getPayHistory },
  pageRoute: { params },
  payHistDetails,
  renderAmount,
}) {
  const classes = useStyles();
  const isMobile = useMobile();
  useOnMount(() => {
    getPayHistory(params.id);
  });
  return (
    <Grid container spacing={isMobile ? 0 : 3}>
      <Grid item xs={12} style={{ maxHeight: 88 }}>
        <div className={classes.titlebar}>
          <Typography className={classes.title} variant={"h4"}>
            {formatDateLong(payHistDetails.checkDate)}
            {" Paystub"}
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ marginTop: 8, color: "rgba(0,0,0,0.38)" }}
          >
            {formatDate(payHistDetails.perStartDate)}
            {" - "}
            {formatDate(payHistDetails.perEndDate)}
          </Typography>
          <HidePaySwitch />
        </div>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Box boxShadow={3} className={classes.netPayCard}>
          <div className={classes.netPayChart}>
            <NetPayDonutChart
              deductions={{
                amount: renderAmount(payHistDetails.deds),
                value: payHistDetails.deds,
              }}
              gross={{
                amount: renderAmount(payHistDetails.gross),
                value: payHistDetails.gross,
              }}
              net={{
                amount: renderAmount(payHistDetails.net),
                value: payHistDetails.net,
              }}
              taxes={{
                amount: renderAmount(payHistDetails.taxes),
                value: payHistDetails.taxes,
              }}
              title={<Typography>Take home pay</Typography>}
              subtitle=""
              footnote={
                <React.Fragment>
                  out of {renderAmount(payHistDetails.gross || 0)}
                </React.Fragment>
              }
            />
          </div>
          <div className={classes.netPayTools}>
            <Typography variant="body2">
              {`${formatHours(payHistDetails.hours)} hours at ` +
                `${renderAmount(payHistDetails.rate || 0)} / hour`}
            </Typography>
          </div>
        </Box>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Box boxShadow={3} className={classes.payHistoryCard}>
          <div className={classes.payHistoryContent}>
            <PaystubTaxTable
              payHistDetails={payHistDetails}
              renderAmount={renderAmount}
            />
          </div>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Button
          component="span"
          className={classes.netPayDownload}
          variant="contained"
          color="primary"
          onClick={() => downloadPayHistoryDocument(params.id)}
        >
          DOWNLOAD PAYSTUB
        </Button>
      </Grid>
    </Grid>
  );
}

export const PayHistoryDetailsPage = connectView(
  _PayHistoryDetailsPage,
  state => {
    return {
      ...payHistDetails(state),
      ...renderAmount(state),
    };
  },
  [PayHistoryActions],
);
