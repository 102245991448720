import { makeStyles } from "@material-ui/core";

const largerBtn = {
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 40,
  paddingRight: 40,
};

export const useStyles = makeStyles(
  theme => ({
    help: {
      margin: 4,
      marginTop: 20,
    },

    cancel: {
      ...largerBtn,
      alignSelf: "flex-start",
      marginTop: 2,
      marginBottom: 13,
    },

    submit: {
      ...largerBtn,
      alignSelf: "flex-end",
      marginTop: 2,
      marginBottom: 13,
    },

    toolbar: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: 10,
    },
  }),
  {
    classNamePrefix: "ChangePasswordPage",
  },
);
