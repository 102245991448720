import { DashboardPage } from "./DashboardPage";
import { NotFoundPage } from "./NotFoundPage";

export const MainPages = {
  dashboard: {
    anon: false,
    path: "/",
    title: "Dashboard",
    type: "PAGE_DASHBOARD",
    view: DashboardPage,
  },
  notFound: {
    anon: true,
    layout: null,
    path: "*",
    title: "Page Not Found",
    type: "PAGE_NOT_FOUND",
    view: NotFoundPage,
  },
};
export default MainPages;

export const MainArea = {
  pages: MainPages,
};
