import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  theme => ({
    /** Style for <Card variant="outlined" ...> */
    card1: {
      marginTop: 35,
      padding: 10,
    },
    card2: {
      marginTop: 35,
      padding: 10,
    },
    formControlRight: {
      marginTop: 0,
      maxWidth: 200,
    },
    formControl: {
      marginTop: 35,
      minWidth: 120,
      width: "100%",
    },
    exemptHelp: {
      padding: 10,
      backgroundColor: "grey",
      color: theme.palette.common.white,
      maxWidth: 340,
    },
    optAdjustPanel: {
      // marginTop: 35,
    },
    optAdjustPanelDets: {
      display: "block",
    },
  }),
  {
    classNamePrefix: "EditW4Form",
  },
);
