import { AuthActions } from "../auth/actions";
import { DDActions } from "./actions";
/**
 * Direct Deposits state.
 * @example
 * {
 *  accounts: [
 *    {
 *      accountNumber: "1234567890",
 *      accountType: "Checking",
 *      amount: 0.50,
 *      bankName: "TD Bank, NA",
 *      id: 2,
 *      lastDeposit: 452.60,
 *      lastDepositDate: "2017-11-21T00:00:00",
 *      lastPayroll: 141,
 *      routingNumber: "987654321",
 *      split: "Percent Split",
 *      status: "Pre-Note"
 *    },
 *    ...
 *  ]
 * }
 */
export const DDState = {
  name: "dd",
  persist: true,
  defaults: {},
  handlers: {
    [AuthActions.type.LOGOUT_SUCCESS](state, action) {
      return DDState.defaults;
    },
    /**
     * @param {any} state
     * @param {{
     *    data:import("./actions").LoadDDAccountsData
     * }} action
     */
    [DDActions.type.DD_ACCOUNTS_LIST_SUCCESS](state, action) {
      if (action.error) {
        return state;
      }
      const {
        data: { accounts = [] },
      } = action;
      return {
        ...state,
        accounts,
      };
    },
  },
};
