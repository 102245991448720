import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  theme => ({
    title: {
      fontSize: 28,
      margin: 0,
      marginTop: 12,
    },
    titlebar: {
      display: "flex",
      "& h4": {
        display: "inline-block",
      },
      [theme.breakpoints.down("sm")]: {
        justifyContent: "space-between",
      },
    },
    divider: {
      marginTop: 90,
      marginLeft: -50,
      marginRight: -50,
      boxShadow: "0 0 4px 0 rgba(0,0,0,0.12), 0 0 8px 0 rgba(0,0,0,0.16)",
    },
    noResultsRow: {
      textAlign: "center",
      paddingTop: 40,
    },
    menuButton: {
      marginTop: 10,
      marginLeft: 40,
      paddingBottom: 10,
      textTransform: "none",
      whiteSpace: "nowrap",
    },
    iconCell: {
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 24,
      width: 10,
      // color: "#6FA200",
      "& > svg": {
        fontSize: 32,
      },
      "& > span": {
        margin: 0,
        display: "inline-block",
        position: "relative",
        top: 42,
        left: 21,
      },
    },
    nameCell: {
      // width: 10,
      whiteSpace: "nowrap",
    },
    toolCell: {
      width: 10,
      whiteSpace: "nowrap",
    },
  }),
  {
    classNamePrefix: "AnnualDocumentsPage",
  },
);
