import React from "react";
import {
  AppBar,
  Button,
  Container,
  Dialog,
  Hidden,
  IconButton,
  MobileStepper,
  Toolbar,
  Typography,
} from "@material-ui/core";
// Local
import { emptyHandler } from "../../lib";
import { ProgressDots } from "../feedback/ProgressDots";
import { CloseIcon, KeyboardArrowLeft, KeyboardArrowRight } from "../icons";
import { SlideUpTransition } from "../transitions";
import { useMobile } from "../../themes";
import { useStyles } from "./WizardDialog.styles";

function _WizardDialog({
  children,
  maxWidth = "sm",
  open = false,
  onCancel = emptyHandler,
  onComplete = emptyHandler,
  steps = 1,
  title = "Wizard Dialog",
}) {
  const classes = useStyles();
  const isMobile = useMobile("sm");

  const [step, setStep] = React.useState(0);
  const events = React.useRef();

  const onPrev = React.useCallback(e => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    return events?.current?.onPrev();
  }, []);

  const onNext = React.useCallback(e => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    return events?.current?.onNext();
  }, []);

  const handleCancel = React.useCallback(() => {
    onCancel();
    setStep(0);
  }, [onCancel, setStep]);

  const handleComplete = React.useCallback(() => {
    onComplete();
    setStep(0);
  }, [onComplete, setStep]);

  const prevText = events.current?.prevText(step);
  const nextText = events.current?.nextText(step);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleCancel}
      TransitionComponent={SlideUpTransition}
    >
      <Hidden mdUp>
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCancel}
              aria-label="close"
            >
              <CloseIcon className={classes.appBarIcon} />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
      </Hidden>
      <form
        onSubmit={onNext}
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          paddingTop: isMobile ? 80 : undefined,
          paddingBottom: isMobile ? 80 : 75,
        }}
      >
        <Container
          maxWidth={maxWidth}
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Hidden smDown>
            <div className={classes.titlebarLg}>
              <div className={classes.titlebarLgBody}>
                <Typography variant="h4">{title}</Typography>
                <IconButton
                  color="inherit"
                  onClick={handleCancel}
                  aria-label="close"
                  style={{ alignSelf: "flex-end", marginRight: -24 }}
                >
                  <CloseIcon className={classes.titleIcon} />
                </IconButton>
              </div>
              <ProgressDots
                activeStep={step}
                steps={steps}
                style={{
                  paddingBottom: 24,
                }}
              />
            </div>
          </Hidden>
          <div className={classes.content}>
            {open &&
              React.cloneElement(children, {
                events,
                onCancel: handleCancel,
                onComplete: handleComplete,
                setStep,
                step,
                steps,
              })}
          </div>
        </Container>
        {!isMobile && (
          <div className={classes.toolbarBottom}>
            <Container maxWidth={maxWidth} className={classes.toolbar}>
              {prevText ? (
                <Button variant="contained" size="medium" onClick={onPrev}>
                  <KeyboardArrowLeft />
                  {prevText}
                </Button>
              ) : (
                <div />
              )}
              <Button
                variant="contained"
                type="submit"
                size="medium"
                color="secondary"
              >
                {nextText} <KeyboardArrowRight />
              </Button>
            </Container>
          </div>
        )}
        {isMobile && (
          <MobileStepper
            style={{ paddingBottom: 30, paddingTop: 20 }}
            variant="dots"
            steps={steps}
            position="bottom"
            activeStep={step}
            backButton={
              prevText ? (
                <Button variant="contained" size="medium" onClick={onPrev}>
                  <KeyboardArrowLeft />
                  {prevText}
                </Button>
              ) : (
                <div />
              )
            }
            nextButton={
              <Button
                variant="contained"
                type="submit"
                size="medium"
                color="secondary"
              >
                {nextText} <KeyboardArrowRight />
              </Button>
            }
          />
        )}
      </form>
    </Dialog>
  );
}

export const WizardDialog = React.memo(_WizardDialog);

/* <DialogSaveCancelButtons
  cancelMobile
  cancelText={step > 0 ? "BACK" : "CANCEL"}
  noCancel={step === 3}
  onCancel={onPrev}
  saveText={step === 2 ? "SUBMIT" : step === 3 ? "OK" : "NEXT"}
/> */
