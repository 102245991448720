import React from "react";
import { Grid } from "@material-ui/core";
// Local
import {
  // AccountEmailCard,
  AccountPasswordCard,
  TabInfo,
  TabPanel,
} from "./";

function _AccountTab({ showing }) {
  return (
    <TabPanel index={TabInfo.security.idx} showing={showing}>
      <Grid container spacing={3}>
        {/* <Grid item xs={12} md={6}>
          <AccountEmailCard />
        </Grid> */}
        <Grid item xs={12} md={6}>
          <AccountPasswordCard />
        </Grid>
      </Grid>
    </TabPanel>
  );
}

export const AccountTab = React.memo(_AccountTab);
