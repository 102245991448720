import { renderAmount as prefsRenderAmount } from "../prefs/selectors";
import { DDSplitMethod, DDSplitOption, DDValidAccountIds } from "./actions";

/**
 * @param {{dd:{accounts:Array<import("./actions").DDAccount>}}} state
 */
export function ddAccounts(state) {
  let { accounts = [] } = state.dd;
  if (accounts.length > 1) {
    accounts = accounts.sort(
      (a, b) =>
        // Sort by id only.
        a.id < b.id ? -1 : 1,
      // // Put "100% / remaining" amounts last.
      // a.split === DDSplitMethod.Percent && a.amount === 1
      //   ? 1
      //   : b.split === DDSplitMethod.Percent && b.amount === 1
      //   ? -1
      //   : // Everything else in order of id.
      //   a.id < b.id
      //   ? -1
      //   : 1,
    );
  }
  return {
    ddAccounts: accounts,
  };
}
/**
 * @param {{dd:{accounts:Array<import("./actions").DDAccount>}}} state
 */
export function ddAccountsAllDisabled(state) {
  const { accounts } = state.dd;
  if (!accounts || accounts.length < 1) {
    // Return a value that won't cause any errors to flash on the screen.
    return {
      ddAccountsAllDisabled: true,
    };
  }
  return {
    ddAccountsAllDisabled: !accounts.some(a => a.enabled),
  };
}
/**
 * @param {{dd:{accounts:Array<import("./actions").DDAccount>}}} state
 */
export function ddAccountsAnyDisabled(state) {
  const { accounts } = state.dd;
  if (!accounts || accounts.length < 1) {
    // Return a value that won't cause any errors to flash on the screen.
    return {
      ddAccountsAnyDisabled: false,
    };
  }
  return {
    ddAccountsAnyDisabled: accounts.some(a => !a.enabled),
  };
}
/**
 * @param {{dd:{accounts:Array<import("./actions").DDAccount>}}} state
 */
export function ddAccountsAnyDisabledByPriority(state) {
  const { accounts } = state.dd;
  let ddAccountsAnyDisabledByPriority = false;
  if (!accounts || accounts.length < 1) {
    // Return a value that won't cause any errors to flash on the screen.
    return {
      ddAccountsAnyDisabledByPriority,
    };
  }
  let foundAccountWith100PercentRemaining = false;
  for (const a of accounts) {
    if (!foundAccountWith100PercentRemaining) {
      foundAccountWith100PercentRemaining =
        a.split !== DDSplitOption.Flat && a.amount === 1 && a.enabled;
      continue;
    } else {
      ddAccountsAnyDisabledByPriority = true;
    }
  }
  return {
    ddAccountsAnyDisabledByPriority,
  };
}
/**
 * @param {{dd:{accounts:Array<import("./actions").DDAccount>}}} state
 */
export function ddAccountsTotalPercent(state) {
  const { accounts } = state.dd;
  if (!accounts || accounts.length < 1) {
    // Return a value that won't cause any errors to flash on the screen.
    return {
      ddAccountsTotalPercent: 1,
    };
  }
  const result = accounts.reduce(
    (r, acct) => {
      if (acct.enabled) {
        const split = DDSplitOption.fromAccount(acct);
        if (split === DDSplitOption.Remaining) {
          r.remaining = true;
        } else if (split === DDSplitOption.Percent) {
          r.total += acct.amount || 0;
        }
      }
      return r;
    },
    { total: 0, remaining: false },
  );
  if (result.total < 1 && result.remaining) {
    result.total = 1;
  }
  return {
    ddAccountsTotalPercent: result.total,
  };
}
/**
 * @param {{dd:{accounts:Array<import("./actions").DDAccount>}}} state
 */
export function nextDDAccountId(state) {
  const {
    dd: { accounts = [] },
  } = state;
  const accountIds = accounts.map(acct => acct.id);
  const availableIds = DDValidAccountIds.filter(
    id => accountIds.indexOf(id) < 0,
  );
  return {
    nextDDAccountId: availableIds[0],
  };
}
/**
 * @param {{dd:{accounts:Array<import("./actions").DDAccount>}}} state
 */
export function renderDDAmount(state) {
  const {
    dd: { accounts = [] },
  } = state;
  const { renderAmount } = prefsRenderAmount(state);
  return {
    /**
     * @param {import("./actions").DDAccount} account
     */
    renderDDAmount(account) {
      let { amount, enabled } = account;
      amount = amount || 0;
      // Dollar amount?
      if (account.split === DDSplitMethod.Flat) {
        return renderAmount(amount);
      }
      // 100%
      if (amount === 1) {
        if (accounts.length > 0 && enabled) {
          return "Entire remaining net";
        }
        return "100%";
      }
      // Other percentage?
      if (!isNaN(amount) && amount <= 1) {
        const percent = amount * 100;
        return percent + "%";
      }
      return "0%";
    },
  };
}
