import React from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
// Local
import { MiniDonutChart } from "../../../components/charts/MiniDonutChart";
import { DownloadIcon, VisibilityIcon } from "../../../components";
import { formatDate, formatHours, PageLink } from "../../../lib";
import { useStyles } from "./PayHistoryPageTable.styles";
import { Pages } from "../../index";

function _PayHistoryPageTable({ onDownload, renderAmount, rows }) {
  const classes = useStyles();
  return (
    <Table>
      <TableBody>
        {rows.map(row => (
          <React.Fragment key={row.id}>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.iconCell}>
                <MiniDonutChart gross={row.gross} net={row.net} deds={row.deds} taxes={row.taxes} />
              </TableCell>
              <TableCell className={classes.dateCell}>
                <strong>{formatDate(row.checkDate)}</strong>
              </TableCell>
              <TableCell className={classes.hoursCell}>
                <span className={classes.label}>
                  {formatHours(row.hours)} hrs
                </span>
              </TableCell>
              <TableCell className={classes.netCell}>
                <span className={classes.label}>Take home</span>{" "}
                <strong className={classes.money}>
                  {renderAmount(row.net)}
                </strong>
              </TableCell>
              <TableCell className={classes.grossCell}>
                <span className={classes.label}>Gross</span>{" "}
                <strong>{renderAmount(row.gross)}</strong>
              </TableCell>
              <TableCell className={classes.toolCell}>
                <IconButton
                  component={PageLink}
                  className={classes.button}
                  aria-label="View"
                  title="View"
                  to={Pages.payHist.details}
                  params={{ id: row.id }}
                >
                  <VisibilityIcon fontSize="small" />
                </IconButton>
                <IconButton
                  className={classes.button}
                  aria-label="Download Paystub"
                  title="Download Paystub"
                  onClick={() => onDownload(row.id)}
                >
                  <DownloadIcon fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRowEmpty}>
              <td></td>
            </TableRow>
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
  );
}

export const PayHistoryPageTable = React.memo(_PayHistoryPageTable);

/*
<Box className={classes.dataRowBox} boxShadow={3} mt={2} p={2}>
  <Box className={classes.dataCellIcon}>
    <img src={DonutChartImg} width={34} height={34} alt="Icon" />
  </Box>
  <Box className={classes.dataCellDate}>
    <strong>{formatDate(row.checkDate)}</strong>
  </Box>
  <Box className={classes.dataCellHours}>
    <span className={classes.label}>{row.hours} hrs</span>
  </Box>
  <Box className={classes.dataCellEmpty}>&nbsp;</Box>
  <Box className={classes.dataCellTakeHome}>
    <span className={classes.label}>Take home</span>{" "}
    <strong className={classes.money}>
      {renderAmount(row.net)}
    </strong>
  </Box>
  <Box className={classes.dataCellGross}>
    <span className={classes.label}>Gross</span>{" "}
    <strong>{renderAmount(row.gross)}</strong>
  </Box>
  <Box className={classes.dataCellButtons}>
    <IconButton
      component={PageLink}
      className={classes.button}
      aria-label="View"
      title="View"
      to={Pages.payHist.details}
      params={{ id: row.id }}
    >
      <VisibilityIcon fontSize="small" />
    </IconButton>
    <IconButton
      className={classes.button}
      aria-label="Download Paystub"
      title="Download Paystub"
      onClick={() => downloadPayHistoryDocument(row.id)}
    >
      <DownloadIcon fontSize="small" />
    </IconButton>
  </Box>
</Box>
*/
