import { AuthActions } from "../auth/actions";
import { DocumentActions } from "./actions";
/**
 * Documents state.
 * @example
 * {
 *  annual: [
 *    {
 *      id: "49933598-d2cb-4149-a221-4c24c502570c",
 *      reportId: 60,
 *      reportName: "W2",
 *      fileType: "pdf",
 *      displayDate: "2018-11-06T00:00:00"
 *    },
 *    ...
 *  ],
 *  annualYears: [2019, 2018, ...],
 * }
 */
export const DocumentState = {
  name: "documents",
  persist: true,
  defaults: {},
  handlers: {
    [AuthActions.type.LOGOUT_SUCCESS](state, action) {
      return DocumentState.defaults;
    },
    /**
     * @param {any} state
     * @param {{ data:import("./actions").GetMyDocumentsData }} action
     */
    [DocumentActions.type.DOCS_ANNUAL_LIST_SUCCESS](state, action) {
      if (action.error) {
        return state;
      }
      const {
        data: { documents = [], years = [] },
      } = action;
      return {
        ...state,
        annual: documents,
        annualYears: years,
      };
    },
  },
};
