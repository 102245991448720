import React from "react";
import ReactPieChart from "react-minimal-pie-chart";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
// Local
import { useStyles } from "./NetPayDonutChart.styles";
import { ie11 } from "../../device";

/**
 * @typedef {object} NetPayDonutChartProps
 * @property {string} className
 * @property {PayDataItem} deductions
 * @property {string} footnote Last text line.
 * @property {PayDataItem} net
 * @property {PayDataItem} taxes
 * @property {string} title Top text line.
 * @property {string} subtitle Second text line.
 *
 * @typedef {object} PayDataItem
 * @property {string} amount Formatted dollar amount.
 * @property {number} [percent] Size of area.
 * @property {string} [color] Color of the item.
 *
 * @param {NetPayDonutChartProps} props
 */
function _NetPayDonutChart({
  className,
  deductions,
  gross,
  net,
  taxes,
  title,
  subtitle,
  footnote,
}) {
  const classes = useStyles();

  const netAmount = net.amount;
  const netColor = "#1CF2E2";

  const getPercent = amount => {
    if (!gross || !gross.value) {
      return 0;
    }

    if (!amount) {
      return 0;
    }
    return (amount / gross.value) * 100;
  };

  /** @type {import("react-minimal-pie-chart").PieChartData[]} */
  const chartData = [
    {
      title: `Pay: ${netAmount}`,
      value: getPercent(net.value),
      color: netColor,
    },
    {
      title: `Deductions: ${deductions.amount}`,
      value: getPercent(deductions.value),
      color: deductions.color || "#2d12a3",
    },
    {
      title: `Taxes: ${taxes.amount}`,
      value: getPercent(taxes.value),
      color: taxes.color || "#dae1e9",
    },
  ];

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.container}>
        <div className={classes.textContainer}>
          {title}
          {subtitle}
          <Typography
            variant="h5"
            className={classes.netAmount}
            style={{ color: "#f21dc7" }}
          >
            {netAmount}
          </Typography>
          <div className={classes.footnote}>
            <Typography variant="caption">{footnote}</Typography>
          </div>
        </div>
        {!ie11 && (
          <ReactPieChart
            className={classes.chart}
            data={chartData}
            lineWidth={10}
            startAngle={-90}
            totalValue={100}
          />
        )}
      </div>
    </div>
  );
}

export const NetPayDonutChart = React.memo(_NetPayDonutChart);
