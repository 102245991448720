import React from "react";
import { FormControlLabel, Switch } from "@material-ui/core";
import { handleCheck } from "../../lib";
import { connectView, preferHidePay, PrefActions } from "../../state";

function _HidePaySwitch({ actions: { updateHidePay }, preferHidePay }) {
  const onChangeHidePay = handleCheck(updateHidePay);
  return (
    <FormControlLabel
      control={
        <Switch
          color="secondary"
          checked={preferHidePay}
          onChange={onChangeHidePay}
          value="preferHidePay"
        />
      }
      label="Hide pay"
      labelPlacement="start"
      style={{ color: "rgba(0,0,0,0.6)", fontSize: 14 }}
    />
  );
}

export const HidePaySwitch = connectView(_HidePaySwitch, preferHidePay, [
  PrefActions,
]);
