import React from "react";
import {
  Checkbox,
  Grid,
  FormControlLabel,
  FormLabel,
  TextField,
} from "@material-ui/core";
// Local
import { useStyles } from "./SignW4.styles";

function _SignW4({ errFields, formData, setFormProps }) {
  const { agreed, signature } = formData;
  const classes = useStyles();
  const onChangeAgreed = React.useCallback(
    e => setFormProps({ agreed: e.currentTarget.checked }),
    [setFormProps],
  );
  const onChangeSignature = React.useCallback(
    e => setFormProps({ signature: e.currentTarget.value }),
    [setFormProps],
  );
  return (
    <Grid item xs={12} style={{ marginBottom: 100 }}>
      <div style={{ textAlign: "center" }}>
        <br />
        <FormLabel>
          Check this box and type your name to sign this document.
        </FormLabel>
      </div>
      <FormControlLabel
        className={classes.formControl}
        control={
          <Checkbox
            checked={agreed}
            onChange={onChangeAgreed}
            value="agreed"
            color="primary"
          />
        }
        style={{ userSelect: "none" }}
        label={
          "Under penalties of law I reviewed this information and find " +
          "them true to the best of my knowledge. This info will change " +
          "my tax withholdings."
        }
      />
      <TextField
        label="Type your full name"
        value={signature}
        onChange={onChangeSignature}
        className={classes.formControl}
        margin="normal"
        error={!!errFields.signature}
        helperText={errFields.signature}
        disabled={!agreed}
      />
    </Grid>
  );
}

export const SignW4 = React.memo(_SignW4);
