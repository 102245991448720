import React from "react";
import createSvgIcon from "@material-ui/icons/utils/createSvgIcon";

/**
 * This icon will be invisible unless `stroke` and `strokeWidth` styles
 * are added, due to `fill="none"` attribute below.
 * References:
 * - https://github.com/mui-org/material-ui/blob/master/packages/material-ui-icons/src/Brightness1.js
 * - https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/stroke-width
 */
export default createSvgIcon(
  <circle cx="12" cy="12" r="10" fill="none" />,
  "CircleOutlined",
);
