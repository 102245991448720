import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
// Local
import { FullScreenDialog } from "../../../components";
import { ChangePasswordForm } from "./";

function _AccountPasswordCard(props) {
  const [editing, setEditing] = React.useState(false);
  const onClickEdit = React.useCallback(
    e => {
      e.preventDefault();
      setEditing(true);
    },
    [setEditing],
  );
  const onCancelEdit = React.useCallback(() => {
    setEditing(false);
  }, [setEditing]);
  const onCompletedEdit = React.useCallback(() => {
    setEditing(false);
  }, [setEditing]);
  return (
    <Box boxShadow={3} style={{ minHeight: 160, padding: 16, fontSize: 14 }}>
      <Typography variant="h6" style={{ fontSize: 16, marginBottom: 20 }}>
        Password
      </Typography>
      <span style={{ color: "rgba(0,0,0,0.38)" }}>
        To change your current password click the edit button below.
      </span>
      <br />
      <br />
      <br />
      <Button color="primary" onClick={onClickEdit} style={{ marginLeft: -16 }}>
        EDIT
      </Button>
      <FullScreenDialog onClose={onCancelEdit} open={editing}>
        <ChangePasswordForm
          onCancel={onCancelEdit}
          onComplete={onCompletedEdit}
        />
      </FullScreenDialog>
    </Box>
  );
}

export const AccountPasswordCard = React.memo(_AccountPasswordCard);
