import React from "react";
import { Box, Chip, IconButton, Typography } from "@material-ui/core";
// Local
import { DeleteIcon, EditIcon } from "../../../components";
import { maskUpToLast4 } from "../../../lib";
import { connectView, employeeReadOnly, renderDDAmount } from "../../../state";
import { /* useStyles, */ useRowStyles } from "./AccountsTableMobile.styles";

/**
 * @typedef {object} AccountRowProps
 * @property {import("../../../state").DDAccount} account
 * @property {(account:any)=>void} onDelete
 * @property {(account:any)=>void} onEdit
 * @property {(account:any)=>void} renderDDAmount
 *
 * @param {any} props
 */
function _AccountRowMobile({
  account,
  employeeReadOnly,
  onDelete,
  onEdit,
  renderDDAmount,
}) {
  const classes = useRowStyles();
  function onClickDelete(e) {
    e.preventDefault();
    onDelete(account);
  }
  function onClickEdit(e) {
    e.preventDefault();
    onEdit(account);
  }
  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Box className={classes.headerAmount}>
          <Typography className={classes.amountText}>
            {renderDDAmount(account)}
          </Typography>
          {!account.enabled && (
            <Chip
              className={classes.amountChip}
              size="small"
              label="disabled"
            />
          )}
        </Box>
        <Box className={classes.headerTools}>
          {employeeReadOnly ? (
            <span>&nbsp;</span>
          ) : (
            <>
              <IconButton aria-label="Edit" title="Edit" onClick={onClickEdit}>
                <EditIcon fontSize="small" color="primary" />
              </IconButton>
              <IconButton
                aria-label="Delete"
                title="Delete"
                onClick={onClickDelete}
              >
                <DeleteIcon fontSize="small" color="primary" />
              </IconButton>
            </>
          )}
        </Box>
      </Box>
      <Box className={classes.bankAndAccountNum}>
        <Typography>
          <strong>
            {account.bankName ? (
              <>{account.bankName}&nbsp;&nbsp;|&nbsp;&nbsp;</>
            ) : null}
            {account.accountType}
            &nbsp;&nbsp;|&nbsp;&nbsp;
            {maskUpToLast4(account.accountNumber)}
          </strong>
        </Typography>
      </Box>
      <Box className={classes.accountName}>
        <Typography>{account.name}</Typography>
      </Box>
    </Box>
  );
}
const AccountRowMobile = connectView(_AccountRowMobile, state => ({
  ...employeeReadOnly(state),
  ...renderDDAmount(state),
}));
/**
 * @typedef {object} AccountsTableProps
 * @property {import("../../../state").DDAccount[]} accounts
 * @property {(account:any) => void} onDelete
 * @property {(account:any) => void} onEdit
 *
 * @param {AccountsTableProps} props
 */
function _AccountsTableMobile({ accounts, onDelete, onEdit }) {
  // const classes = useStyles();
  return accounts.map(account => (
    <AccountRowMobile
      key={account.id}
      account={account}
      onDelete={onDelete}
      onEdit={onEdit}
    />
  ));
}

export const AccountsTableMobile = React.memo(_AccountsTableMobile);
