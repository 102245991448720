import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  theme => ({
    button: {
      color: theme.palette.primary.main,
    },
    dateCell: {
      width: 10,
    },
    netCell: {
      // minWidth: 170,
      // [theme.breakpoints.up("lg")]: {
      //   minWidth: 200,
      // },
    },
    netPay: {
      color: "#f21dc7",
    },
    // table: {
    //   minWidth: 400,
    // },
    toolCell: {
      textAlign: "right",
      whiteSpace: "nowrap",
      padding: 0,
    },
  }),
  {
    classNamePrefix: "PayHistoryTable",
  },
);
