import React from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
// Local
import {
  connectView,
  employeeReadOnly,
  renderAmount,
  taxesW4,
} from "../../../state";
import { EditW4Wizard } from "./EditW4Wizard";

function _FederalTaxCard({ classes, employeeReadOnly, renderAmount, taxesW4 }) {
  const [updating, setUpdating] = React.useState(false);

  function onClickUpdate() {
    setUpdating(true);
  }
  function onCancelUpdate() {
    setUpdating(false);
  }
  function onCompletedUpdate() {
    setUpdating(false);
  }
  return (
    <Box boxShadow={3} className={classes.w4box}>
      <div className={classes.subtitlebar}>
        <h3 className={classes.subtitle}>Federal</h3>
      </div>
      <Table className={classes.table}>
        <TableBody>
          {taxesW4.formStyle !== 1 && !employeeReadOnly && (
            <TableRow>
              <TableCell>Updated W4 for 2020</TableCell>
              <TableCell
                className={`${classes.valueCell} ${classes.twoLineCell}`}
              >
                <strong>No</strong>{" "}
                <Button color="primary" onClick={onClickUpdate}>
                  (UPDATE NOW)
                </Button>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>Filing status</TableCell>
            <TableCell className={classes.valueCell}>
              {taxesW4.filingStatusText}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Withholding status</TableCell>
            <TableCell className={classes.valueCell}>
              {taxesW4.isExempt ? "Exempt" : "Not Exempt"}
            </TableCell>
          </TableRow>
          {taxesW4.formStyle === 1 ? (
            <>
              <TableRow>
                <TableCell className={classes.twoLineCell}>
                  Multiple Job Tax Rate
                  <br />
                  <small>
                    <em>Step 2 option (c)</em>
                  </small>
                </TableCell>
                <TableCell className={classes.valueCell}>
                  {taxesW4.multiJobOption2c ? "Yes" : "No"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Dependents Tax Credit</TableCell>
                <TableCell className={classes.valueCell}>
                  {renderAmount(taxesW4.dependentsAmount)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Other Income</TableCell>
                <TableCell className={classes.valueCell}>
                  {renderAmount(taxesW4.otherIncome)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Deductions</TableCell>
                <TableCell className={classes.valueCell}>
                  {renderAmount(taxesW4.deductions)}
                </TableCell>
              </TableRow>
            </>
          ) : (
            <TableRow>
              <TableCell>Total allowances</TableCell>
              <TableCell className={classes.valueCell}>
                {taxesW4.dependents}
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>Additional amount withheld</TableCell>
            <TableCell className={classes.valueCell}>
              {renderAmount(taxesW4.amountExtra)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {!employeeReadOnly && (
        <Button
          color="primary"
          className={classes.updateButton}
          onClick={onClickUpdate}
        >
          UPDATE
        </Button>
      )}
      <EditW4Wizard
        open={updating}
        onCancel={onCancelUpdate}
        onComplete={onCompletedUpdate}
      />
    </Box>
  );
}
export const FederalTaxCard = connectView(_FederalTaxCard, state => ({
  ...employeeReadOnly(state),
  ...renderAmount(state),
  ...taxesW4(state),
}));
