import { makeStyles } from "@material-ui/core";

export const useInputStyles = makeStyles(
  theme => ({
    // radioGroup: {
    //   [theme.breakpoints.down("sm")]: {
    //     display: "flex",
    //     flexDirection: "column",
    //   },
    // },
    amountFlatCtrl: {
      [theme.breakpoints.up("sm")]: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    amountFlatText: {
      maxWidth: 100,
      [theme.breakpoints.up("sm")]: {
        margin: theme.spacing(1),
        marginLeft: 10,
        marginRight: 0,
      },
    },
    amountPercentCtrl: {
      [theme.breakpoints.up("sm")]: {
        marginLeft: 20,
        marginRight: 0,
      },
    },
    amountPercentText: {
      maxWidth: 100,
      [theme.breakpoints.up("sm")]: {
        margin: theme.spacing(1),
        marginLeft: 10,
        marginRight: 10,
      },
    },
    amountRemainingCtrl: {
      [theme.breakpoints.up("sm")]: {
        marginLeft: 20,
        marginRight: 0,
      },
    },
  }),
  { classNamePrefix: "AmountEditor" },
);

export const useStyles = makeStyles(
  theme => ({
    title: { margin: 0 },
    subtitle: {
      color: "rgba(0,0,0,0.6)",
    },
    inputCell: {
      [theme.breakpoints.up("sm")]: {
        paddingLeft: 4,
        paddingRight: 0,
        "&:last-child": {
          paddingLeft: 4,
          paddingRight: 0,
        },
      },
    },
    nameCell: {
      // maxWidth: 160,
      paddingRight: 0,
      paddingLeft: 0,
      [theme.breakpoints.up("sm")]: {
        // width: 160,
      },
      width: 160,
      whiteSpace: "nowrap",
    },
    errorContent: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#EC5150",
      minHeight: 80,
    },
    largerBtn: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 40,
      paddingRight: 40,
    },
  }),
  {
    classNamePrefix: "EditAmountsForm",
  },
);
