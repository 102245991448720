import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  theme => ({
    gridItem: {
      display: "flex",
      justifyContent: "space-between",
    },
    largerBtn: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 40,
      paddingRight: 40,
      [theme.breakpoints.down("sm")]: {
        // margin: 12,
        width: "100%",
      },
    },
  }),
  {
    classNamePrefix: "EditAmountsForm",
  },
);
