import React from "react";
import { Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
// Local
import { DialogSaveCancelButtons } from "../../../../components";
import { useInputValue, useOnMount } from "../../../../lib";
import {
  connectView,
  emergencyContact,
  emergencyContactRelationshipTypes,
  ProfileActions,
} from "../../../../state";
import { useMobile } from "../../../../themes";
import { useStyles } from "./EmergencyContactForm.styles";

/**
 * @typedef {object} EmergencyContactFormProps
 * @property {typeof import("../../../../state").ProfileActions} actions
 * @property {import("../../../../state").EmergencyContact} emergencyContact
 * @property {number} id
 * @property {function} onCancel
 * @property {function} onComplete
 *
 * @param {EmergencyContactFormProps} props
 */
function _EmergencyContactForm({
  actions: {
    createEmergencyContact,
    getEmergencyContact,
    updateEmergencyContact,
  },
  emergencyContact: contact,
  emergencyContactRelationshipTypes: reltypes,
  id,
  onCancel,
  onComplete,
}) {
  const classes = useStyles();

  const [name, onChangeName, setName] = useInputValue();
  const [email, onChangeEmail, setEmail] = useInputValue();
  const [homePhone, onChangeHomePhone, setHomePhone] = useInputValue();
  const [relationship, onChangeRelationship, setRelationship] = useInputValue(
    "",
    {
      valueFromArg: 1,
      valueProp: "",
    },
  );

  const isMobile = useMobile();

  React.useEffect(() => {
    if (id === 0) {
      return;
    }
    setName(contact.name || "");
    setEmail(contact.email || "");
    setHomePhone(contact.homePhone || "");
    setRelationship(contact.relationship || "");
  }, [contact, id, setName, setEmail, setHomePhone, setRelationship]);

  async function onSave(e) {
    e.preventDefault();
    /** @type {import("../../../../state").EmergencyContact} */
    const contactToSave = {
      email,
      homePhone,
      name,
      relationship,
    };
    if (id === 0) {
      await createEmergencyContact(contactToSave);
    } else {
      contactToSave.id = id;
      await updateEmergencyContact(contactToSave);
    }
    onComplete();
  }

  useOnMount(() => {
    if (id > 0) {
      getEmergencyContact(id);
    }
  });

  if (id === null || id === undefined) {
    return null;
  }
  return (
    <form onSubmit={onSave}>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            autoFocus={!isMobile}
            className={classes.formControl100}
            label="Full name"
            margin="normal"
            onChange={onChangeName}
            value={name}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl100}
            label="Email"
            margin="normal"
            onChange={onChangeEmail}
            type="email"
            value={email}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl100}
            label="Phone"
            margin="normal"
            onChange={onChangeHomePhone}
            type="tel"
            value={homePhone}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            autoHighlight
            // NOT using freeSolo, instead concating value into options and
            // using TextField inputProps onChange handler.
            // See https://github.com/mui-org/material-ui/issues/18113#issuecomment-548971048
            includeInputInList
            className={classes.formControl100}
            id="reltypes"
            options={
              relationship && reltypes.indexOf(relationship) < 0
                ? reltypes.concat(relationship)
                : reltypes
            }
            onChange={onChangeRelationship}
            value={relationship}
            renderInput={params => (
              <TextField
                {...params}
                label="Relation"
                margin="normal"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  onChange: e => {
                    onChangeRelationship(e, e.target.value || "");
                    params.inputProps.onChange(e);
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <p>&nbsp;</p>
        </Grid>
        <DialogSaveCancelButtons onCancel={onCancel} />
      </Grid>
    </form>
  );
}

export const EmergencyContactForm = connectView(
  _EmergencyContactForm,
  state => ({
    ...emergencyContact(state),
    ...emergencyContactRelationshipTypes(state),
  }),
  [ProfileActions],
);

EmergencyContactForm.defaultProps = {
  title: "Edit emergency contact",
};
