import React from "react";
import { Avatar, Grid, Tabs, Tab, Typography } from "@material-ui/core";
// Local
import { formatDate, Navigation, PageURL } from "../../lib";
import {
  avatarInfo,
  connectView,
  dateHired,
  employeeFullName,
  isEmContactEnabled,
} from "../../state";
import { Pages } from "../../pages";
import {
  AccountTab,
  ContactsTab,
  PersonalTab,
  tabA11yProps,
  TabIndex,
  TabInfo,
} from "./components";
import { useStyles } from "./ProfilePage.styles";

function onChangeTab(event, newValue) {
  Navigation.redirect(
    PageURL.to(Pages.profile.index, {
      params: {
        tab: TabInfo[TabIndex[newValue]].path,
      },
    }),
  );
}

const styleFont14 = { fontSize: 14 };

function _ProfilePage({
  avatarInfo,
  employeeFullName,
  pageRoute: {
    params: { tab = TabIndex[0] },
  },
  dateHired,
  isEmContactEnabled,
}) {
  const showIndex = TabInfo[tab].idx || 0;

  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} style={{ marginBottom: 30 }}>
        <Avatar
          className={classes.avatar}
          style={{
            color: avatarInfo.textColor,
            backgroundColor: avatarInfo.bgColor,
          }}
        >
          {avatarInfo.text}
        </Avatar>
        <Typography variant="h4" className={classes.userName}>
          {employeeFullName}
        </Typography>
        <br />
        <span style={{ marginLeft: 24, color: "rgba(0,0,0,0.6)" }}>
          Hired on {formatDate(dateHired)}
        </span>
      </Grid>
      <Grid item xs={12}>
        <Tabs
          classes={{ indicator: classes.tabIndicator }}
          onChange={onChangeTab}
          value={showIndex}
          variant="fullWidth"
          style={{
            borderBottom: "1px solid rgba(0,0,0,0.12)",
            marginBottom: 15,
          }}
        >
          <Tab label="Personal" {...tabA11yProps(0)} style={styleFont14} />
          <Tab
            label="Emergency Contacts"
            {...tabA11yProps(1)}
            style={styleFont14} disabled={!isEmContactEnabled}
          />
          <Tab label="Security" {...tabA11yProps(2)} style={styleFont14} />
        </Tabs>
        <PersonalTab showing={showIndex === TabInfo.personal.idx} />
        <ContactsTab showing={showIndex === TabInfo.contacts.idx} />
        <AccountTab showing={showIndex === TabInfo.security.idx} />
      </Grid>
    </Grid>
  );
}
export const ProfilePage = connectView(_ProfilePage, state => ({
  ...avatarInfo(state),
  ...employeeFullName(state),
  ...dateHired(state),
  ...isEmContactEnabled(state),
}));
