import React from "react";
import { Grid, TextField } from "@material-ui/core";
// Local
import { DialogSaveCancelButtons } from "../../../../components";
import { useInputValue, useOnMount } from "../../../../lib";
import {
  connectView,
  personalContact,
  ProfileActions,
} from "../../../../state";
import { useMobile } from "../../../../themes";
import { useStyles } from "./ContactForm.styles";

/**
 * @typedef {object} ContactFormProps
 * @property {typeof import("../../../../state").ProfileActions} actions
 * @property {import("../../../../state").ProfileContact} personalContact
 * @property {function} onCancel
 * @property {function} onComplete
 *
 * @param {ContactFormProps} props
 */
function _ContactForm({
  actions: { getProfile, updateProfileContact },
  personalContact: contact,
  onCancel,
  onComplete,
}) {
  const classes = useStyles();

  const [homeEmail, onChangeHomeEmail, setHomeEmail] = useInputValue();
  const [homePhone, onChangeHomePhone, setHomePhone] = useInputValue();
  const [cellPhone, onChangeCellPhone, setCellPhone] = useInputValue();

  const isMobile = useMobile();

  React.useEffect(() => {
    setHomeEmail(contact.homeEmail || "");
    setHomePhone(contact.homePhone || "");
    setCellPhone(contact.cellPhone || "");
  }, [contact, setHomeEmail, setHomePhone, setCellPhone]);

  async function onSave() {
    await updateProfileContact({
      homeEmail,
      homePhone,
      cellPhone,
    });
    onComplete();
  }

  useOnMount(() => {
    getProfile();
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <span>Enter your contact info.</span>
      </Grid>
      <Grid item xs={12}>
        <TextField
          autoFocus={!isMobile}
          className={classes.formControl100}
          label="Email"
          margin="normal"
          onChange={onChangeHomeEmail}
          type="email"
          value={homeEmail}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={classes.formControl100}
          label="Phone"
          margin="normal"
          onChange={onChangeHomePhone}
          type="tel"
          value={homePhone}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={classes.formControl100}
          label="Mobile"
          margin="normal"
          onChange={onChangeCellPhone}
          type="tel"
          value={cellPhone}
        />
      </Grid>
      <Grid item xs={12}>
        <p>&nbsp;</p>
      </Grid>
      <DialogSaveCancelButtons onCancel={onCancel} onSave={onSave} />
    </Grid>
  );
}

export const ContactForm = connectView(
  _ContactForm,
  state => ({
    ...personalContact(state),
  }),
  [ProfileActions],
);

ContactForm.defaultProps = {
  title: "Edit contact info",
};
