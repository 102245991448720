import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  theme => ({
    root: {
      width: 300,
      height: 300,
    },
    container: {
      position: "relative",
      width: "100%",
      height: "100%",
    },
    textContainer: {
      position: "absolute",
      zIndex: 101,
      top: "33.3%",
      left: 0,
      width: "100%",
      textAlign: "center",
    },
    chart: {
      position: "absolute",
      zIndex: theme.zIndex.mobileStepper - 100,
      top: 0,
      left: 0,
      width: "100%",
    },
    netAmount: {
      margin: 0,
      marginTop: 10,
      fontWeight: "bold",
    },
    footnote: {
      margin: 0,
      marginTop: 10,
      color: "rgba(0,0,0,0.38)",
    },
    title: {
      margin: 0,
    },
    subtitle: {
      margin: 0,
      minHeight: 20,
    },
  }),
  {
    classNamePrefix: "NetPayDonutChart",
  },
);
