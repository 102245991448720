import { formatAmountUSD, maskNumbersIf } from "../../lib";

export function preferHidePay(state) {
  const {
    prefs: { hidePay },
  } = state;
  return {
    preferHidePay: !!hidePay,
  };
}

export function preferHideInfo(state) {
  // For now, we'll use the same `hidePay` preference instead of making another.
  const {
    prefs: { hideInfo },
  } = state;
  return {
    preferHideInfo: !!hideInfo,
  };
}

export function renderAmount(state) {
  const {
    prefs: { hidePay },
  } = state;
  return {
    renderAmount(value) {
      const amount = formatAmountUSD(value ?? 0);
      return maskNumbersIf(hidePay, amount);
    },
  };
}

export function renderPrivateNum(state) {
  const {
    prefs: { hideInfo },
  } = state;
  return {
    renderPrivateNum(value) {
      return maskNumbersIf(hideInfo, value ?? "");
    },
  };
}
