import React from "react";
import { Grid, TextField } from "@material-ui/core";
// Local
import { DialogSaveCancelButtons, SelectStateUS } from "../../../../components";
import {
  useInputValue,
  useInputTargetValue,
  useOnMount,
} from "../../../../lib";
import {
  connectView,
  personalAddress,
  ProfileActions,
} from "../../../../state";
import { useMobile } from "../../../../themes";
import { useStyles } from "./PersonalAddressForm.styles";

/**
 * @typedef {object} PersonalAddressFormProps
 * @property {typeof import("../../../../state").ProfileActions} actions
 * @property {import("../../../../state").ProfileAddress} personalAddress
 * @property {function} onCancel
 * @property {function} onComplete
 *
 * @param {PersonalAddressFormProps} props
 */
function _PersonalAddressForm({
  actions: { getProfile, updateProfileAddress },
  personalAddress: address,
  onCancel,
  onComplete,
}) {
  const classes = useStyles();

  const [street, onChangeStreet, setStreet] = useInputValue();
  const [city, onChangeCity, setCity] = useInputValue();
  const [state, onChangeState, setState] = useInputTargetValue();
  const [zip, onChangeZip, setZip] = useInputValue();

  const isMobile = useMobile();

  React.useEffect(() => {
    setStreet(address.street || "");
    setCity(address.city || "");
    setState(address.state || "");
    setZip(address.zip || "");
  }, [address, setStreet, setCity, setState, setZip]);

  async function onSave(e) {
    e.preventDefault();
    await updateProfileAddress({
      street,
      city,
      state,
      zip,
    });
    onComplete();
  }

  useOnMount(() => {
    getProfile();
  });

  return (
    <form onSubmit={onSave}>
      <Grid container>
        <Grid item xs={12}>
          <span>Enter your address.</span>
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoFocus={!isMobile}
            className={classes.formControl100}
            label="Address"
            margin="normal"
            onChange={onChangeStreet}
            value={street}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl100}
            label="City"
            margin="normal"
            onChange={onChangeCity}
            value={city}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectStateUS
            className={classes.formControl100}
            label="State"
            onChange={onChangeState}
            value={state}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl100}
            label="Zip"
            margin="normal"
            onChange={onChangeZip}
            value={zip}
          />
        </Grid>
        <Grid item xs={12}>
          <p>&nbsp;</p>
        </Grid>
        <DialogSaveCancelButtons onCancel={onCancel} />
      </Grid>
    </form>
  );
}

export const PersonalAddressForm = connectView(
  _PersonalAddressForm,
  state => ({
    ...personalAddress(state),
  }),
  [ProfileActions],
);

PersonalAddressForm.defaultProps = {
  title: "Edit address",
};
