import { makeStyles } from "@material-ui/core";

const cell = {
  display: "inline-block",
  // border: "1px solid black",
  paddingLeft: 4,
};

export const useAccountRowStyles = makeStyles(
  {
    cell,
    cellPriority: { ...cell, flexGrow: 0, marginRight: 10 },
    cellBank: { ...cell, width: 220 },
    cellName: { ...cell, width: 160 },
    cellNum: { ...cell, width: 60 },
    cellAmt: {
      ...cell,
      display: "inline-flex",
      flexGrow: 1,
      marginLeft: "10%",
      alignItems: "center",
    },
    cellBlank1: { ...cell, flexGrow: 2, marginLeft: 10 },
    cellHandle: { ...cell, flexGrow: 0, marginLeft: 10 },
    row: {
      display: "flex",
      justifyContent: "flex-start",
    },
    textAmt: {
      minWidth: 50,
      wordWrap: "break-word",
    },
    noChip: {
      minWidth: 78,
    },
  },
  { classNamePrefix: "ReorderAccountRow" },
);

export const useStyles = makeStyles(
  theme => ({
    title: { margin: 0 },
    errorContent: {
      display: "flex",
      alignItems: "center",
      color: "#EC5150",
      minHeight: 80,
    },
    largerBtn: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 40,
      paddingRight: 40,
    },
    sort: {
      zIndex: theme.zIndex.modal + 100,
    },
  }),
  {
    classNamePrefix: "ReorderAccountsForm",
  },
);
