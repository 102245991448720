import React from "react";
import { IconButton, Popover } from "@material-ui/core";
// Local
import { HelpIcon } from "../icons";
import { useStyles } from "./PopoverHelpIcon.styles";

function _PopoverHelpIcon({ id, children, maxWidth = 340, ...props }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const onClickButton = React.useCallback(
    e => {
      setAnchorEl(e.currentTarget);
    },
    [setAnchorEl],
  );
  const onClosePopover = React.useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);
  const contentStyle = { maxWidth };
  return (
    <>
      <IconButton
        aria-describedby={id}
        className={classes.button}
        onClick={onClickButton}
        tabIndex="-1"
        {...props}
      >
        <HelpIcon />
      </IconButton>
      <Popover
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={onClosePopover}
      >
        <div className={classes.content} style={contentStyle}>
          {children}
        </div>
      </Popover>
    </>
  );
}

export const PopoverHelpIcon = React.memo(_PopoverHelpIcon);
