import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  theme => ({
    appBarIcon: {
      color: "white",
    },
    content: {
      paddingBottom: 40,
      flex: 1,
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    titleIcon: {
      color: "rgba(0,0,0,0.6)",
    },
    titlebarLg: {
      display: "flex",
      flexDirection: "column",
    },
    titlebarLgBody: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 20,
      marginTop: 60,
    },
    toolbarBottom: {
      backgroundColor: "#fafafa",
      paddingTop: 20,
      paddingBottom: 20,
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: theme.zIndex.mobileStepper,
    },
    toolbar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  }),
  {
    classNamePrefix: "WizardDialog",
  },
);
