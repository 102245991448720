import { REACT_APP_API_URL } from "../../config";
import { authGet, authPost, authPut } from "../../lib";
import { UIActions } from "../ui/actions";

const type = {
  TAXES_W4_REQUEST: "TAXES_W4_REQUEST",
  TAXES_W4_FAILURE: "TAXES_W4_FAILURE",
  TAXES_W4_SUCCESS: "TAXES_W4_SUCCESS",

  TAXES_W4_REVIEW_REQUEST: "TAXES_W4_REVIEW_REQUEST",
  TAXES_W4_REVIEW_FAILURE: "TAXES_W4_REVIEW_FAILURE",
  TAXES_W4_REVIEW_SUCCESS: "TAXES_W4_REVIEW_SUCCESS",

  TAXES_W4_UPDATE_REQUEST: "TAXES_W4_UPDATE_REQUEST",
  TAXES_W4_UPDATE_FAILURE: "TAXES_W4_UPDATE_FAILURE",
  TAXES_W4_UPDATE_SUCCESS: "TAXES_W4_UPDATE_SUCCESS",

  TAXES_STATE_UPDATE_REQUEST: "TAXES_STATE_UPDATE_REQUEST",
  TAXES_STATE_UPDATE_SUCCESS: "TAXES_STATE_UPDATE_SUCCESS",

  TAXES_STATE_REVIEW_REQUEST: "TAXES_STATE_REVIEW_REQUEST",
  TAXES_STATE_REVIEW_FAILURE: "TAXES_STATE_REVIEW_FAILURE",
  TAXES_STATE_REVIEW_SUCCESS: "TAXES_STATE_REVIEW_SUCCESS",
};

export const TaxActions = {
  type,

  /**
   * @returns {Promise<{ data: TaxesW4Data }>}
   */
  getTaxesW4() {
    return async dispatch => {
      dispatch({ type: type.TAXES_W4_REQUEST });
      dispatch(UIActions.setUILoading(true));
      const resp = await authGet("/api/my/taxes");
      const { data, error } = resp;
      dispatch(UIActions.setUILoading(false));
      if (error) {
        dispatch(UIActions.showError());
      }
      dispatch({ type: type.TAXES_W4_SUCCESS, data, error });
      return resp;
    };
  },
  /**
   * @param {TaxesW4Data} data
   */
  reviewTaxesW4(data) {
    data = {
      formStyle: 1,
      ...data,
    };
    return async dispatch => {
      dispatch({ type: type.TAXES_W4_REVIEW_REQUEST });
      dispatch(UIActions.setUILoading(true));
      const resp = await authPost("/api/my/taxes/review", data);
      /** @type {{downloadURL:string}} */
      const rdata = resp.data;
      dispatch(UIActions.setUILoading(false));
      if (resp.error) {
        dispatch(UIActions.showError("W4 could not be opened."));
        return resp;
      }
      // window.open(`${REACT_APP_API_URL}${rdata.downloadURL}`, "_blank");
      return {
        downloadURL: `${REACT_APP_API_URL}${rdata.downloadURL}`,
      };
    };
  },
  /**
   * @param {TaxesW4Data} data
   */
  reviewStateTaxesW4(data) {
    return async dispatch => {
      dispatch({ type: type.TAXES_STATE_REVIEW_REQUEST });
      dispatch(UIActions.setUILoading(true));
      const resp = await authPost("/api/my/taxes/state/review", data);
      /** @type {{downloadURL:string}} */
      const rdata = resp.data;
      dispatch(UIActions.setUILoading(false));
      if (resp.error) {
        dispatch(UIActions.showError("State W4 could not be opened."));
        return resp;
      }
      // window.open(`${REACT_APP_API_URL}${rdata.downloadURL}`, "_blank");
      return {
        downloadURL: `${REACT_APP_API_URL}${rdata.downloadURL}`,
      };
    };
  },
  /**
   * @param {TaxesW4Data} data
   */
  updateStateTaxes(data) {
    return async dispatch => {
      dispatch({ type: type.TAXES_STATE_UPDATE_REQUEST });
      dispatch(UIActions.setUILoading(true));
      const resp = await authPut("/api/my/taxes/state", data);
      const { data: { downloadURL } = {}, error } = resp;
      dispatch(UIActions.setUILoading(false));
      if (error) {
        dispatch(UIActions.showError());
        return resp;
      }
      dispatch({ type: type.TAXES_STATE_UPDATE_SUCCESS, data });
      // dispatch(UIActions.showUpdated());
      return {
        downloadURL: `${REACT_APP_API_URL}${downloadURL}`,
      };
    };
  },
  /**
   * @param {TaxesW4Data} data
   */
  updateTaxesW4(data) {
    data = {
      formStyle: 1,
      ...data,
    };
    return async dispatch => {
      dispatch({ type: type.TAXES_W4_UPDATE_REQUEST });
      dispatch(UIActions.setUILoading(true));
      const resp = await authPut("/api/my/taxes", data);
      const { data: { downloadURL } = {}, error } = resp;
      dispatch(UIActions.setUILoading(false));
      if (error) {
        dispatch(UIActions.showError());
        return resp;
      }
      dispatch({ type: type.TAXES_W4_UPDATE_SUCCESS, data });
      // dispatch(UIActions.showUpdated());
      return {
        downloadURL: `${REACT_APP_API_URL}${downloadURL}`,
      };
    };
  },
};

/**
 * @typedef {object} TaxesW4Data
 * @property {"Married" | "MarriedSingle" | "Single"} filingStatus
 * @property {boolean} isExempt
 * @property {boolean} multiJobOption2c
 * @property {number} dependentsAmount
 * @property {number} otherIncome
 * @property {number} deductions
 * @property {number} amountExtra
 * @property {string} signature
 *
 */
