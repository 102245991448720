import { createSelector } from "reselect";

export const FilingStatuses = {
  Single: "Single",
  Married: "Married",
  MarriedSingle: "Married, withhold at higher Single rate",
};

function selectFederalTaxes(state) {
  return state.taxes.federal || {};
}

function selectFederalTaxesForW4(federal = {}) {
  return {
    taxesW4: {
      ...federal,
      filingStatusText: FilingStatuses[federal.filingStatus],
    },
  };
}

// NOTE: Exporting a memoized selector to prevent EditW4Form component
// from re-mounting and resetting all form values to original values...
//
// Q: What about this selector caused the bug?
// A: The return value of `selectFederalTaxesForW4` re-creates the `taxesW4` property each
// time because it merges `filingStatusText` for convenience into the same
// object.
//
// Q: How does a memoized selector solve this?
// A: The memoized `taxesW4` selector returns a cached value until `selectFederalTaxes`
// returns something different.
//

export const taxesW4 = createSelector(
  selectFederalTaxes,
  selectFederalTaxesForW4,
);

function selectTaxesWorkState(state) {
  return state.taxes.workState;
}

/** @returns {{taxesLocal:{localId:number,dependents:number,amountExtra:number,isActive:boolean}[]}} */
export function taxesLocal(state) {
  const workState = state.taxes.workState || {};
  const { locals = [] } = workState;
  if (workState.stateAbbr !== "NY") return { taxesLocal: [] };
  return {
    taxesLocal: locals.map(item => ({
      ...item,
      name: item.localId === 1200 ? "New York City" : "Yonkers",
    })),
  };
}

export const taxesWorkState = createSelector(
  selectTaxesWorkState,
  (workState = {}) => {
    const stateAbbr = workState && workState.stateAbbr;
    return {
      taxesWorkState: workState,
      taxesWorkStateAbbr: stateAbbr || "",
      taxesWorkStateIsNY: stateAbbr === "NY",
    };
  },
);

export function getStateTaxLocals(taxesWorkState, formData) {
  if (taxesWorkState.stateAbbr !== "NY") return [];
  const locals = [];
  if (formData.resideNYC === "yes") {
    locals.push({
      localId: 1200,
      dependents: formData.dependentsNYC || 0,
      amountExtra: formData.amountExtraNYC || 0,
    });
  }
  if (formData.resideYonkers === "yes") {
    locals.push({
      localId: 1201,
      dependents: formData.dependents || taxesWorkState.dependents || 0,
      amountExtra: formData.amountExtraYonkers,
    });
  }
  return locals;
}
