import { REACT_APP_API_URL } from "../../config";
import { authPost, authGet } from "../../lib";
import { UIActions } from "../ui/actions";

const type = {
  DOCS_ANNUAL_LIST_REQUEST: "DOCS_ANNUAL_LIST_REQUEST",
  DOCS_ANNUAL_LIST_FAILURE: "DOCS_ANNUAL_LIST_FAILURE",
  DOCS_ANNUAL_LIST_SUCCESS: "DOCS_ANNUAL_LIST_SUCCESS",
};

export const DocumentActions = {
  type,

  downloadDocument(id) {
    return async dispatch => {
      dispatch(UIActions.setUILoading(true));
      const resp = await authPost(`/api/my/documents/annual/${id}/download`);
      /** @type {{downloadURL:string}} */
      const data = resp.data;
      dispatch(UIActions.setUILoading(false));
      if (resp.error) {
        dispatch(UIActions.showError("Document could not be downloaded."));
        return;
      }
      window.open(`${REACT_APP_API_URL}${data.downloadURL}`, "_blank");
    };
  },

  listAnnualDocuments(year) {
    return async dispatch => {
      dispatch({ type: type.DOCS_ANNUAL_LIST_REQUEST });
      dispatch(UIActions.setUILoading(true));
      const resp = await authGet(
        "/api/my/documents/annual" + (year ? `?year=${year}` : ""),
      );
      const { data, error } = resp;
      dispatch(UIActions.setUILoading(false));
      if (error) {
        dispatch(UIActions.showError());
      }
      dispatch({ type: type.DOCS_ANNUAL_LIST_SUCCESS, data, error });
      return resp;
    };
  },
};

/**
 * @typedef {object} DocumentInfo
 * @property {string} id
 * @property {number} reportId
 * @property {string} reportName
 * @property {string} fileType
 * @property {string} displayDate
 *
 * @typedef {object} GetMyDocumentsData
 * @property {DocumentInfo[]} documents
 *
 */
