import React from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  // TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
// Local
import { DownloadIcon, VisibilityIcon } from "../../../components";
import { formatDate, PageLink } from "../../../lib";
import { connectView, PayHistoryActions, renderAmount } from "../../../state";
import { useStyles } from "./PayHistoryTable.styles";
import { Pages } from "../../";

/**
 * Renders the pay history table.
 * @typedef {object} PayHistoryTableProps
 * @property {import("../../state").PayHistoryItem[]} records
 * @property {(value:number)=>string} renderAmount
 *
 * @param {PayHistoryTableProps} props
 */
function _PayHistoryTable({
  actions: { downloadPayHistoryDocument },
  records = [],
  renderAmount,
}) {
  const classes = useStyles();
  return (
    <Table className={classes.table}>
      <TableBody>
        {records.map(row => (
          <TableRow key={row.id}>
            <TableCell className={classes.dateCell}>
              <Typography variant="caption">
                <strong>{formatDate(row.checkDate)}</strong>
              </Typography>
            </TableCell>
            <TableCell className={classes.netCell}>
              <Typography variant="caption">
                <span style={{ color: "rgba(0,0,0,0.6)" }}>Take home</span>{" "}
                <span className={classes.netPay}>{renderAmount(row.net)}</span>
              </Typography>
            </TableCell>
            <TableCell className={classes.toolCell}>
              <IconButton
                component={PageLink}
                className={classes.button}
                aria-label="View"
                title="View"
                to={Pages.payHist.details}
                params={{ id: row.id }}
              >
                <VisibilityIcon fontSize="small" />
              </IconButton>
              <IconButton
                className={classes.button}
                aria-label="Download Paystub"
                title="Download Paystub"
                onClick={() => downloadPayHistoryDocument(row.id)}
              >
                <DownloadIcon fontSize="small" />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export const PayHistoryTable = connectView(
  _PayHistoryTable,
  state => ({
    ...renderAmount(state),
  }),
  [PayHistoryActions],
);
