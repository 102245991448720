import { AuthActions } from "../auth/actions";
import { DashboardActions } from "./actions";
/**
 * Dashboard state.
 * @example
 * {
 *  lastPaycheck: {
 *    prNum: 152,
 *    checkDate: "",
 *    hours: 40,
 *    gross: 640,
 *    net: 481.34,
 *    checkCounter: 1
 *  },
 *  payHist: [
 *    {
 *      prNum: 152,
 *      checkDate: "",
 *      hours: 40,
 *      gross: 640,
 *      net: 481.34,
 *      checkCounter: 1
 *    },
 *    ...
 *  ]
 * }
 */
export const DashboardState = {
  name: "dashboard",
  persist: true,
  defaults: {},
  handlers: {
    [AuthActions.type.LOGOUT_SUCCESS](state, action) {
      return DashboardState.defaults;
    },
    /**
     * @param {any} state
     * @param {{ data:import("./actions").DashboardApiData }} action
     */
    [DashboardActions.type.DASHBOARD_SUCCESS](state, action) {
      if (action.error) {
        return state;
      }
      const {
        data: { payHist = [], zayZoonUrl },
      } = action;
      return {
        ...state,

        // See https://bitbean.atlassian.net/browse/BO-141
        // lastPaycheck: payHist.filter(row => row.type === "Regular")[0] || {},
        lastPaycheck: payHist[0] || {},

        payHist,
        zayZoonUrl,
      };
    },
  },
};
