import { ChangePasswordPage } from "./ChangePasswordPage";
import { ProfilePage } from "./ProfilePage";

export const ProfilePages = {
  changePassword: {
    anon: false,
    path: "/profile/change-password",
    title: "Change Password",
    type: "PAGE_CHANGEPASSWORD",
    view: ChangePasswordPage,
  },
  index: {
    anon: false,
    path: "/profile/:tab?",
    title: "My profile",
    type: "PAGE_PROFILE",
    view: ProfilePage,
  },
};
export default ProfilePages;

export const ProfileArea = {
  pages: ProfilePages,
};
