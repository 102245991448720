import React from "react";
import {
  Chip,
  IconButton,
  Table,
  TableCell,
  TableBody,
  TableRow,
  Typography,
} from "@material-ui/core";
// Local
import { DeleteIcon, EditIcon } from "../../../components";
import { addIf, maskUpToLast4 } from "../../../lib";
import { connectView, employeeReadOnly, renderDDAmount } from "../../../state";
import { /* useStyles, */ useRowStyles } from "./AccountsTable.styles";

/**
 * @typedef {object} AccountRowProps
 * @property {import("../../../state").DDAccount} account
 * @property {(account:any)=>void} onDelete
 * @property {(account:any)=>void} onEdit
 * @property {(account:any)=>void} renderDDAmount
 *
 * @param {any} props
 */
function _AccountRow({
  account,
  employeeReadOnly,
  onDelete,
  onEdit,
  renderDDAmount,
}) {
  const classes = useRowStyles();
  function onClickDelete(e) {
    e.preventDefault();
    onDelete(account);
  }
  function onClickEdit(e) {
    e.preventDefault();
    onEdit(account);
  }
  return (
    <TableRow>
      <TableCell className={classes.accountIdCell}>
        <Typography>{account.id}</Typography>
      </TableCell>
      <TableCell className={classes.accountNumCell}>
        <Typography>
          <strong>{`${addIf(account.bankName, " / ")}${
            account.accountType
          }`}</strong>
        </Typography>
      </TableCell>
      <TableCell className={classes.accountNameCell}>
        <Typography>
          <strong>{account.name}</strong>
        </Typography>
      </TableCell>
      <TableCell className={classes.accountNumCell}>
        <Typography>{maskUpToLast4(account.accountNumber)}</Typography>
      </TableCell>
      <TableCell className={classes.amountCell}>
        <div className={classes.amountContainer}>
          <Typography className={classes.amountText}>
            {renderDDAmount(account)}
          </Typography>
          {!account.enabled && (
            <Chip className={classes.amountChip} label="disabled" />
          )}
        </div>
      </TableCell>
      {/* <TableCell>
        {account.lastDeposit && (
          <Typography>
            <span className={classes.lastDepLabel}>
              {"Last deposit"}
              &nbsp;<em>{formatDate(account.lastDepositDate)}</em>
              &nbsp;&nbsp;
            </span>
            <strong>
              <span className={classes.lastDep}>
                {renderAmount(account.lastDeposit)}
              </span>
            </strong>
          </Typography>
        )}
      </TableCell> */}
      <TableCell className={classes.toolCell}>
        {!employeeReadOnly && (
          <>
            <IconButton
              className={classes.button}
              aria-label="Edit"
              title="Edit"
              onClick={onClickEdit}
            >
              <EditIcon fontSize="small" color="primary" />
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="Delete"
              title="Delete"
              onClick={onClickDelete}
            >
              <DeleteIcon fontSize="small" color="primary" />
            </IconButton>
          </>
        )}
      </TableCell>
    </TableRow>
  );
}
const AccountRow = connectView(_AccountRow, state => ({
  ...employeeReadOnly(state),
  ...renderDDAmount(state),
}));
/**
 * @typedef {object} AccountsTableProps
 * @property {import("../../../state").DDAccount[]} accounts
 * @property {(account:any) => void} onDelete
 * @property {(account:any) => void} onEdit
 *
 * @param {AccountsTableProps} props
 */
function _AccountsTable({ accounts, onDelete, onEdit }) {
  // const classes = useStyles();
  return (
    <Table>
      <TableBody>
        {accounts.map(account => (
          <AccountRow
            key={account.id}
            account={account}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        ))}
      </TableBody>
    </Table>
  );
}

export const AccountsTable = React.memo(_AccountsTable);
