import React from "react";
import {
  AppBar,
  Avatar,
  Chip,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { duration } from "@material-ui/core/styles/transitions";
import clsx from "clsx";
// Local
import {
  ArrowRightIcon,
  MenuIcon,
  HomeIcon,
  HistoryIcon,
  AccessTimeIcon,
  SaveAltIcon,
  DescriptionIcon,
  FolderIcon,
  MuiPageLink,
  PersonIcon,
  PowerSettingsIcon,
  CopyrightText,
  VersionText,
} from "../components";
import { REACT_APP_COMPANY_SITE_URL } from "../config";
import { iOS } from "../device";
import { Navigation } from "../lib";
import Pages from "../pages";
import {
  isW4Enabled,
  isDdEnabled,
  avatarInfo,
  connectView,
  employeeFullName,
  employeeIsContractor,
  employerName,
  profileCount,
  showTimeKeeping,
} from "../state";
import coLogoShape from "../assets/img/logo-shape.png";
import coLogo from "../assets/img/logo-transparent.png";
import { useStyles } from "./MainMenu.styles";

const menuItems = [
  {
    text: "Home",
    icon: HomeIcon,
    url: "/",
    urlActiveIf: {
      exact: true,
    },
  },
  {
    text: "Pay history",
    icon: HistoryIcon,
    url: "/pay-history",
  },
  {
    text: "Time Keeping",
    icon: AccessTimeIcon,
    url: "/time-keeping",
    excludeIf(props) {
      return !props.showTimeKeeping;
    },
  },
  {
    text: "Direct deposit",
    icon: SaveAltIcon,
    url: "/direct-deposit",
    otherProps(props) {
      const disabled = !props.isDdEnabled;
      return {
        disabled,
        title: disabled
          ? "To activate this feature, reach out to your HR manager"
          : undefined,
        style: disabled ? { pointerEvents: "unset" } : undefined,
      };
    },
  },
  {
    text: "Tax withholdings - W4",
    icon: DescriptionIcon,
    url: "/tax-withholdings",
    excludeIf(props) {
      return props.employeeIsContractor;
    },
    otherProps(props) {
      const disabled = !props.isW4Enabled;
      return {
        disabled,
        title: disabled
          ? "To activate this feature, reach out to your HR manager"
          : undefined,
        style: disabled ? { pointerEvents: "unset" } : undefined,
      };
    },
  },
  {
    text: "Annual documents",
    icon: FolderIcon,
    url: "/documents",
  },
  {
    text: "Profile",
    icon: PersonIcon,
    url: "/profile",
  },
  {
    text: "Logout",
    icon: PowerSettingsIcon,
    onClick() {
      if (!window.confirm("Are you sure you want to log out?")) {
        return;
      }
      Navigation.go("/auth/logout");
    },
  },
];

function _MainMenu(props) {
  const { avatarInfo, employeeFullName, employerName, profileCount } = props;
  const classes = useStyles();
  // #region State
  /**
   * - We use `setCurrentPath` only to cause a re-render, not reading it.
   */
  // #endregion
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [, setCurrentPath] = React.useState(null);
  // #region Callbacks, Effects
  /**
   * - The `routeChanged` callback is created only once on component mount.
   * Not on every render. See https://reactjs.org/docs/hooks-reference.html#usecallback
   *
   * - The `Navigation.onRouteChanged` handler is only created on component
   * mount or if `routeChanged` is recreated (which only happens on mount).
   * Likewise, the retuned `remove` function is only called  React when the
   * component is unmounted or if `routeChanged` is recreated.
   * See https://reactjs.org/docs/hooks-reference.html#cleaning-up-an-effect
   * See https://reactjs.org/docs/hooks-reference.html#conditionally-firing-an-effect
   */
  // #endregion
  const routeChanged = React.useCallback(() => {
    setCurrentPath(Navigation.location.pathname);
  }, []);
  React.useEffect(() => {
    const remove = Navigation.onRouteChanged(routeChanged);
    return remove;
  }, [routeChanged]);
  // #region Input handlers
  /**
   * - These should come last because they will use things defined above.
   */
  // #endregion
  const onToggleMenu = React.useCallback(() => {
    setMobileOpen(value => !value);
  }, [setMobileOpen]);
  const onCloseMenu = React.useCallback(() => {
    setMobileOpen(false);
  }, [setMobileOpen]);
  const onOpenMenu = React.useCallback(() => {
    setMobileOpen(true);
  }, [setMobileOpen]);

  function onMenuItemClick(item, disabled) {
    if (!item.onClick) {
      item.onClick = () => {
        if (iOS) {
          // Prevent swipe back navigation from showing an open menu.
          Navigation.delayed(item.url, duration.leavingScreen + 50);
        } else {
          Navigation.go(item.url);
        }
        setMobileOpen(false);
      };
    }
    return !disabled ? item.onClick : undefined;
  }

  const menuContent = (
    <div className={classes.menuRoot}>
      <div className={classes.avatarRow}>
        <div className={classes.avatarCol}>
          <Avatar
            className={classes.avatar}
            style={{
              color: "#220e7d",
              backgroundColor: "rgb(242, 29, 199)",
              fontWeight: 600,
            }}
          >
            {avatarInfo.text}
          </Avatar>
        </div>
        {profileCount > 1 && (
          <div className={classes.chooseProfile}>
            <Typography
              className={classes.employerNameText}
              variant="caption"
              component={MuiPageLink}
              to={Pages.auth.chooseProfile}
              style={{
                color: "unset",
                verticalAlign: "middle",
                display: "inline-block",
              }}
            >
              Switch profile
              <ArrowRightIcon
                style={{
                  verticalAlign: "middle",
                  display: "inline-block",
                }}
              />
            </Typography>
          </div>
        )}
      </div>
      <Typography variant="h6" className={classes.userName}>
        {employeeFullName}
      </Typography>
      <List style={{ padding: 0 }}>
        <ListItem>
          <ListItemText
            primary={
              <Typography
                className={classes.employerNameText}
                variant="body2"
                style={{ color: "unset", textDecoration: "none" }}
              >
                {employerName}
              </Typography>
            }
          />
        </ListItem>
      </List>
      <Divider />
      <List className={classes.menuList}>
        {menuItems
          .filter(item => !item.excludeIf?.(props))
          .map(item => {
            const { text, icon: Icon, url } = item;
            /**
             * `Navigation.isActive` can only be read reliably since we're
             * re-rendering when `setCurrentPath` is called in `routeChanged`,
             * the `Navigation.onRouteChanged` event handler...
             */
            const isActive = Navigation.isActive(url, item.urlActiveIf);
            const itemProps = item.otherProps?.(props);
            return (
              <ListItem
                key={text}
                button
                className={clsx(
                  isActive ? classes.menuListItemActive : classes.menuListItem,
                  itemProps?.className,
                )}
                onClick={onMenuItemClick(item, itemProps?.disabled)}
                {...itemProps}
              >
                <ListItemIcon className={classes.menuListItemIcon}>
                  <Icon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <>
                      {text}
                      {itemProps?.disabled && (
                        <>
                          &nbsp;
                          <Chip size="small" label="Inactive" />
                        </>
                      )}
                    </>
                  }
                />
              </ListItem>
            );
          })}
      </List>
    </div>
  );

  return (
    <>
      <Hidden mdUp>
        <AppBar position="fixed" className={classes.appBar} id="mainAppBar">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open menu"
              edge="start"
              onClick={onToggleMenu}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.companyLogoShape}>
              <img src={coLogoShape} alt="Company logo" height="32" />
            </div>
          </Toolbar>
        </AppBar>
      </Hidden>
      <nav className={classes.drawer} aria-label="Main menu">
        <Hidden mdUp implementation="css">
          <SwipeableDrawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={onCloseMenu}
            onOpen={onOpenMenu}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
          >
            {menuContent}
          </SwipeableDrawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {menuContent}
            <div className={classes.companyLogoBox}>
              <Link
                color="inherit"
                href={REACT_APP_COMPANY_SITE_URL}
                target="blank"
                rel="noreferrer noopener"
              >
                <img src={coLogo} alt="Company logo" />
              </Link>
              <Typography
                variant="caption"
                display="block"
                align="center"
                style={{
                  color: "rgba(255, 255, 255, 0.38)",
                  marginLeft: -10,
                  marginRight: -10,
                }}
              >
                <VersionText /> <CopyrightText />
              </Typography>
            </div>
          </Drawer>
        </Hidden>
      </nav>
    </>
  );
}

export const MainMenu = connectView(_MainMenu, state => ({
  ...avatarInfo(state),
  ...employeeFullName(state),
  ...employeeIsContractor(state),
  ...employerName(state),
  ...profileCount(state),
  ...isDdEnabled(state),
  ...isW4Enabled(state),
  ...showTimeKeeping(state),
}));
