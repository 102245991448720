import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  theme => ({
    divider: {
      marginTop: 94,
      marginLeft: -50,
      marginRight: -50,
      boxShadow: "0 0 4px 0 rgba(0,0,0,0.12), 0 0 8px 0 rgba(0,0,0,0.16)",
    },
    fab: {
      float: "right",
      marginTop: -30,
      marginRight: 40,
      backgroundColor: theme.palette.secondary.light,
    },
    title: {
      fontSize: 28,
      margin: 0,
      marginTop: 12,
    },
    titlebar: {
      "& h4": {
        display: "inline-block",
      },
      "& label": {
        float: "right",
        marginTop: 10,
      },
    },
    errorContent: {
      display: "flex",
      alignItems: "center",
      color: "#EC5150",
      minHeight: 80,
    },
    editAmountsBtn: {
      marginTop: 30,
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 30,
      paddingRight: 30,
    },
    loading: {
      textAlign: "center",
      minHeight: 38,
    },
    noResultsRow: {
      textAlign: "center",
      paddingTop: 40,
    },
  }),
  {
    classNamePrefix: "AccountsPage",
  },
);
