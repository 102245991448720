import React from "react";
import { Box, Grid, IconButton } from "@material-ui/core";
// Local
import DonutChartImg from "../../../assets/img/donut-chart-transparent_34.png";
import { DownloadIcon, VisibilityIcon } from "../../../components";
import { formatDate, formatHours, PageLink } from "../../../lib";
import { useStyles } from "./PayHistoryPageTableSmall.styles";
import { Pages } from "../../index";

function _PayHistoryPageTableSmall({ onDownload, renderAmount, rows }) {
  const classes = useStyles();
  return (
    <Grid container>
      {rows.map(row => (
        <Grid key={row.id} item xs={12}>
          <Box boxShadow={3} mt={2} px={2} pb={2}>
            <Box className={classes.mdataRowBox}>
              <Box className={classes.dataCell}>
                <strong>{formatDate(row.checkDate)}</strong>
              </Box>
              <Box className={classes.mdataCellButtons}>
                <IconButton
                  component={PageLink}
                  className={classes.button}
                  aria-label="View"
                  title="View"
                  to={Pages.payHist.details}
                  params={{ id: row.id }}
                >
                  <VisibilityIcon fontSize="small" />
                </IconButton>
                <IconButton
                  className={classes.button}
                  aria-label="Download Paystub"
                  title="Download Paystub"
                  onClick={() => onDownload(row.id)}
                >
                  <DownloadIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>
            <Box className={classes.mdataCellRow2}>
              <Box className={classes.mdataCellIcon}>
                <img src={DonutChartImg} width={34} height={34} alt="Icon" />
              </Box>
              <Box className={classes.mdataCellHours}>
                <strong>{formatHours(row.hours)}</strong>
                <br />
                <span className={classes.label}>Hours</span>
              </Box>
              <Box className={classes.mdataCellTakeHome}>
                <strong className={classes.money}>
                  {renderAmount(row.net)}
                </strong>
                <br />
                <span className={classes.label}>Take home</span>
              </Box>
              <Box className={classes.mdataCellGross}>
                <strong>{renderAmount(row.gross)}</strong>
                <br />
                <span className={classes.label}>Gross</span>
              </Box>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}

export const PayHistoryPageTableSmall = React.memo(_PayHistoryPageTableSmall);
