import React from "react";
import { Box, Button, Grid, IconButton, Typography } from "@material-ui/core";
// Local
import { DeleteIcon, EditIcon, FullScreenDialog } from "../../../components";
import { formatPhone, useOnMount } from "../../../lib";
import {
  connectView,
  emergencyContacts,
  employeeReadOnly,
  ProfileActions,
} from "../../../state";
import { useMobile } from "../../../themes";
import { TabInfo, TabPanel, EmergencyContactForm } from "./";

const spacerStyle = { marginBottom: 6 };

function _ContactCard({ contact, employeeReadOnly, onEdit, onDelete }) {
  return (
    <Box boxShadow={3} style={{ padding: 16, paddingBottom: 14, fontSize: 14 }}>
      <Typography
        variant="h6"
        style={{ display: "inline-block", fontSize: 16, marginBottom: 12 }}
      >
        {contact.name ?? ""}
      </Typography>
      {!employeeReadOnly && (
        <div className="inblock" style={{ float: "right" }}>
          <IconButton aria-label="Edit" title="Edit" onClick={onEdit}>
            <EditIcon fontSize="small" color="primary" />
          </IconButton>
          <IconButton aria-label="Delete" title="Delete" onClick={onDelete}>
            <DeleteIcon fontSize="small" color="primary" />
          </IconButton>
        </div>
      )}
      <br />
      <div style={spacerStyle}>
        <Typography
          variant="caption"
          style={{ color: "rgba(0,0,0,0.38)", marginRight: 38 }}
        >
          Email
        </Typography>
        {contact.email ?? ""}
      </div>
      <div style={spacerStyle}>
        <Typography
          variant="caption"
          style={{ color: "rgba(0,0,0,0.38)", marginRight: 32 }}
        >
          Phone
        </Typography>
        {formatPhone(contact.homePhone)}
      </div>
      <div style={spacerStyle}>
        <Typography
          variant="caption"
          style={{ color: "rgba(0,0,0,0.38)", marginRight: 22 }}
        >
          Relation
        </Typography>
        {contact.relationship ?? ""}
      </div>
    </Box>
  );
}
const ContactCard = React.memo(_ContactCard);

function _ContactList({
  emergencyContacts,
  employeeReadOnly,
  handleEditRow,
  handleDeleteRow,
}) {
  return emergencyContacts.map(contact => (
    <Grid key={contact.id} item xs={12}>
      <ContactCard
        contact={contact}
        employeeReadOnly={employeeReadOnly}
        onEdit={handleEditRow(contact)}
        onDelete={handleDeleteRow(contact)}
      />
    </Grid>
  ));
}
const ContactList = React.memo(_ContactList);

function _ContactsTab({
  actions: { deleteEmergencyContact, getEmergencyContacts },
  emergencyContacts,
  employeeReadOnly,
  showing,
}) {
  const [editingId, editId] = React.useState(null);
  const editing = editingId !== null;

  const handleDeleteRow = React.useCallback(
    row => {
      async function onClickDelete(e) {
        e.preventDefault();
        if (
          !window.confirm(
            "Are you sure that you want to delete the following contact?\n\n" +
              `${row.name} - ${row.email} - ${formatPhone(row.homePhone)}`,
          )
        ) {
          return;
        }
        await deleteEmergencyContact(row.id);
        getEmergencyContacts();
      }
      return onClickDelete;
    },
    [deleteEmergencyContact, getEmergencyContacts],
  );

  /** @param {import("../../../state").EmergencyContact} row */
  const handleEditRow = React.useCallback(
    row => {
      /** @param {React.SyntheticEvent<HTMLButtonElement>} e */
      async function onClickEdit(e) {
        e.preventDefault();
        editId(row.id);
      }
      return onClickEdit;
    },
    [editId],
  );

  const onClickCreate = React.useCallback(() => {
    editId(0);
  }, [editId]);

  const onCancelEdit = React.useCallback(() => {
    editId(null);
  }, [editId]);

  const onCompletedEdit = React.useCallback(() => {
    editId(null);
    getEmergencyContacts();
  }, [editId, getEmergencyContacts]);

  useOnMount(() => {
    getEmergencyContacts();
  });

  const isMobile = useMobile();

  if (!showing) {
    return null;
  }
  return (
    <TabPanel index={TabInfo.contacts.idx} showing={showing}>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ paddingTop: isMobile ? 0 : undefined }}>
          <Typography
            variant="h6"
            style={{ display: "inline-block", marginTop: isMobile ? -16 : 0 }}
          >
            Emergency contacts
          </Typography>
          {!isMobile && !employeeReadOnly && (
            <Button
              variant="contained"
              color="secondary"
              size="large"
              style={{ float: "right" }}
              onClick={onClickCreate}
            >
              + ADD CONTACT
            </Button>
          )}
        </Grid>
        <ContactList
          emergencyContacts={emergencyContacts}
          employeeReadOnly={employeeReadOnly}
          handleDeleteRow={handleDeleteRow}
          handleEditRow={handleEditRow}
        />
        {isMobile && !employeeReadOnly && (
          <Button
            variant="contained"
            color="secondary"
            size="large"
            style={{ width: "100%", margin: 12, padding: 14 }}
            onClick={onClickCreate}
          >
            + ADD CONTACT
          </Button>
        )}
        <FullScreenDialog onClose={onCancelEdit} open={editing}>
          <EmergencyContactForm
            id={editingId}
            onCancel={onCancelEdit}
            onComplete={onCompletedEdit}
            title={
              editingId === 0
                ? "New emergency contact"
                : "Edit emergency contact"
            }
          />
        </FullScreenDialog>
      </Grid>
    </TabPanel>
  );
}

export const ContactsTab = connectView(
  _ContactsTab,
  state => ({
    ...emergencyContacts(state),
    ...employeeReadOnly(state),
  }),
  [ProfileActions],
);
