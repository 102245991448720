import { AccountsPage } from "./AccountsPage";

export const DirectDepositPages = {
  index: {
    anon: false,
    path: "/direct-deposit",
    title: "Direct Deposit Accounts",
    type: "PAGE_DIRECTDEPOSIT_ACCOUNTS",
    view: AccountsPage,
  },
};
export default DirectDepositPages;

export const DirectDepositArea = {
  pages: DirectDepositPages,
};
