import React from "react";
import { Button, Grid } from "@material-ui/core";
// Local
import { useMobile } from "../../themes";
import { useStyles } from "./DialogSaveCancelButtons.styles";

function _DialogSaveCancelButtons({
  cancelDisabled = false,
  cancelMobile = false,
  cancelText = "CANCEL",
  noCancel = false,
  onCancel,
  onSave,
  saveDisabled = false,
  saveText = "SAVE",
}) {
  const classes = useStyles();
  const isMobile = useMobile();
  function onClickCancel(e) {
    e.preventDefault();
    onCancel();
  }
  function onClickSave(e) {
    e.preventDefault();
    onSave();
  }
  return (
    <Grid item xs={12} className={classes.gridItem}>
      {(!isMobile || cancelMobile) && !noCancel && (
        <Button
          className={classes.largerBtn}
          disabled={cancelDisabled}
          onClick={onClickCancel}
          variant="contained"
          size="large"
        >
          {cancelText}
        </Button>
      )}
      <Button
        className={classes.largerBtn}
        color="secondary"
        disabled={saveDisabled}
        onClick={onSave && onClickSave}
        variant="contained"
        size="large"
        type="submit"
      >
        {saveText}
      </Button>
    </Grid>
  );
}

export const DialogSaveCancelButtons = React.memo(_DialogSaveCancelButtons);
