import { authGet } from "../../lib";
import { UIActions } from "../ui/actions";

const type = {
  DASHBOARD_REQUEST: "DASHBOARD_REQUEST",
  DASHBOARD_FAILURE: "DASHBOARD_FAILURE",
  DASHBOARD_SUCCESS: "DASHBOARD_SUCCESS",
};

export const DashboardActions = {
  type,

  getDashboardData() {
    return async dispatch => {
      dispatch({ type: type.DASHBOARD_REQUEST });
      dispatch(UIActions.setUILoading(true));
      const resp = await authGet("/api/my/dashboard/data");
      const { data, error } = resp;
      const resp2 = await authGet("/api/my/dashboard/zayZoonUrl");
      const { url } = resp2.data;
      data.zayZoonUrl = url;
      dispatch(UIActions.setUILoading(false));
      if (error) {
        dispatch(UIActions.showError());
      }
      dispatch({ type: type.DASHBOARD_SUCCESS, data, error });
      return resp;
    };
  },
};

/**
 * @typedef {object} DashboardPayHist
 * @property {number} prNum
 * @property {string} checkDate
 * @property {number} hours
 * @property {number} gross
 * @property {number} net
 * @property {number} checkCounter
 * @property {"Regular" | "Other"} type
 *
 * @typedef {object} DashboardApiData
 * @property {DashboardPayHist[]} payHist
 *
 */
