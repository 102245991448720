import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  theme => ({
    button: { color: "rgba(0,0,0,0.38)" },
    content: {
      padding: 10,
      backgroundColor: "grey",
      color: theme.palette.common.white,
    },
  }),
  {
    classNamePrefix: "PopoverHelpIcon",
  },
);
