import { PayHistoryPage } from "./PayHistoryPage";
import { PayHistoryDetailsPage } from "./PayHistoryDetailsPage";

export const PayHistoryPages = {
  details: {
    anon: false,
    path: "/pay-history/:id",
    title: "Pay",
    type: "PAGE_PAYHISTORY_DETAILS",
    view: PayHistoryDetailsPage,
  },
  index: {
    anon: false,
    path: "/pay-history",
    title: "Pay History",
    type: "PAGE_PAYHISTORY",
    view: PayHistoryPage,
  },
};
export default PayHistoryPages;

export const PayHistoryArea = {
  pages: PayHistoryPages,
};
