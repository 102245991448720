import React from "react";
import { Box, FormControlLabel, Switch, Typography } from "@material-ui/core";
// Local
import { InfoOutlinedIcon } from "../../../components";
import { formatDate, handleCheck } from "../../../lib";
import {
  connectView,
  preferHideInfo,
  personalBio,
  renderPrivateNum,
  PrefActions,
} from "../../../state";

function _PersonalBioCard({
  actions: { updateHideInfo },
  preferHideInfo,
  personalBio: { bDay, genderText, ssn },
  renderPrivateNum,
}) {
  const onChangeHideInfo = handleCheck(updateHideInfo);
  return (
    <Box boxShadow={3} style={{ minHeight: 160, padding: 16, fontSize: 14 }}>
      <Typography
        variant="h6"
        style={{ display: "inline-block", fontSize: 16, marginBottom: 20 }}
      >
        Personal bio
      </Typography>
      <FormControlLabel
        control={
          <Switch
            color="secondary"
            checked={preferHideInfo}
            onChange={onChangeHideInfo}
          />
        }
        label="Hide info"
        labelPlacement="start"
        style={{ float: "right", paddingRight: 5 }}
      />
      <br />
      <span style={{ color: "rgba(0,0,0,0.38)", marginRight: 22 }}>
        DOB
      </span>{" "}
      {renderPrivateNum(formatDate(bDay))}
      <div style={{ height: 10 }}>&nbsp;</div>
      <span style={{ color: "rgba(0,0,0,0.38)", marginRight: 22 }}>
        SSN
      </span>{" "}
      {renderPrivateNum(ssn)}
      <div style={{ height: 10 }}>&nbsp;</div>
      <span style={{ color: "rgba(0,0,0,0.38)", marginRight: 6 }}>
        Gender
      </span>{" "}
      {genderText ?? ""}
      <div style={{ height: 16 }}>&nbsp;</div>
      <div
        style={{
          color: "rgba(0,0,0,0.38)",
          display: "flex",
          marginLeft: -2,
          alignItems: "center",
        }}
      >
        <InfoOutlinedIcon />
        <span style={{ marginLeft: 32 }}>
          Please contact your employer to update personal.
        </span>
        information.
      </div>
    </Box>
  );
}

export const PersonalBioCard = connectView(
  _PersonalBioCard,
  state => ({
    ...preferHideInfo(state),
    ...personalBio(state),
    ...renderPrivateNum(state),
  }),
  [PrefActions],
);
