export function payHistDetails(state) {
  const {
    details = {
      taxDetails: [],
    },
  } = state.payHist;
  return {
    payHistDetails: details,
  };
}

export function payHistory(state) {
  const { list = [], maybeHasMore = false } = state.payHist;
  return {
    payHistory: list,
    payHistoryMaybeHasMore: maybeHasMore,
  };
}
