import { makeStyles } from "@material-ui/core";

const tableCell = {
  paddingLeft: 24,
  paddingRight: 24,
  paddingTop: 12,
  paddingBottom: 12,
};
const shrinkCell = {
  width: 10,
  whiteSpace: "nowrap",
};

export const useStyles = makeStyles(
  theme => ({
    button: {
      color: theme.palette.primary.light,
    },

    dateCell: {
      ...tableCell,
      ...shrinkCell,
    },
    grossCell: tableCell,
    hoursCell: {
      ...tableCell,
    },
    iconCell: {
      ...tableCell,
      ...shrinkCell,
    },

    label: {
      color: theme.palette.grey[700],
    },
    money: {
      color: theme.palette.secondary.light,
    },

    netCell: tableCell,

    tableRow: {
      borderRadius: 3,
      boxShadow: theme.shadows[3],
    },
    tableRowEmpty: {
      "& > td": {
        borderLeft: "none",
        borderRight: "none",
        background: "transparent !important",
        padding: 8,
      },
    },
    toolCell: {
      // ...tableCell,
      padding: 0,
      paddingRight: 16,
      ...shrinkCell,
    },
  }),
  {
    classNamePrefix: "PayHistoryPageTable",
  },
);
