import React from "react";
import { ButtonGroup } from "@material-ui/core";

const ButtonSwitchStyle = {
  paddingLeft: 60,
  paddingRight: 60,
  textTransform: "none",
  fontSize: 14,
  fontWeight: "normal",
};

const NotSelectedStyle = {
  backgroundColor: "#ffffff",
};

export const ButtonSwitch = React.memo(
  /**
   * @typedef {import("@material-ui/core/ButtonGroup").ButtonGroupProps}
   * ButtonGroupProps
   *
   * @typedef {object} ButtonSwitchProps
   * @property {React.ReactNode} children
   * @property {string} name A name to assign to the switch and each button, for
   * use with form change handlers.
   * @property {(e:React.SyntheticEvent<HTMLButtonElement>,value:any)=>void}
   * onChange Called if the user clicks one of the buttons causing the value to
   * change. `onChange` is NOT called if a child button `onClick` handler sets
   * `e.cancelled = true`.
   * @property {object} [selectedProps] These props will be applied to the
   * selected button.
   * @property {any} value The current value.
   *
   * @param {ButtonGroupProps & ButtonSwitchProps} props
   */
  function ButtonSwitch(props) {
    const {
      children,
      name,
      onChange,
      selectedProps = {
        color: "primary",
      },
      value,
      style,
      ...rest
    } = props;
    const modChildren = React.Children.map(children, child => {
      const { onClick: onClickChild, value: childValue } = child.props;
      const childProps = {
        name,
        color: "",
        style: {
          ...ButtonSwitchStyle,
          ...style,
        },
        /** @param {React.SyntheticEvent<HTMLButtonElement>} e */
        onClick(e) {
          if (onClickChild) {
            onClickChild(e);
            if (e.cancelled) {
              delete e.cancelled;
              return;
            }
          }
          if (onChange && childValue !== value) {
            onChange(e, value);
          }
        },
      };
      if (value === childValue) {
        Object.assign(childProps, selectedProps);
      } else {
        Object.assign(childProps.style, NotSelectedStyle);
      }
      return React.cloneElement(child, childProps);
    });
    return (
      <ButtonGroup variant="contained" size="small" {...rest}>
        {modChildren}
      </ButtonGroup>
    );
  },
);
