import React from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
// Local
import {
  DownloadIcon,
  VisibilityIcon,
  NetPayDonutChart,
  HidePaySwitch,
} from "../../components";
import { formatHours, PageLink, useOnMount } from "../../lib";
import {
  DashboardActions,
  PayHistoryActions,
  connectView,
  dashboardData,
  employeeFirstName,
  employeeIsContractor,
  renderAmount,
  showZayZoonButton,
  showPerksButton,
  uiLoading,
} from "../../state";
import { Pages } from "../../pages";
import { useMobile } from "../../themes";
import { PayHistoryTable } from "./components/PayHistoryTable";
import { useStyles } from "./DashboardPage.styles";
import "../../assets/css/perks.css";
import { HiChevronRight } from "react-icons/hi";

function _DashboardPage({
  actions: { downloadPayHistoryDocument, getDashboardData },
  employeeFirstName,
  employeeIsContractor,
  lastPaycheck,
  payHist,
  renderAmount,
  showZayZoonButton,
  showPerksButton,
  zayZoonUrl,
  uiLoading,
}) {
  const classes = useStyles();
  const isMobile = useMobile();
  const perksUrl = "https://www.perksatwork.com/join/wmdyt9c?hgt=c97f1aa3-77b2-48c4-4282-7d727b2a313b&hgti=1";

  const hasPayHist = payHist.length > 0;

  const inViewPort = (entries, observer) => {
    entries.forEach(entry => {
      entry.target.classList.toggle("is-inViewport", entry.isIntersecting);
    });
  }

  const obs = new IntersectionObserver(inViewPort);

  document.querySelectorAll('[data-inviewport]').forEach(el => {
    obs.observe(el, {});
  });

  useOnMount(() => {
    getDashboardData();
  });

  const zayZoonClick = () => {
    if (zayZoonUrl) {
      window.open(zayZoonUrl);
    }
  };

  const PerksClick = () => {
    if (perksUrl) {
      window.open(perksUrl);
    }
  };

  const getZayZoon = () => {
    return (
      <Box boxShadow={3} className={classes.zayZoonCard}>
        <div onClick={zayZoonClick} className={classes.zayZoonContent}>
          <h3>FREE YOURSELF FROM PAYDAY</h3>
          <div
            style={{ fontSize: 12, fontWeight: 600, paddingBottom: 10 }}
          >
            Use ZayZoon to get your wages as you earn them. Avoid late
            bill payments, overdraft fees and payday loans.
          </div>
          <h3>PAYCHECK ON-DEMAND</h3>
          <div style={{ textAlign: "right" }}>
            <img
              src={require("../../assets/img/zayzoon-logo.png")}
              alt=""
              style={{ filter: "brightness(3)", width: 120 }}
            />
          </div>
        </div>
      </Box>)
  }

  const getPerks = () => {
    return (<Box boxShadow={3} className={classes.perksCard}>
      <div style={{ position: 'relative' }} className="test" data-inViewPort>
        <div className="main" data-inviewport>
          <p className={isMobile ? "text-mobile" : "text"} data-inviewport>We’re ecxited to announce</p>
          <img src={require("../../assets/img/hlogo.png")}
            alt="#"
            className="logo" data-inviewport />

        </div>
        <div className="main-text" data-inviewport>
          <div className="first">
            <div className="divstyle">
              <img src={require("../../assets/img/ilogo.png")}
                alt="#"
                className="main-logo" data-inviewport />
            </div>
            <span className={isMobile ? "discount-mobile" : "discount"}>Discounts. Rewards. Wellness.</span>
          </div>
          {isMobile ?
            <div>
              <div className="second" data-inviewport>
                <p className="tagline-mobile" data-inviewport>
                  Our brand-new employee perk platform, designed to help you lead{" "}
                  <span className="tagline2">a healthier and happier life.</span>
                </p>

              </div>
              <div>
                <a href="https://support.brandspaycheck.com/hc/en-us/articles/4410730124179" className="href-mobile" target="_blank" rel="noopener noreferrer">
                  <img src={require("../../assets/img/first.svg")} alt="More Information and Support" className="info-links-mobile" />
                  <span className="link-text-mobile">Learn more</span>
                </a>
                <a href="https://www.youtube.com/watch?v=3FmGq1mriIQ" target="_blank" className="href-mobile" rel="noopener noreferrer">
                  <img src={require("../../assets/img/second.svg")} alt="Video Introduction" style={{ margin: "0 0 0 12px" }} className="info-links-mobile" />
                  <span className="link-text-mobile">Watch Video</span>
                </a>
                <button className="button-mobile" onClick={PerksClick}>
                  Sign up free <HiChevronRight className="hiChevron-mobile" />
                </button>
              </div>
            </div> :
            <div className="second">
              <p className={isMobile ? "tagline-mobile" : "tagline"}>
                Our brand-new employee perk platform, designed to help you lead{" "}
                <span className="tagline2">a healthier and happier life.</span>
              </p>
              <a href="https://support.brandspaycheck.com/hc/en-us/articles/4410730124179" target="_blank" rel="noopener noreferrer">
                <img src={require("../../assets/img/first.svg")} alt="More Information and Support" className="info-links" />
              </a>
              <a href="https://www.youtube.com/watch?v=3FmGq1mriIQ" target="_blank" rel="noopener noreferrer">
                <img src={require("../../assets/img/second.svg")} alt="Video Introduction" style={{ margin: "0 12px" }} className="info-links" />
              </a>

              <button className="button" onClick={PerksClick}>
                Sign up free <HiChevronRight />
              </button>
            </div>}
        </div>
      </div>
    </Box>)
  }

  console.log(showPerksButton);
  console.log(showZayZoonButton);

  return (
    <Grid container spacing={isMobile ? 0 : 3}>
      <Grid item xs={12}>
        <div className={classes.titlebar}>
          <Typography className={classes.title} variant="h4">
            {!employeeIsContractor && employeeFirstName
              ? `Hey ${employeeFirstName}!`
              : ""}
          </Typography>
          <HidePaySwitch />
        </div>
      </Grid>
      <Grid container item spacing={3}>
        <Grid item xs={12} lg={6}>
          <Box boxShadow={3} className={classes.netPayCard}>
            <div className={classes.netPayChart}>
              <NetPayDonutChart
                deductions={{
                  amount: renderAmount(lastPaycheck.deds),
                  value: lastPaycheck.deds,
                }}
                gross={{
                  amount: renderAmount(lastPaycheck.gross),
                  value: lastPaycheck.gross,
                }}
                net={{
                  amount: renderAmount(lastPaycheck.net),
                  value: lastPaycheck.net,
                }}
                taxes={{
                  amount: renderAmount(lastPaycheck.taxes),
                  value: lastPaycheck.taxes,
                }}
                title={
                  <Typography variant="h6">
                    <strong>Most Recent Paycheck</strong>
                  </Typography>
                }
                subtitle={
                  <Typography variant="caption">Take home pay</Typography>
                }
                footnote={`${formatHours(lastPaycheck.hours)} hours`}
              />
            </div>
            {hasPayHist && (
              <div className={classes.netPayTools}>
                <Button
                  component={PageLink}
                  color="primary"
                  to={Pages.payHist.details}
                  params={{ id: lastPaycheck.id || "0" }}
                >
                  <VisibilityIcon />
                  &nbsp; View
                </Button>
                <Button
                  color="primary"
                  onClick={() => downloadPayHistoryDocument(lastPaycheck.id)}
                >
                  <DownloadIcon />
                  &nbsp; Download Paystub
                </Button>
              </div>
            )}
          </Box>
          {showZayZoonButton && getZayZoon()}
          {!showZayZoonButton && showPerksButton && getPerks()}
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box boxShadow={3} className={classes.payHistoryCard}>
            <div className={classes.payHistoryContent}>
              <h3>Pay History</h3>
              <Button
                color="primary"
                component={PageLink}
                className={classes.payHistoryViewAll}
                to={Pages.payHist.index}
              >
                View All
              </Button>
            </div>
            <div className={classes.centered}>
              {!uiLoading && !hasPayHist && (
                <Typography variant="subtitle1">
                  There is no recent pay history for you at this time.
                </Typography>
              )}
            </div>
            <PayHistoryTable records={payHist} />
          </Box>
          {showPerksButton && showZayZoonButton && getPerks()}
        </Grid>
      </Grid>
    </Grid >
  );
}

export const DashboardPage = connectView(
  _DashboardPage,
  state => {
    console.log(state)
    return {
      ...employeeFirstName(state),
      ...employeeIsContractor(state),
      ...dashboardData(state),
      ...renderAmount(state),
      ...showZayZoonButton(state),
      ...showPerksButton(state),
      ...uiLoading(state),
    };
  },
  [DashboardActions, PayHistoryActions],
);
