import { makeStyles } from "@material-ui/core";

// export const useStyles = makeStyles(
//   theme => ({
//     table: {
//       //
//     },
//   }),
//   {
//     classNamePrefix: "AccountsTable",
//   },
// );

export const useRowStyles = makeStyles(
  theme => {
    const cell = {
      [theme.breakpoints.down("sm")]: {
        padding: 8,
      },
    };
    return {
      accountIdCell: {
        ...cell,
        width: 10,
      },
      accountNameCell: {
        ...cell,
        width: 10,
        whiteSpace: "nowrap",
      },
      accountNumCell: {
        ...cell,
        width: 10,
        whiteSpace: "nowrap",
      },
      amountCell: {
        ...cell,
        width: 100,
        color: "#6FA200",
        whiteSpace: "nowrap",
      },
      amountChip: {
        marginLeft: 8,
      },
      amountContainer: {
        display: "inline-flex",
        alignItems: "center",
      },
      amountText: {
        minWidth: 50,
        wordWrap: "break-word",
      },
      toolCell: {
        ...cell,
        width: 10,
        whiteSpace: "nowrap",
      },
      lastDep: {
        color: "#97c707",
      },
      lastDepLabel: {
        color: "rgba(0,0,0,0.6)",
      },
    };
  },
  {
    classNamePrefix: "AccountRow",
  },
);
