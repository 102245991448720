import { makeStyles } from "@material-ui/core";
import desktopBg from "../assets/img/bg-blue_500x850.jpg";

export const useStyles = makeStyles(
  theme => ({
    baseContainer: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      backgroundColor: "#2D12A3",
      [theme.breakpoints.up("sm")]: {
        backgroundColor: theme.palette.background.paper,
        flexGrow: 1,
      },
    },
    desktopContainer: {
      display: "flex",
    },
    desktopSidebar: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        backgroundImage: `url(${desktopBg})`,
        backgroundSize: "cover",
        display: "block",
        flex: "0 0 auto",

        // TODO-CSS: How to scale the image contained within the sidebar?

        // paddingLeft: 55,
        width: "30vw",
      },
    },
    content: {
      flexGrow: 1,
      marginTop: '30vh'
    },
    footerBox: {
      color: "grey",
      textAlign: "center",
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        color: "lightgrey",
      },
    },
    logoBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "30vh",
      justifyContent: "center",
      backgroundColor: theme.palette.background.paper,
      marginLeft: -16,
      marginRight: -16,

      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
  }),
  {
    classNamePrefix: "AuthAreaLayout",
  },
);
