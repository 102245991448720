import React from "react";
import {
  Card,
  Grid,
  List,
  ListItem,
  // ListItemText,
  Typography,
} from "@material-ui/core";
// Local
import {
  AuthActions,
  currentProfileId,
  ProfileActions,
  useDispatch,
  useSelector,
} from "../../state";
// import { BusinessIcon } from "../../components";
import { Navigation, useOnMount } from "../../lib";
import Pages from "../";
import { useStyles } from "./ChooseProfilePage.styles";

function _ChooseProfilePage({
  pageRoute: {
    query: { after: navigateAfterLoginURL },
  },
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const curProfileId = useSelector(currentProfileId);
  const [profiles, setProfiles] = React.useState([]);
  const [switching, setSwitching] = React.useState(false);

  const clickProfile = React.useCallback(
    item => {
      return async e => {
        e.preventDefault();
        if (switching) {
          return;
        }
        setSwitching(true);
        if (item.id === curProfileId) {
          Navigation.go(Pages.main.dashboard.path);
          return;
        }
        const result = await dispatch(AuthActions.switchProfile(item.id));
        if (result.error) {
          alert("There was an error. Please try again.");
          Navigation.go(Pages.auth.logout.path);
          return;
        }
        window.location.replace(navigateAfterLoginURL || "/");
      };
    },
    [switching, curProfileId, navigateAfterLoginURL, dispatch],
  );

  useOnMount(() => {
    dispatch(ProfileActions.getProfilesList()).then(items =>
      setProfiles(items),
    );
  });

  return (
    <div className={classes.root}>
      <Grid>
        <Grid item xs={12}>
          <List>
            {profiles.map(item => (
              <ListItem key={item.id}>
                <Card className={classes.card} onClick={clickProfile(item)}>
                  <Typography variant="h5">
                    {/* <BusinessIcon fontSize="small" color="disabled" /> */}
                    {item.title}
                  </Typography>
                  <p>{item.body1}</p>
                  <p>{item.body2}</p>
                </Card>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </div>
  );
}
export const ChooseProfilePage = React.memo(_ChooseProfilePage);
