import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  theme => ({
    titlebar: {
      "& h4": {
        display: "inline-block",
        [theme.breakpoints.down("xs")]: {
          fontSize: 18,
          marginTop: 8,
        },
      },
      "& label": {
        float: "right",
        [theme.breakpoints.up("sm")]: {
          marginTop: 10,
        },
        // [theme.breakpoints.down("sm")]: {
        position: "relative",
        top: -58,
        right: 7,
        // },
      },
    },
    title: {
      fontSize: 28,
      margin: 0,
      [theme.breakpoints.up("sm")]: {
        marginTop: 12,
      },
    },
    netPayCard: {
      display: "flex",
      flexDirection: "column",
      margin: 10,
      minHeight: 400,
      paddingTop: 30,
      [theme.breakpoints.down("sm")]: {
        paddingTop: 15,
      },
    },
    netPayChart: {
      flex: 2,
      display: "flex",
      justifyContent: "center",
      margin: 10,
      marginBottom: 0,
    },
    netPayTools: {
      display: "flex",
      justifyContent: "space-between",
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20,
      color: "rgba(0,0,0,0.6)",
    },
    payHistoryCard: {
      margin: 10,
      minHeight: 400,
      padding: 10,
    },
    payHistoryContent: {
      height: 360,
      minWidth: 310,
      overflowY: "auto",
    },
    divider: {
      marginTop: 20,
      [theme.breakpoints.up("md")]: {
        borderLeft: "1px solid rgba(0,0,0,0.16)",
        paddingLeft: 20,
      },
    },
    netPayDownload: {
      float: "right",
      marginRight: 10,
    },
  }),
  {
    classNamePrefix: "PayHistoryDetailsPage",
  },
);
