import React from "react";
import {
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
// Local
import { MuiPageLink, VisibilityIcon } from "../../components";
import {
  hasAuthRequestToken,
  hasInvalidTokenError,
  Navigation,
  useInputValue,
  useOnMount,
} from "../../lib";
import Pages from "../../pages";
import { AuthActions, connectView } from "../../state";
import { useMobile } from "../../themes";
import { useStyles } from "./ResetPasswordPage.styles";

function _ResetPasswordPage({
  actions: { confirmAccount, confirmAccountExpired, resetPassword },
  pageRoute: {
    location: { pathname },
    query: { email, token, expires },
  },
}) {
  const confirming = Pages.auth.confirmAccount.path === pathname;
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [password1, onChangePassword1] = useInputValue("");
  const [password2, onChangePassword2] = useInputValue("");
  const [passwordInputType1, setPasswordInputType1] = React.useState(
    "password",
  );
  const [passwordInputType2, setPasswordInputType2] = React.useState(
    "password",
  );

  const isMobile = useMobile();

  const onClickSubmit = React.useCallback(
    /** @param {React.SyntheticEvent<HTMLButtonElement>} e */
    async e => {
      e.preventDefault();

      if (password1.length < 8) {
        setErrorMessage("Password must be at least 8 characters long.");
        return;
      }

      if (!password1.match(/[A-Z]/g) || !password1.match(/[a-z]/g)) {
        setErrorMessage(
          "Password must contain uppercase and lowercase characters.",
        );
        return;
      }

      if (!password1.match(/[0-9]/g)) {
        setErrorMessage("Password must contain at least one digit.");
        return;
      }

      if (!password1.match(/[^a-zA-Z0-9\s]/g)) {
        setErrorMessage(
          "Password must contain at least one punctuation character.",
        );
        return;
      }
      if (password1 !== password2) {
        setErrorMessage("Passwords must match.");
        return;
      }
      setErrorMessage("");
      const doAction = confirming ? confirmAccount : resetPassword;
      const result = await doAction({
        email,
        token,
        password1,
        password2,
      });
      if (!result.error) {
        Navigation.redirect(Pages.auth.login.path + "?reset=true");
      } else if (result.error.code === "AccountConfirmed") {
        // They're not logged in (we checked in useOnMount). They must not know
        // their password, but they don't need to request another invitation.
        Navigation.redirect(
          Pages.auth.forgotPassword.path +
            "?expiredEmail=" +
            encodeURIComponent(email),
        );
      } else if (hasInvalidTokenError(result.error)) {
        if (confirming) {
          setErrorMessage(
            "Link expired. Please request another invitation to continue.",
          );
        } else {
          Navigation.redirect(
            Pages.auth.forgotPassword.path +
              "?expiredEmail=" +
              encodeURIComponent(email),
          );
        }
      } else {
        setErrorMessage("There was an error. Please try again.");
      }
    },
    [
      confirmAccount,
      confirming,
      email,
      password1,
      password2,
      resetPassword,
      token,
    ],
  );

  const onClickTogglePassword1 = React.useCallback(
    e => {
      e.preventDefault();
      setPasswordInputType1(current => {
        return current === "password" ? "text" : "password";
      });
    },
    [setPasswordInputType1],
  );

  const onClickTogglePassword2 = React.useCallback(
    e => {
      e.preventDefault();
      setPasswordInputType2(current => {
        return current === "password" ? "text" : "password";
      });
    },
    [setPasswordInputType2],
  );

  async function onMountAsync() {
    // If the user clicked the invitation email just to get back to the app,
    // navigate to the home page for them...
    if (hasAuthRequestToken()) {
      window.location.replace("/");
    }
    // If the URL has an expires time for the client (older ones don't), check
    // if that expired already and if so, send them to the login page.
    if (expires) {
      const dateExp = new Date(expires);
      const timeNow = new Date().getTime();
      if (timeNow > dateExp.getTime()) {
        await confirmAccountExpired({ email });
        setTimeout(() => {
          window.location.replace(
            Pages.auth.login.path +
              "?expiredEmail=" +
              encodeURIComponent(email),
          );
        }, 100);
      }
    }
  }

  useOnMount(() => {
    onMountAsync();
  });

  return (
    <div className={classes.rootContainer}>
      <Typography variant="h5">
        {confirming ? "New account sign up" : "Create new password"}
      </Typography>
      <p className={classes.error}>{errorMessage}</p>
      <Typography className={classes.help}>
        Passwords must be at least <strong>8 characters long</strong> and
        contain <strong>uppercase</strong> and <strong>lowercase</strong>{" "}
        characters, <strong>digits</strong> and <strong>punctuation</strong>{" "}
        characters.
        <br />
        <br />
        It's a good idea to use a strong password that you're not using
        elsewhere.
      </Typography>
      <form className="form" noValidate>
        <TextField
          margin="normal"
          required
          fullWidth
          name="password1"
          label="Enter New Password"
          type={passwordInputType1}
          id="password1"
          autoComplete="new-password"
          value={password1}
          onChange={onChangePassword1}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <VisibilityIcon
                  onClick={onClickTogglePassword1}
                  style={{
                    cursor: "pointer",
                    color: isMobile
                      ? "rgba(255,255,255,0.38)"
                      : "rgba(0,0,0,0.38)",
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password2"
          label="Reenter New Password"
          type={passwordInputType2}
          id="password2"
          autoComplete="reenter-new-password"
          value={password2}
          onChange={onChangePassword2}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <VisibilityIcon
                  onClick={onClickTogglePassword2}
                  style={{
                    cursor: "pointer",
                    color: isMobile
                      ? "rgba(255,255,255,0.38)"
                      : "rgba(0,0,0,0.38)",
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
        <div style={{ textAlign: "center" }}>
          <Button
            type="submit"
            fullWidth={isMobile}
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={onClickSubmit}
            size="large"
          >
            {confirming ? "Sign up" : "Reset Password"}
          </Button>
          <br />
          <br />
          <MuiPageLink
            to={Pages.auth.login}
            style={{ color: isMobile ? undefined : "#000000" }}
          >
            {confirming ? "Already signed up? " : "Already have a password? "}
            Login now.
          </MuiPageLink>
        </div>
      </form>
    </div>
  );
}

export const ResetPasswordPage = connectView(_ResetPasswordPage, [AuthActions]);
