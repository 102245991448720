import React from "react";
import {
  // Card,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  // Grid,
  List,
  ListItem,
  // ListItemText,
  // ListItemSecondaryAction,
  Typography,
} from "@material-ui/core";
// Local
import { ExpandMoreIcon, LabelIcon } from "../../../components";
import {
  useStyles,
  usePanelStyles,
  usePanelSummaryStyles,
  usePanelDetailStyles,
} from "./PaystubTaxTable.styles";

/**
 * @typedef {object} DetailListItemProps
 * @property {string} text
 * @property {string} amount
 *
 * @param {DetailListItemProps} props
 */
function DetailListItem({ text, amount }) {
  return (
    <ListItem>
      <Typography variant="caption" style={{ width: "100%" }}>
        {text}
        <span
          style={{
            float: "right",
            margin: "auto 0",
            marginRight: 40,
            color: "rgba(0,0,0,0.6)",
          }}
        >
          {amount}
        </span>
      </Typography>
    </ListItem>
  );
}
/**
 * @typedef {object} TaxCategoryItemProps
 * @property {string} iconColor
 * @property {(value:number)=>string} renderAmount
 * @property {string} title
 * @property {PayHistoryDetailsTaxYTD[]} rows
 * @property {boolean} [ytd]
 *
 * @param {TaxCategoryItemProps} props
 */
function TaxCategoryItem({ iconColor, renderAmount, title, rows = [] }) {
  const classesPanel = usePanelStyles();
  const classesSummary = usePanelSummaryStyles();
  const classesDetails = usePanelDetailStyles();

  const total = rows.reduce((total, row) => {
    total += parseFloat(row.current || row.amount) || 0;
    return total;
  }, 0);

  return (
    <ExpansionPanel classes={classesPanel} square>
      <ExpansionPanelSummary
        classes={classesSummary}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="taxes-content"
        id="taxes-header"
      >
        <LabelIcon
          style={{
            color: iconColor,
            float: "left",
            marginLeft: -24,
            marginRight: 16,
          }}
        />
        <Typography>
          <strong>{title}</strong>
        </Typography>
        <span style={{ marginLeft: "auto", float: "right" }}>
          {renderAmount(total)}
        </span>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails classes={classesDetails}>
        <List dense style={{ marginLeft: 26, width: "100%" }}>
          {rows.map((row, i) => (
            <DetailListItem
              key={i}
              text={row.descr}
              amount={renderAmount(row.current || row.amount)}
            />
          ))}
        </List>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

/**
 * @typedef {object} YTDCategoryItemProps
 * @property {PayHistoryDetails} payHistDetails
 * @property {(value:number)=>string} renderAmount
 * @property {string} title
 *
 * @param {YTDCategoryItemProps} props
 */
function YTDCategoryItem({ payHistDetails, renderAmount }) {
  const classesPanel = usePanelStyles();
  const classesSummary = usePanelSummaryStyles();
  const classesDetails = usePanelDetailStyles();

  return (
    <ExpansionPanel classes={classesPanel} square>
      <ExpansionPanelSummary
        classes={classesSummary}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="taxes-content"
        id="taxes-header"
      >
        <Typography style={{ marginLeft: 16 }}>
          <strong>Year to Date</strong>
        </Typography>
        <span
          style={{
            marginLeft: "auto",
            float: "right",
          }}
        >
          {renderAmount(payHistDetails.ytdNet)}
        </span>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails classes={classesDetails}>
        <List dense style={{ marginLeft: 26, width: "100%" }}>
          <DetailListItem
            text="Gross"
            amount={renderAmount(payHistDetails.ytdGross)}
          />
          <DetailListItem
            text="Net"
            amount={renderAmount(payHistDetails.ytdNet)}
          />
        </List>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

/**
 * @typedef {object} PaystubTaxTableProps
 * @property {PayHistoryDetails} payHistDetails
 * @property {(value:number)=>string} renderAmount
 *
 * @param {PaystubTaxTableProps} props
 */
function _PaystubTaxTable({ payHistDetails, renderAmount }) {
  const classes = useStyles();
  const { deductions, taxDetails } = payHistDetails;
  return (
    <div className={classes.root}>
      <TaxCategoryItem
        iconColor="#FFCC2C"
        title="Taxes"
        rows={taxDetails}
        renderAmount={renderAmount}
      />
      <TaxCategoryItem
        iconColor="#FF5C5C"
        title="Deductions"
        rows={deductions}
        renderAmount={renderAmount}
      />
      <YTDCategoryItem
        payHistDetails={payHistDetails}
        renderAmount={renderAmount}
      />
    </div>
  );
}

export const PaystubTaxTable = React.memo(_PaystubTaxTable);

/**
 * @typedef {import("../../state").PayHistoryDetails} PayHistoryDetails
 * @typedef {import("../../state").PayHistoryDetailsTaxYTD} PayHistoryDetailsTaxYTD
 */
