export function dateHired(state) {
  const {
    profile: { bio: { hired } = {} },
  } = state;
  return {
    dateHired: hired,
  };
}

export function emergencyContact(state) {
  const {
    profile: { emergencyContact = {} },
  } = state;
  return {
    emergencyContact,
  };
}
/** @returns {{ emergencyContactRelationshipTypes: string[] }} */
export function emergencyContactRelationshipTypes(state) {
  const {
    profile: { emergencyContactRelationshipTypes = [] },
  } = state;
  return {
    emergencyContactRelationshipTypes,
  };
}

export function emergencyContacts(state) {
  const {
    profile: { emergencyContacts = [] },
  } = state;
  return {
    emergencyContacts,
  };
}

export function personalAddress(state) {
  const {
    profile: { address = {} },
  } = state;
  return {
    personalAddress: address,
  };
}

export function personalBio(state) {
  const {
    profile: { bio = {} },
  } = state;
  return {
    personalBio: bio,
  };
}

export function personalContact(state) {
  const {
    profile: { contact = {} },
  } = state;
  return {
    personalContact: contact,
  };
}
