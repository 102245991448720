import React from "react";
import {
  AppBar,
  Container,
  Dialog,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
// Local
import { CloseIcon } from "../icons";
import { SlideUpTransition } from "../transitions";
import { useStyles } from "./FullScreenDialog.styles";

export const FullScreenDialog = React.memo(
  /**
   * @typedef {object} FullScreenDialogProps
   * @property {React.ReactNode} children
   * @property {() => void} onClose
   * @property {boolean} open True if the dialog should be visible.
   * @property {React.ReactNode} title Title of the app bar.
   *
   * @param {FullScreenDialogProps} props
   */
  function FullScreenDialog(props) {
    const { children, onClose, open, title: titleProp } = props;

    const classes = useStyles();

    const [title, setTitle] = React.useState("");
    const child = React.useMemo(() => {
      // Get the only child after cloning it to add props.
      return React.cloneElement(React.Children.only(children), {
        onChangeTitle: setTitle,
      });
    }, [children, setTitle]);
    const { title: childTitleProp, maxWidth = "sm" } = child.props;

    React.useEffect(() => {
      // Set the default title if dialog opens, closes or if title props change.
      if (!open) {
        setTitle("");
      } else {
        setTitle(childTitleProp || titleProp);
      }
    }, [childTitleProp, titleProp, setTitle, open]);

    const handleClose = React.useCallback(() => {
      onClose();
    }, [onClose]);

    return (
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={SlideUpTransition}
      >
        <Hidden smUp>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {title}
              </Typography>
            </Toolbar>
          </AppBar>
        </Hidden>
        <Container maxWidth={maxWidth}>
          <Hidden xsDown>
            <div className={classes.titlebarLg}>
              <Typography variant="h4">{title}</Typography>
              <IconButton
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                style={{ alignSelf: "flex-end", marginRight: -24 }}
              >
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </div>
          </Hidden>
          <div className={classes.content}>{child}</div>
        </Container>
      </Dialog>
    );
  },
);
