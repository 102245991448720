import React from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
// Local
import { DocumentIcon, DownloadIcon, ExpandMoreIcon } from "../../components";
import { Navigation, PageURL, useOnMount } from "../../lib";
import {
  annualDocuments,
  connectView,
  DocumentActions,
  uiLoading,
} from "../../state";
import { useStyles } from "./AnnualDocumentsPage.styles";
import Pages from "../../pages";

function _AnnualDocumentsPage({
  actions: { downloadDocument, listAnnualDocuments },
  annualDocuments,
  annualYears,
  pageRoute: { params: { year } = {} },
  uiLoading,
}) {
  // If year not chosen yet, choose the first item in the annualYears array.
  // The annualYears array is sorted by most recent year first.
  if (!year) {
    // NOTE: annualYears will be empty on the very first render.
    year = annualYears[0]?.toString();
  }

  const classes = useStyles();
  const [yearsMenuAnchor, setYearsMenuAnchor] = React.useState(null);
  function onClickDownload(id) {
    /** @param {React.SyntheticEvent<HTMLButton>} e */
    function onClick(e) {
      e.preventDefault();
      downloadDocument(id);
    }
    return onClick;
  }
  function onClickYears(e) {
    setYearsMenuAnchor(e.currentTarget);
  }
  function onClickYear(year) {
    return () => {
      setYearsMenuAnchor(null);
      Navigation.redirect(
        PageURL.to(Pages.docs.index, {
          params: { year },
        }),
      );
    };
  }
  function onCloseYears() {
    setYearsMenuAnchor(null);
  }
  useOnMount(() => {
    listAnnualDocuments(year);
  });
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.titlebar}>
            <Typography className={classes.title} variant="h4">
              Annual documents
            </Typography>
            {year && (
              <Button
                aria-controls="years-menu"
                aria-haspopup="true"
                onClick={onClickYears}
                className={classes.menuButton}
              >
                Year {year}
                <ExpandMoreIcon />
              </Button>
            )}
            <Menu
              id="years-menu"
              anchorEl={yearsMenuAnchor}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              getContentAnchorEl={null}
              keepMounted
              open={Boolean(yearsMenuAnchor)}
              onClose={onCloseYears}
              MenuListProps={{
                variant: "selectedMenu",
              }}
            >
              {annualYears.map(year => (
                <MenuItem key={year} onClick={onClickYear(year)}>
                  {year}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.divider}>
            <Divider />
          </Box>
        </Grid>
      </Grid>
      {!uiLoading && annualDocuments.length < 1 && (
        <Grid item xs={12} className={classes.noResultsRow}>
          <Typography variant="subtitle1">
            There are no documents to display at this time.
          </Typography>
        </Grid>
      )}
      <Table>
        <TableBody>
          {annualDocuments.map(row => (
            <TableRow key={row.id} className={classes.tableRow}>
              <TableCell className={classes.iconCell}>
                <DocumentIcon fontSize="inherit" />
              </TableCell>
              <TableCell className={classes.nameCell}>
                {row.reportName}
              </TableCell>
              <TableCell className={classes.toolCell}>
                <IconButton
                  color="primary"
                  aria-label="View"
                  title="View"
                  onClick={onClickDownload(row.id)}
                >
                  <DownloadIcon fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}

export const AnnualDocumentsPage = connectView(
  _AnnualDocumentsPage,
  state => ({
    ...annualDocuments(state),
    ...uiLoading(state),
  }),
  [DocumentActions],
);
