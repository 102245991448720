import { createMuiTheme, useMediaQuery } from "@material-ui/core";

/** Material-ui default theme, to copy from where necessary. */
const defaults = createMuiTheme();

/**
 * Override box shadows. Must provide all 25 array elements...
 * - https://github.com/mui-org/material-ui/issues/8780
 * - https://gist.github.com/phacks/6c3c3a5f395f6e9660ae132c237250a3
 */
const boxShadow = "0 0 4px -4px rgba(0,0,0,0.12), 0 0 8px 0 rgba(0,0,0,0.16)";
const shadows = [...defaults.shadows];
// for (let i = 0; i < 25; i++) {
//   shadows[i] = "none";
// }
shadows[3] = boxShadow;

/**
 * Default theme for the app based on the
 * [Materiaul UI default theme](https://material-ui.com/customization/default-theme/)
 * with overrides to match design **exactly**.
 */
export const defaultTheme = createMuiTheme({
  typography: {
    fontFamily: `"TT Norms Pro", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500
  },
  palette: {
    type: "light",
    primary: { main: "#2D12A3" },
    secondary: { main: "#2D12A3" },
  },
  overrides: {
    MuiButton: {
      contained: {
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
      },
    },
    MuiButtonGroup: {
      contained: {
        boxShadow: "none",
        border: "1px solid rgba(0,0,0,0.16)",
      },
    },
    MuiMenu: {
      paper: {
        boxShadow,
      },
    },
  },
  props: {
    MuiCard: {
      variant: "outlined",
    },
  },
  shadows,
});
/** Returns `true` if the screen is sized for mobile. */
export function useMobile(size = "xs") {
  return useMediaQuery(defaultTheme.breakpoints.down(size));
}
/** Returns `true` if the PWA is installed and running in standalone mode. */
export function useInstalledPWA() {
  return useMediaQuery("(display-mode: standalone)");
}
