import { __PROD__, REACT_APP_VERSION } from "../config";

if (__PROD__) {
  require("./inspectlet");
}

export function setTelemetryUser({ username, conum, empnum }) {
  if (!__PROD__) {
    return;
  }
  const { __insp: insp } = window;
  if (!insp) {
    return;
  }
  insp.push(["identify", { username }]);
  insp.push(["tagSession", { conum, empnum }]);
  insp.push(["tagSession", { ClientApp_Version: REACT_APP_VERSION }]);
  // TODO: Get version of API and put it here.
  // insp.push(["tagSession", { API_Version: TODO_getVersionOfAPI() }]);
}
