import React from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
// Local
import { FilterListIcon, HidePaySwitch } from "../../components";
import { isDateValid } from "../../lib";
import {
  connectView,
  payHistory,
  PayHistoryActions,
  payTypeBool,
  renderAmount,
  uiLoading,
} from "../../state";
import { useStyles } from "./PayHistoryPage.styles";
import { useMobile } from "../../themes";
import { PayHistoryPageTable, PayHistoryPageTableSmall } from "./components";

function shouldSearchDate(date) {
  return date === null || isDateValid(date);
}

function _PayTypeFilterDesktop({ className, pay, other, onPayTypeChanged }) {
  return (
    <div className={className}>
      <FormControlLabel
        control={
          <Checkbox
            checked={pay}
            onChange={onPayTypeChanged("pay")}
            inputProps={{
              "aria-label": "Filter pay",
            }}
          />
        }
        label="Pay"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={other}
            onChange={onPayTypeChanged("other")}
            inputProps={{
              "aria-label": "Filter other",
            }}
          />
        }
        label="Other"
      />
    </div>
  );
}
const PayTypeFilterDesktop = React.memo(_PayTypeFilterDesktop);

function _PayTypeFilterMobile({ pay, other, onPayTypeChanged }) {
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  function onClickButton(e) {
    setMenuAnchor(e.currentTarget);
  }
  function onClickMenuItem(payTypeName) {
    const changePayType = onPayTypeChanged(payTypeName);
    const checked = { pay, other }[payTypeName];
    return () => {
      // Send mock checkbox target with toggled `checked` value.
      changePayType({ target: { checked: !checked } });
      setMenuAnchor(null);
    };
  }
  function onCloseMenu() {
    setMenuAnchor(null);
  }
  return (
    <>
      <IconButton style={{ color: "black" }} onClick={onClickButton}>
        <FilterListIcon />
      </IconButton>
      <Menu
        id="pay-type-menu"
        anchorEl={menuAnchor}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={onCloseMenu}
        MenuListProps={{
          variant: "selectedMenu",
        }}
      >
        <MenuItem onClick={onClickMenuItem("pay")}>
          <Checkbox
            checked={pay}
            inputProps={{
              "aria-label": "Filter pay",
            }}
          />
          Pay
        </MenuItem>
        <MenuItem onClick={onClickMenuItem("other")}>
          <Checkbox
            checked={other}
            inputProps={{
              "aria-label": "Filter other",
            }}
          />
          Other
        </MenuItem>
      </Menu>
    </>
  );
}
const PayTypeFilterMobile = React.memo(_PayTypeFilterMobile);

function _PayHistoryPage({
  actions: { downloadPayHistoryDocument, listPayHistory },
  pageRoute: { query },
  payHistory,
  payHistoryMaybeHasMore,
  renderAmount,
  uiLoading,
}) {
  const isMobile = useMobile();
  const classes = useStyles();
  // #region State
  const [fromDate, setFromDate] = React.useState(
    query.fromDate ? new Date(query.fromDate) : null,
  );

  const [toDate, setToDate] = React.useState(
    query.toDate ? new Date(query.toDate) : null,
  );

  const [searchState, setSearchState] = React.useState({
    fromDate,
    toDate,
    pay: payTypeBool(query.payType, "pay"),
    other: payTypeBool(query.payType, "other"),
  });
  // #endregion
  // #region Input Handlers

  const onChangeFromDate = React.useCallback(
    date => {
      setFromDate(date);
      if (shouldSearchDate(date)) {
        setSearchState(state => ({ ...state, fromDate: date }));
      }
    },
    [setFromDate, setSearchState],
  );
  const onChangeToDate = React.useCallback(
    date => {
      setToDate(date);
      if (shouldSearchDate(date)) {
        setSearchState(state => ({ ...state, toDate: date }));
      }
    },
    [setSearchState],
  );
  const onPayTypeChanged = React.useCallback(
    name => {
      return e => {
        const { checked } = e.target;
        setSearchState(state => {
          const newState = { ...state, [name]: checked };
          if (!newState.pay && !newState.other) {
            return state; // Disallow unchecking both.
          }
          return newState;
        });
      };
    },
    [setSearchState],
  );

  const onClickReset = React.useCallback(() => {
    setFromDate(null);
    setToDate(null);
    setSearchState({
      fromDate: null,
      toDate: null,
      pay: true,
      other: true,
    });
  }, [setSearchState, setFromDate, setToDate]);

  const onClickLoadMore = React.useCallback(
    /** @param {React.SyntheticEvent<HTMLButtonElement>} e */
    e => {
      e.preventDefault();
      listPayHistory(false, searchState);
    },
    [listPayHistory, searchState],
  );
  // #endregion
  // #region Effects
  React.useEffect(() => {
    listPayHistory(true, searchState);
  }, [listPayHistory, searchState]);
  // #endregion
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.titlebar}>
            <Typography className={classes.title} variant="h4">
              Pay history
            </Typography>
            <div className={classes.titleCtrl}>
              <HidePaySwitch />
              {isMobile && (
                <PayTypeFilterMobile
                  other={searchState.other}
                  pay={searchState.pay}
                  onPayTypeChanged={onPayTypeChanged}
                />
              )}
            </div>
          </div>
        </Grid>
        {isMobile ? (
          <>
            <Grid item xs={6} style={{ paddingRight: 8 }}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog1"
                label="From"
                format="MM/dd/yyyy"
                invalidDateMessage=""
                value={fromDate}
                onChange={onChangeFromDate}
                KeyboardButtonProps={{
                  "aria-label": "From date",
                }}
              />
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: 8 }}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog2"
                label="To"
                format="MM/dd/yyyy"
                invalidDateMessage=""
                value={toDate}
                onChange={onChangeToDate}
                KeyboardButtonProps={{
                  "aria-label": "To date",
                }}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12} className={classes.filtersDesktop}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog1"
              label="From"
              format="MM/dd/yyyy"
              invalidDateMessage=""
              value={fromDate}
              onChange={onChangeFromDate}
              KeyboardButtonProps={{
                "aria-label": "From date",
              }}
              style={{ marginRight: 16 }}
            />
            <KeyboardDatePicker
              style={{ marginRight: 16 }}
              margin="normal"
              id="date-picker-dialog2"
              label="To"
              format="MM/dd/yyyy"
              invalidDateMessage=""
              value={toDate}
              onChange={onChangeToDate}
              KeyboardButtonProps={{
                "aria-label": "To date",
              }}
            />
            <Button className={classes.clearButton} onClick={onClickReset}>
              Reset
            </Button>
            <PayTypeFilterDesktop
              className={classes.filtersBox}
              pay={searchState.pay}
              other={searchState.other}
              onPayTypeChanged={onPayTypeChanged}
            />
          </Grid>
        )}
      </Grid>
      <Grid container>
        {!uiLoading && payHistory.length < 1 && (
          <Grid item xs={12} className={classes.noResultsRow}>
            <Typography variant="subtitle1">
              There is no pay history to display for this period of time.
            </Typography>
          </Grid>
        )}
      </Grid>
      {isMobile ? (
        <PayHistoryPageTableSmall
          onDownload={downloadPayHistoryDocument}
          renderAmount={renderAmount}
          rows={payHistory}
        />
      ) : (
        <PayHistoryPageTable
          onDownload={downloadPayHistoryDocument}
          renderAmount={renderAmount}
          rows={payHistory}
        />
      )}
      {payHistoryMaybeHasMore && (
        <Button
          onClick={onClickLoadMore}
          variant="contained"
          color="primary"
          size="large"
          style={{
            margin: "auto 0",
            marginTop: 50,
            paddingLeft: 30,
            paddingRight: 30,
          }}
        >
          LOAD MORE
        </Button>
      )}
    </>
  );
}

export const PayHistoryPage = connectView(
  _PayHistoryPage,
  state => ({
    ...payHistory(state),
    ...renderAmount(state),
    ...uiLoading(state),
  }),
  [PayHistoryActions],
);
