import { AuthState } from "./auth/state";
import { DashboardState } from "./dashboard/state";
import { DDState } from "./dd/state";
import { DocumentState } from "./documents/state";
import { PayHistoryState } from "./payHist/state";
import { PrefState } from "./prefs/state";
import { ProfileState } from "./profile/state";
import { UIState } from "./ui/state";

import { TaxState } from "./taxes/state";

export * from "./auth/actions";
export * from "./auth/selectors";

export * from "./dashboard/actions";
export * from "./dashboard/selectors";

export * from "./dd/actions";
export * from "./dd/selectors";

export * from "./documents/actions";
export * from "./documents/selectors";

export * from "./payHist/actions";
export * from "./payHist/selectors";

export * from "./prefs/actions";
export * from "./prefs/selectors";

export * from "./profile/actions";
export * from "./profile/selectors";

export * from "./taxes/actions";
export * from "./taxes/selectors";

export * from "./ui/actions";
export * from "./ui/selectors";

export default [
  AuthState,
  DashboardState,
  DDState,
  DocumentState,
  PayHistoryState,
  PrefState,
  ProfileState,
  TaxState,
  UIState,
];
