import React from "react";
import { Grid } from "@material-ui/core";
// Local
import { useOnMount } from "../../../lib";
import { connectView, ProfileActions } from "../../../state";
import {
  PersonalAddressCard,
  PersonalBioCard,
  PersonalContactCard,
  TabInfo,
  TabPanel,
} from "./";

function _PersonalTab({ actions: { getProfile }, showing }) {
  useOnMount(() => {
    if (!showing) {
      return;
    }
    getProfile();
  });
  return (
    <TabPanel index={TabInfo.personal.idx} showing={showing}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <PersonalAddressCard />
        </Grid>
        <Grid item xs={12} md={6}>
          <PersonalContactCard />
        </Grid>
        <Grid item xs={12} md={6}>
          <PersonalBioCard />
        </Grid>
      </Grid>
    </TabPanel>
  );
}

export const PersonalTab = connectView(_PersonalTab, [ProfileActions]);
