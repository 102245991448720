import { AuthActions } from "../auth/actions";
import { TaxActions } from "./actions";
/**
 * Tax state.
 * @example
 * {
 *  federal: {
 *    filingStatus: "Single",
 *    dependents: 2,
 *    amountExtra: 0,
 *    isExempt: false,
 *    ...
 *   },
 *   workState: {
 *     stateAbbr: "NY",
 *     isExempt: false,
 *     filingStatus: "Married",
 *     filingStatuses: ["Married", "Single"],
 *     dependents: 0,
 *     amountExtra: 99.99,
 *     locals: [{
 *       localId: 1200,
 *       dependents: 0,
 *       amountExtra: 99.99
 *     }, ...]
 *   },
 * }
 */
export const TaxState = {
  name: "taxes",
  persist: true,
  defaults: {},
  handlers: {
    [AuthActions.type.LOGOUT_SUCCESS](state, action) {
      return TaxState.defaults;
    },
    [TaxActions.type.TAXES_W4_SUCCESS](state, { data, error }) {
      if (error) {
        return state;
      }
      return {
        ...state,
        ...data,
      };
    },
    [TaxActions.type.TAXES_STATE_UPDATE_SUCCESS](state, { data }) {
      return {
        ...state,
        workState: {
          ...state.workState,
          ...data,
        },
      };
    },
    [TaxActions.type.TAXES_W4_UPDATE_SUCCESS](state, { data }) {
      return {
        ...state,
        federal: {
          ...state.federal,
          ...data,
        },
      };
    },
  },
};
