import React from "react";
import { Grid } from "@material-ui/core";
// Local
import { WizardDialog } from "../../../components";
import { boolYesNo, isYes, useObjectState, useOnMount } from "../../../lib";
import {
  connectView,
  getStateTaxLocals,
  TaxActions,
  taxesWorkState,
} from "../../../state";
import { EditStateW4Form } from "./EditStateW4Form";
import { ReviewStateW4 } from "./ReviewStateW4";
import { SignStateW4 } from "./SignStateW4";
import { CompletedStateW4 } from "./CompletedStateW4";

const defaultDialogTitle = "State tax withholdings W4";

const localFormDefaultsNY = {
  resideNYC: "no",
  resideYonkers: "no",
  dependentsNYC: 0,
  amountExtraNYC: 0,
  amountExtraYonkers: 0,
};

function localsNY(isNY, taxesWorkState) {
  const formProps = {};
  if (!isNY) {
    return formProps;
  }
  let locals = taxesWorkState?.locals?.find(item => item.localId === 1200);
  if (locals /* && locals.isActive */) {
    formProps.resideNYC = "yes";
    formProps.dependentsNYC = locals.dependents || 0;
    formProps.amountExtraNYC = locals.amountExtra || 0;
  }
  locals = taxesWorkState?.locals?.find(item => item.localId === 1201);
  if (locals /* && locals.isActive */) {
    formProps.resideYonkers = "yes";
    formProps.amountExtraYonkers = locals.amountExtra || 0;
  }
  return formProps;
}

function _EditStateW4Controller({
  actions,
  events,
  onCancel,
  onComplete,
  step,
  setStep,
  setDialogTitle,
  taxesWorkState,
  taxesWorkStateAbbr: stateAbbr,
  taxesWorkStateIsNY: isNY,
}) {
  // #region State
  const [errFields, setErrFields] = useObjectState();

  const [formData, setFormProps] = useObjectState({
    filingStatus: "",
    isExempt: "no",
    dependents: 0,
    amountExtra: 0,
    agreed: false,
    signature: "",
    ...(isNY ? localFormDefaultsNY : {}),
  });
  // #endregion
  // #region Effects
  React.useEffect(() => {
    setDialogTitle(stateAbbr + " " + defaultDialogTitle);
  }, [stateAbbr, setDialogTitle]);
  React.useEffect(() => {
    setFormProps({
      filingStatus:
        taxesWorkState.filingStatus || taxesWorkState.filingStatuses[0] || "",
      isExempt: boolYesNo(taxesWorkState.isExempt),
      dependents: taxesWorkState.dependents || 0,
      amountExtra: taxesWorkState.amountExtra || 0,
      ...localsNY(isNY, taxesWorkState),
    });
  }, [taxesWorkState, isNY, setFormProps]);
  // #endregion
  // #region Events
  events.current = {
    prevText: React.useCallback(step => {
      if (step === 3) return undefined;
      return step > 0 ? "Back" : "Cancel";
    }, []),
    nextText: React.useCallback(step => {
      return step === 2 ? "Submit" : step === 3 ? "OK" : "Next";
    }, []),
    onPrev: React.useCallback(() => {
      setStep(step => {
        const prevStep = step - 1;
        if (prevStep < 0) {
          onCancel();
        }
        return prevStep;
      });
    }, [onCancel, setStep]),

    onNext: React.useCallback(
      e => {
        if (e && e.preventDefault) {
          e.preventDefault();
        }
        setStep(step => {
          // Validate
          switch (step) {
            case 0: // EditStateW4Form
              if (!validateForm(formData, setErrFields)) {
                return step;
              }
              break;
            case 2: // SignStateW4
              if (!validateSignatureForm(formData, setErrFields)) {
                return step;
              }
              actions
                .updateStateTaxes({
                  stateAbbr: taxesWorkState.stateAbbr,
                  filingStatus: formData.filingStatus,
                  isExempt: isYes(formData.isExempt),
                  dependents: formData.dependents,
                  amountExtra: formData.amountExtra,
                  signature: formData.signature,
                  locals: getStateTaxLocals(taxesWorkState, formData),
                })
                .then(({ error, downloadURL }) => {
                  if (!error) {
                    setFormProps({
                      signedW4DownloadURL: downloadURL,
                    });
                    setStep(3);
                  }
                });
              return step;
          }
          // Step
          const nextStep = step + 1;
          if (nextStep > 3 /* CompletedStateW4 */) {
            onComplete();
          }
          return nextStep;
        });
      },
      [
        formData,
        actions,
        onComplete,
        setStep,
        setErrFields,
        setFormProps,
        taxesWorkState,
      ],
    ),
  };

  useOnMount(() => {
    actions.getTaxesW4();
  });
  // #endregion

  return (
    <Grid container style={{ height: "100%" }}>
      {step === 0 && (
        <EditStateW4Form
          errFields={errFields}
          formData={formData}
          setFormProps={setFormProps}
          taxesWorkState={taxesWorkState}
          taxesWorkStateAbbr={stateAbbr}
          taxesWorkStateIsNY={isNY}
        />
      )}
      {step === 1 && (
        <ReviewStateW4
          actions={actions}
          formData={formData}
          taxesWorkState={taxesWorkState}
          stateAbbr={stateAbbr}
          isNY={isNY}
        />
      )}
      {step === 2 && (
        <SignStateW4
          errFields={errFields}
          formData={formData}
          setFormProps={setFormProps}
          taxesWorkState={taxesWorkState}
          stateAbbr={stateAbbr}
          isNY={isNY}
        />
      )}
      {step === 3 && <CompletedStateW4 formData={formData} />}
    </Grid>
  );
}

const EditStateW4Controller = connectView(
  _EditStateW4Controller,
  state => ({
    ...taxesWorkState(state),
  }),
  [TaxActions],
);

export const EditStateW4Wizard = React.memo(function _EditStateW4Wizard({
  open,
  onCancel,
  onComplete,
}) {
  const [title, setTitle] = React.useState(defaultDialogTitle);
  return (
    <WizardDialog
      open={open}
      onCancel={onCancel}
      onComplete={onComplete}
      steps={4}
      title={title}
    >
      <EditStateW4Controller setDialogTitle={setTitle} />
    </WizardDialog>
  );
});

function validateForm(formData, setErrFields) {
  const { dependents, amountExtra } = formData;
  if (
    !validatePositiveAmounts(
      {
        dependents,
        amountExtra,
      },
      setErrFields,
    )
  ) {
    return false;
  }
  return true;
}

function validatePositiveAmounts(amounts, setErrFields) {
  let valid = true;
  for (let key in amounts) {
    if (!validatePositiveAmount(amounts[key], key, setErrFields)) {
      valid = false;
    }
  }
  return valid;
}

function validatePositiveAmount(amount, name, setErrFields) {
  const value = parseFloat(amount || "0");
  if (value < 0) {
    setErrFields({
      [name]: "Must be zero or higher.",
    });
    return false;
  }
  return true;
}

function validateSignatureForm(formData, setErrFields) {
  let valid = true;
  if (!formData.agreed) {
    setErrFields({
      agreed: "Must agree to continue.",
    });
    valid = false;
  }
  if (!(formData.signature || "").trim()) {
    setErrFields({
      signature: "Signature must not be blank.",
    });
    valid = false;
  }
  return valid;
}
