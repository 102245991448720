import React from "react";
import { Grid } from "@material-ui/core";
// Local
import {
  WizardDialog /*, DialogSaveCancelButtons */,
} from "../../../components";
import { boolYesNo, isYes, useObjectState, useOnMount } from "../../../lib";
import { connectView, TaxActions, taxesW4 } from "../../../state";
import { EditW4Form } from "./EditW4Form";
import { ReviewW4 } from "./ReviewW4";
import { SignW4 } from "./SignW4";
import { DownloadW4 } from "./DownloadW4";
// import { useStyles } from "./EditW4Wizard.styles";

function _EditW4Controller({
  actions,
  actions: { getTaxesW4 },
  events,
  onCancel,
  onComplete,
  step,
  setStep,
  taxesW4,
}) {
  // const classes = useStyles();
  // #region State
  const [errFields, setErrFields] = useObjectState();
  const [formData, setFormProps] = useObjectState({
    filingStatus: "Single",
    isExempt: "no",
    multiJobOption2c: "no",
    optionalAdj: false,
    childAmount: "",
    dependentsAmount: "",
    otherIncome: "",
    deductions: "",
    amountExtra: "",
    otherDepAmount: "",
    agreed: false,
    signature: "",
  });
  // #endregion
  // #region Effects
  React.useEffect(() => {
    setFormProps({
      filingStatus: taxesW4.filingStatus || "Single",
      isExempt: boolYesNo(taxesW4.isExempt),
      multiJobOption2c: boolYesNo(taxesW4.multiJobOption2c),
      dependentsAmount: taxesW4.dependentsAmount || "0",
      otherIncome: taxesW4.otherIncome || "0",
      deductions: taxesW4.deductions || "0",
      amountExtra: taxesW4.amountExtra || "0",
      optionalAdj: !!(
        taxesW4.otherIncome ||
        taxesW4.deductions ||
        taxesW4.amountExtra
      ),
    });
  }, [taxesW4, setFormProps]);
  // #endregion
  // #region Events
  events.current = {
    prevText: React.useCallback(step => {
      if (step === 3) return undefined;
      return step > 0 ? "Back" : "Cancel";
    }, []),
    nextText: React.useCallback(step => {
      return step === 2 ? "Submit" : step === 3 ? "OK" : "Next";
    }, []),
    onPrev: React.useCallback(() => {
      setStep(step => {
        const prevStep = step - 1;
        if (prevStep < 0) {
          onCancel();
        }
        return prevStep;
      });
    }, [onCancel, setStep]),

    onNext: React.useCallback(
      e => {
        if (e && e.preventDefault) {
          e.preventDefault();
        }
        setStep(step => {
          // Validate
          switch (step) {
            case 0: // EditW4Form
              if (!validateForm(formData, setErrFields)) {
                return step;
              }
              break;
            case 2: // SignW4
              if (!validateSignatureForm(formData, setErrFields)) {
                return step;
              }
              actions
                .updateTaxesW4({
                  filingStatus: formData.filingStatus,
                  isExempt: isYes(formData.isExempt),
                  multiJobOption2c: isYes(formData.multiJobOption2c),
                  dependentsAmount: formData.dependentsAmount,
                  otherIncome: formData.otherIncome,
                  deductions: formData.deductions,
                  amountExtra: formData.amountExtra,
                  signature: formData.signature,
                })
                .then(({ error, downloadURL }) => {
                  if (!error) {
                    setFormProps({
                      signedW4DownloadURL: downloadURL,
                    });
                    setStep(3);
                  }
                });
              return step;
          }
          // Step
          const nextStep = step + 1;
          if (nextStep > 3 /* DownloadW4 */) {
            onComplete();
            // actions.showUpdated(); // From UIActions
          }
          return nextStep;
        });
      },
      [formData, actions, onComplete, setStep, setErrFields, setFormProps],
    ),
  };

  useOnMount(() => {
    getTaxesW4();
  });
  // #endregion

  return (
    <Grid container style={{ height: "100%" }}>
      {step === 0 && (
        <EditW4Form
          errFields={errFields}
          formData={formData}
          setFormProps={setFormProps}
        />
      )}
      {step === 1 && <ReviewW4 actions={actions} formData={formData} />}
      {step === 2 && (
        <SignW4
          errFields={errFields}
          formData={formData}
          setFormProps={setFormProps}
        />
      )}
      {step === 3 && <DownloadW4 formData={formData} />}
    </Grid>
  );
}

const EditW4Controller = connectView(
  _EditW4Controller,
  state => ({
    ...taxesW4(state),
  }),
  [TaxActions],
);

export const EditW4Wizard = React.memo(function _EditW4Wizard({
  open,
  onCancel,
  onComplete,
}) {
  return (
    <WizardDialog
      open={open}
      onCancel={onCancel}
      onComplete={onComplete}
      steps={4}
      title="Federal tax withholdings W4"
    >
      <EditW4Controller />
    </WizardDialog>
  );
});

function validateForm(formData, setErrFields) {
  const { dependentsAmount, otherIncome, deductions, amountExtra } = formData;
  if (
    !validatePositiveAmounts(
      {
        dependentsAmount,
        otherIncome,
        deductions,
        amountExtra,
      },
      setErrFields,
    )
  ) {
    return false;
  }
  return true;
}

function validatePositiveAmounts(amounts, setErrFields) {
  let valid = true;
  for (let key in amounts) {
    if (!validatePositiveAmount(amounts[key], key, setErrFields)) {
      valid = false;
    }
  }
  return valid;
}

function validatePositiveAmount(amount, name, setErrFields) {
  const value = parseFloat(amount || "0");
  if (value < 0) {
    setErrFields({
      [name]: "Must be zero or higher.",
    });
    return false;
  }
  return true;
}

function validateSignatureForm(formData, setErrFields) {
  let valid = true;
  if (!formData.agreed) {
    setErrFields({
      agreed: "Must agree to continue.",
    });
    valid = false;
  }
  if (!(formData.signature || "").trim()) {
    setErrFields({
      signature: "Signature must not be blank.",
    });
    valid = false;
  }
  return valid;
}
