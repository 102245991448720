import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  theme => ({
    card: {
      marginTop: 16,
      padding: 10,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      "& label": {
        marginBottom: 10,
      },
    },
    formControlRight: {
      marginTop: 0,
      maxWidth: 200,
    },
    formControl: {
      // marginTop: 35,
      minWidth: 120,
      width: "100%",
    },
    exemptHelp: {
      padding: 10,
      backgroundColor: "grey",
      color: theme.palette.common.white,
      maxWidth: 340,
    },
    optAdjustPanel: {
      // marginTop: 35,
    },
    optAdjustPanelDets: {
      display: "block",
    },
  }),
  {
    classNamePrefix: "EditStateW4Form",
  },
);
