import React from "react";
import { Box, Container, CssBaseline, ThemeProvider } from "@material-ui/core";
// Local
import { CopyrightText, VersionText } from "../components";
import { authMobileTheme, useMobile } from "../themes";
import coLogoTitleSubLight from "../assets/img/logo-transparent.png";
import coLogoTitleSub from "../assets/img/logo-title-sub.png";
import { useStyles } from "./AuthAreaLayout.styles";

function _AuthAreaLayout(props) {
  const isMobile = useMobile();
  const classes = useStyles();
  const { children } = props;
  let RootComponent = React.Fragment;
  let rootProps;
  let containerMaxWidth = "sm";
  if (isMobile) {
    RootComponent = ThemeProvider;
    rootProps = { theme: authMobileTheme };
    // No max width limit for mobile, render default 100% width.
    containerMaxWidth = false;
  }
  return (
    <RootComponent {...rootProps}>
      <CssBaseline />
      <div className={classes.desktopContainer}>
        <Box className={classes.desktopSidebar}>
          <img
            src={coLogoTitleSubLight}
            alt="Company logo, title"
            style={{display: "flex", margin: "100px auto", width: "15vw"}}
          />
        </Box>
        <Container
          maxWidth={containerMaxWidth}
          className={classes.baseContainer}
        >
          <Box className={classes.logoBox}>
            <img src={coLogoTitleSub} alt="Company logo, title" width="250" />
          </Box>
          <div className={classes.content}>{children}</div>
          <Box className={classes.footerBox}>
            <VersionText /> <CopyrightText />
          </Box>
        </Container>
      </div>
    </RootComponent>
  );
}

export const AuthAreaLayout = React.memo(_AuthAreaLayout);
