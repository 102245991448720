import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  theme => ({
    titlebar: {
      "& h4": {
        display: "inline-block",
      },
      "& label": {
        float: "right",
        [theme.breakpoints.up("sm")]: {
          marginTop: 10,
        },
      },
    },
    title: {
      fontSize: 28,
      fontWeight: 400,
      color: '#2d11a2',
      margin: 0,
      [theme.breakpoints.up("sm")]: {
        marginTop: 12,
      },
    },
    netPayCard: {
      display: "flex",
      flexDirection: "column",
      margin: 10,
      minHeight: 400,
      paddingTop: 30,
      [theme.breakpoints.down("sm")]: {
        paddingTop: 15,
      },
    },
    zayZoonCard: {
      display: "flex",
      flexDirection: "column",
      margin: 10,
      marginTop: 40,
      [theme.breakpoints.down("sm")]: {
        marginTop: 10,
      },
    },
    zayZoonContent: {
      cursor: 'pointer',
      width: '100%',
      height: '100%',
      background: 'linear-gradient(90deg,#6dc55c,#21b7de)',
      color: 'white',
      padding: '18px 18px 8px',
      "& h3": {
        margin: 0,
      }
    },
    netPayChart: {
      flex: 2,
      display: "flex",
      justifyContent: "center",
      margin: 10,
      marginBottom: 0,
    },
    netPayTools: {
      display: "flex",
      justifyContent: "space-between",
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20,
    },
    payHistoryCard: {
      margin: 10,
      minHeight: 400,
      padding: 10,
    },
    payHistoryContent: {
      display: "flex",
      justifyContent: "space-between",
      "& h3": {
        display: "inline-block",
        marginLeft: 15,
      },
    },
    payHistoryViewAll: {
      marginTop: 10,
      paddingRight: 20,
    },
    perksCard: {
      display: "flex",
      flexDirection: "column",
      margin: 10,
      marginTop: 40,

      [theme.breakpoints.down("sm")]: {
        marginTop: 10,
      },
    },
    centered: {
      textAlign: "center",
    },
  }),
  {
    classNamePrefix: "DashboardPage",
  },
);
