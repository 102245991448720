import React from "react";
import { Grid, TextField } from "@material-ui/core";
// Local
import { DialogSaveCancelButtons } from "../../../../components";
import { useInputValue } from "../../../../lib";
import { connectView, ProfileActions, UIActions } from "../../../../state";
import { useMobile } from "../../../../themes";
import { useStyles } from "./ChangePasswordForm.styles";

/**
 * @typedef {object} ChangePasswordFormProps
 * @property {typeof import("../../../../state").ProfileActions} actions
 * @property {function} onCancel
 * @property {function} onComplete
 *
 * @param {ChangePasswordFormProps} props
 */
function _ChangePasswordForm({
  actions: { changeAccountPassword, showNotification },
  onCancel,
  onComplete,
}) {
  const classes = useStyles();

  const [currentPassword, onChangeCurrentPassword] = useInputValue();
  const [newPassword, onChangeNewPassword] = useInputValue();
  const [confirmPassword, onChangeConfirmPassword] = useInputValue();

  const isMobile = useMobile();

  async function onSave(e) {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      showNotification("Passwords must match.");
      return;
    }
    await changeAccountPassword({
      currentPassword,
      newPassword,
      confirmPassword,
    });
    onComplete();
  }

  return (
    <form onSubmit={onSave}>
      <Grid container>
        <Grid item xs={12}>
          <span>Enter your contact info.</span>
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoFocus={!isMobile}
            className={classes.formControl100}
            label="Current password"
            margin="normal"
            onChange={onChangeCurrentPassword}
            type="password"
            value={currentPassword}
            inputProps={{
              autoComplete: "off",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl100}
            label="New Password"
            margin="normal"
            onChange={onChangeNewPassword}
            type="password"
            value={newPassword}
            inputProps={{
              autoComplete: "new-password",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl100}
            label="Confirm new password"
            margin="normal"
            onChange={onChangeConfirmPassword}
            type="password"
            value={confirmPassword}
            inputProps={{
              autoComplete: "new-password",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <p>&nbsp;</p>
        </Grid>
        <DialogSaveCancelButtons onCancel={onCancel} />
      </Grid>
    </form>
  );
}

export const ChangePasswordForm = connectView(_ChangePasswordForm, [
  ProfileActions,
  UIActions,
]);

ChangePasswordForm.defaultProps = {
  title: "Change my password",
};
