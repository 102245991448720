import React from "react";
import { Button, TextField, Typography } from "@material-ui/core";
// Local
import { useStyles } from "./ChangePasswordPage.styles";

export function ChangePasswordPage() {
  const classes = useStyles();
  return (
    <div className="paper">
      <Typography variant="h5">Change My Password</Typography>
      <br />
      <form className="form" noValidate>
        <Typography>Old Password</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="currentPassword"
          label="Enter your old password"
          type="password"
          id="currentPassword"
          autoComplete="password"
        />
        <p>&nbsp;</p>
        <Typography>New Password</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password1"
          label="Enter New Password"
          type="password"
          id="password1"
          autoComplete="new-password"
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password2"
          label="Reenter New Password"
          type="password"
          id="password2"
          autoComplete="reenter-new-password"
        />
        <div className={classes.toolbar}>
          <Button
            className={classes.cancel}
            variant="contained"
            color="default"
            size="large"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            className={classes.submit}
            size="large"
          >
            Reset Password
          </Button>
        </div>
      </form>
    </div>
  );
}
