import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  theme => ({
    titlebar: {
      display: "flex",
      justifyContent: "space-between",
      "& h4": {
        display: "inline-block",
        padding: 0,
      },
    },
    titleCtrl: {
      display: "inline-block",
      marginTop: 10,
      "& button": {
        marginLeft: 10,
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: -5,
      },
    },
    title: {
      fontSize: 28,
      margin: 0,
      [theme.breakpoints.up("sm")]: {
        marginTop: 12,
      },
    },
    filtersDesktop: {
      display: "flex",
      justifyContent: "flex-start",
      // alignContent: "center",
      alignItems: "baseline",
      paddingBottom: 38,
    },
    money: {
      color: theme.palette.secondary.light,
    },
    label: {
      color: theme.palette.grey[700],
    },
    noResultsRow: {
      textAlign: "center",
      paddingTop: 40,
    },
    button: {
      color: theme.palette.primary.light,
    },
    clearButton: {
      marginRight: 16,
    },
    filtersBox: {
      display: "inline-block",
      marginLeft: "auto",
      minWidth: 165,
      [theme.breakpoints.up("md")]: {
        marginTop: 28,
        marginBottom: 8,
      },
    },
  }),
  {
    classNamePrefix: "PayHistoryPage",
  },
);
