import React from "react";
import { Button, Grid, FormLabel, Typography } from "@material-ui/core";
// Local

// import { useStyles } from "./DownloadW4.styles";

function _DownloadW4({ formData }) {
  // const classes = useStyles();
  const { signedW4DownloadURL } = formData;
  return (
    <Grid item xs={12}>
      <div style={{ textAlign: "center", minHeight: 500 }}>
        <br />
        <Typography variant="h2">All done!</Typography>
        <br />
        <FormLabel>
          Your Federal W-4 has been submitted to your employer
        </FormLabel>
        <br />
        <Button
          href={signedW4DownloadURL}
          target="BrandsW4Signed"
          variant="contained"
          style={{ marginTop: 20 }}
        >
          Download your signed Federal W-4
        </Button>
      </div>
    </Grid>
  );
}

export const DownloadW4 = React.memo(_DownloadW4);
