import { stringToHslPastel } from "../../lib";

export function accountEmail(state) {
  const {
    auth: { email },
  } = state;
  return {
    accountEmail: email,
  };
}

export function allowDD(state) {
  const {
    auth: { allowDD },
  } = state;
  return {
    allowDD,
  };
}

export function isW4Enabled(state) {
  const {
    auth: { isW4Enabled },
  } = state;
  return {
    isW4Enabled,
  };
}

export function isEmContactEnabled(state) {
  const {
    auth: { isEmContactEnabled },
  } = state;
  return {
    isEmContactEnabled,
  };
}

export function isDdEnabled(state) {
  const {
    auth: { isDdEnabled },
  } = state;
  return {
    isDdEnabled,
  };
}

export function avatarInfo(state) {
  const {
    auth: { employee = {} },
  } = state;
  const firstName = employee?.fName ?? "";
  const lastName = employee?.lName ?? "";
  const middleName = employee?.mName ?? "";
  const wholeName = (firstName + " " + middleName).trim() + " " + lastName;
  const initials = firstName.substring(0, 1) + lastName.substring(0, 1);
  return {
    avatarInfo: {
      bgColor: stringToHslPastel(wholeName),
      text: initials,
      textColor: "#fff",
    },
  };
}

export function employeeFirstName(state) {
  const {
    auth: { employee },
  } = state;
  return {
    employeeFirstName: employee?.fName ?? "",
  };
}

export function employeeLastName(state) {
  const {
    auth: { employee },
  } = state;
  return {
    employeeLastName: employee?.lName ?? "",
  };
}

export function employeeFullName(state) {
  const {
    auth: { employee = {} },
  } = state;
  return {
    employeeFullName: `${employee?.fName ?? ""} ${employee?.lName ?? ""}`.trim(),
  };
}

export function employeeIsContractor(state) {
  const {
    auth: { employee = {} },
  } = state;
  return {
    employeeIsContractor: employee?.empType === "CONTRACT",
  };
}

export function employeeReadOnly(state) {
  const {
    auth: { employee = {} },
  } = state;
  return {
    employeeReadOnly: !!employee?.termDate, // True if employee was terminated.
  };
}

export function employerName(state) {
  const {
    auth: { company = {} },
  } = state;
  return {
    employerName: company?.name ?? "",
  };
}

export function currentProfileId(state) {
  return state?.auth?.profileId;
}

export function profileCount(state) {
  return {
    profileCount: state?.auth?.profileCount,
  };
}

export function showZayZoonButton(state) {
  const {
    auth: { showZayZoonButton },
  } = state;
  return {
    showZayZoonButton,
  };
}

export function showPerksButton(state) {
  const {
    auth: { showPerksButton },
  } = state;
  return {
    showPerksButton,
  };
}

export function showTimeKeeping(state) {
  const {
    auth: { showTimeKeeping },
  } = state;
  return {
    showTimeKeeping,
  };
}
