import React from "react";
import {
  Button,
  Card,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
// Local
import { ButtonSwitch, PopoverHelpIcon } from "../../../components";
import { useMobile } from "../../../themes";
import { useStyles } from "./EditStateW4Form.styles";

function _EditStateW4Form({
  errFields,
  formData,
  setFormProps,
  taxesWorkState,
  taxesWorkStateAbbr: stateAbbr,
  taxesWorkStateIsNY: isNY,
}) {
  const classes = useStyles();

  const filingStatusMenuItems = taxesWorkState?.filingStatuses?.map(status => (
    <MenuItem key={status} value={status}>
      {status}
    </MenuItem>
  ));

  // #region State
  const {
    filingStatus,
    isExempt,
    dependents,
    dependentsNYC,
    amountExtra,
    amountExtraNYC,
    amountExtraYonkers,
    resideNYC,
    resideYonkers,
  } = formData;
  const isMobile = useMobile();
  // #endregion
  // #region Events

  const onChangeFilingStatus = React.useCallback(
    e => setFormProps({ filingStatus: e.target.value }),
    [setFormProps],
  );
  const onChangeIsExempt = React.useCallback(
    e => setFormProps({ isExempt: e.currentTarget.value }),
    [setFormProps],
  );
  const onChangeResideNYC = React.useCallback(
    e =>
      setFormProps({ resideNYC: e.currentTarget.value, resideYonkers: "no" }),
    [setFormProps],
  );
  const onChangeResideYonkers = React.useCallback(
    e =>
      setFormProps({ resideYonkers: e.currentTarget.value, resideNYC: "no" }),
    [setFormProps],
  );
  const onChangeDependents = React.useCallback(
    e => setFormProps({ dependents: parseInt(e.currentTarget.value) || 0 }),
    [setFormProps],
  );
  const onChangeDependentsNYC = React.useCallback(
    e => setFormProps({ dependentsNYC: parseInt(e.currentTarget.value) || 0 }),
    [setFormProps],
  );
  const onChangeAmountExtra = React.useCallback(
    e => setFormProps({ amountExtra: parseFloat(e.currentTarget.value) || 0 }),
    [setFormProps],
  );
  const onChangeAmountExtraNYC = React.useCallback(
    e =>
      setFormProps({ amountExtraNYC: parseFloat(e.currentTarget.value) || 0 }),
    [setFormProps],
  );
  const onChangeAmountExtraYonkers = React.useCallback(
    e =>
      setFormProps({
        amountExtraYonkers: parseFloat(e.currentTarget.value) || 0,
      }),
    [setFormProps],
  );

  // #endregion

  return (
    <>
      <Grid item xs={12} style={{ marginBottom: 24 }}>
        <Typography variant="body1">
          Need help filling this out? Instructions can be found on form
          {isNY ? " IT-104" : " NJ-W4"}
          <br />
          {isNY ? (
            <a
              href="https://www.tax.ny.gov/pdf/current_forms/it/it2104_fill_in.pdf"
              target="BrandsStateW4Help"
              style={{ textDecoration: "none" }}
            >
              View instructions
            </a>
          ) : (
            <a
              href="https://www.state.nj.us/treasury/taxation/pdf/current/njw4.pdf"
              target="BrandsStateW4Help"
              style={{ textDecoration: "none" }}
            >
              View instructions
            </a>
          )}
          .
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="filing-status">Filing status</InputLabel>
          <Select
            value={filingStatus}
            onChange={onChangeFilingStatus}
            autoFocus={!isMobile}
          >
            {filingStatusMenuItems}
          </Select>
        </FormControl>
      </Grid>
      {!isNY && (
        <Grid item xs={12}>
          <div className={classes.formControl}>
            <InputLabel
              style={{
                marginBottom: 10,
                display: "flex",
                alignItems: "center",
              }}
            >
              <span>Exempt from state tax withholdings?</span>
              <PopoverHelpIcon id="exempt-help" children={exemptHelpNJ} />
            </InputLabel>
            <ButtonSwitch
              aria-label="Exemption choice"
              value={isExempt}
              onChange={onChangeIsExempt}
            >
              <Button value="yes">Yes</Button>
              <Button value="no">No</Button>
            </ButtonSwitch>
          </div>
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          label={
            !isNY || resideYonkers === "no"
              ? "Dependents"
              : "Dependents for New York State and Yonkers"
          }
          value={dependents}
          onChange={onChangeDependents}
          type="number"
          className={classes.formControl}
          margin="normal"
          error={!!errFields.dependents}
          helperText={errFields.dependents}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <PopoverHelpIcon
                  id="allowance-help-nyc"
                  children={isNY ? allowanceHelpNY : allowanceHelpNJ}
                />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Additional amount withheld"
          value={amountExtra}
          onChange={onChangeAmountExtra}
          type="number"
          className={classes.formControl}
          margin="normal"
          error={!!errFields.amountExtra}
          helperText={errFields.amountExtra}
          // disabled={!optionalAdj}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            endAdornment: (
              <InputAdornment position="end">
                <PopoverHelpIcon id="addtl-help" children={addtlHelp} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      {isNY && (
        <Grid item xs={12}>
          <Card className={classes.card}>
            <div className={classes.formControl}>
              <InputLabel>
                <span>Are you a resident of New York City?</span>
              </InputLabel>
              <ButtonSwitch
                aria-label="Residence choice"
                value={resideNYC}
                onChange={onChangeResideNYC}
              >
                <Button value="yes">Yes</Button>
                <Button value="no">No</Button>
              </ButtonSwitch>
            </div>
            {resideNYC === "yes" && (
              <>
                <TextField
                  label="Dependents for New York City"
                  value={dependentsNYC}
                  onChange={onChangeDependentsNYC}
                  type="number"
                  className={classes.formControl}
                  margin="normal"
                  error={!!errFields.dependentsNYC}
                  helperText={errFields.dependentsNYC}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <PopoverHelpIcon
                          id="allowance-help-nyc"
                          children={allowanceHelpNY}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Additional amount withheld for New York City"
                  value={amountExtraNYC}
                  onChange={onChangeAmountExtraNYC}
                  type="number"
                  className={classes.formControl}
                  margin="normal"
                  error={!!errFields.amountExtraNYC}
                  helperText={errFields.amountExtraNYC}
                  // disabled={!optionalAdj}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <PopoverHelpIcon
                          id="addtl-help-nyc"
                          children={addtlHelp}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            )}
          </Card>
          <Card className={classes.card}>
            <div className={classes.formControl}>
              <InputLabel>
                <span>Are you a resident of Yonkers?</span>
              </InputLabel>
              <ButtonSwitch
                aria-label="Residence choice"
                value={resideYonkers}
                onChange={onChangeResideYonkers}
              >
                <Button value="yes">Yes</Button>
                <Button value="no">No</Button>
              </ButtonSwitch>
            </div>
            {resideYonkers === "yes" && (
              <TextField
                label="Additional amount withheld for Yonkers"
                value={amountExtraYonkers}
                onChange={onChangeAmountExtraYonkers}
                type="number"
                className={classes.formControl}
                margin="normal"
                error={!!errFields.amountExtraYonkers}
                helperText={errFields.amountExtraYonkers}
                // disabled={!optionalAdj}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <PopoverHelpIcon
                        id="addtl-help-yonkers"
                        children={addtlHelp}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Card>
        </Grid>
      )}
      <Grid item xs={12}>
        <p>&nbsp;</p>
      </Grid>
    </>
  );
}

export const EditStateW4Form = React.memo(_EditStateW4Form);

const addtlHelp = (
  <Typography variant="caption">
    Enter any additional tax you want withheld each <strong>pay period</strong>.
  </Typography>
);

const allowanceHelpNY = (
  <Typography variant="caption">
    <strong>Withholding allowances</strong> You may not claim a withholding
    allowance for yourself or, if married, your spouse. Claim the number of
    withholding allowances you compute in Part 1 and Part 5 of the worksheet on
    page 4. If you want more tax withheld, you may claim fewer allowances.{" "}
    <strong>If you claim more than 14 allowances</strong>, your employer{" "}
    <strong>must send</strong> a copy of your <strong>Form IT-2104</strong> to
    the New York State Tax Department. You may then be asked to verify your
    allowances. If you arrive at negative allowances (less than zero) on lines 1
    or 2 and your employer cannot accommodate negative allowances,{" "}
    <strong>enter 0</strong>.
  </Typography>
);

const allowanceHelpNJ = (
  <Typography variant="caption">
    Enter the number of allowances you are claiming. Entering a number on this
    line will decrease the amount of withholding and could result in an
    underpayment on your return.
  </Typography>
);

const exemptHelpNJ = (
  <>
    <Typography variant="caption">
      <strong>Exemption from withholding.</strong> You may claim exemption from
      New Jersey Gross Income Tax Withholdings, if you meet one of the following
      conditions:
    </Typography>
    <ul>
      <li>
        <Typography variant="caption">
          Your filing status is <strong>SINGLE</strong> or{" "}
          <strong>MARRIED/CIVIL UNION PARTNER SEPARATE</strong> and your wages
          plus your taxable nonwage income will be $10,000 or less for the
          current year
        </Typography>
      </li>
      <li>
        <Typography variant="caption">
          Your filing status is{" "}
          <strong>MARRIED/CIVIL UNION COUPLE JOINT</strong>, and your wages
          combined with your spouse’s/civil union partner’s wages plus your
          taxable non wage income will be $20,000 or less for the current year.
        </Typography>
      </li>
      <li>
        <Typography variant="caption">
          Your filing status is <strong>HEAD OF HOUSEHOLD</strong> or{" "}
          <strong>QUALIFYING WIDOW(ER)/SURVIVING CIVIL UNION PARTNER</strong>{" "}
          and your wages plus your taxable nonwage income will be $20,000 or
          less for the current year
        </Typography>
      </li>
    </ul>
  </>
);
