import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  theme => ({
    formControl: {
      margin: theme.spacing(1),
      marginTop: 25,
      minWidth: 120,
    },
    formControl100: {
      margin: theme.spacing(1),
      marginTop: 25,
      minWidth: 120,
      width: "100%",
    },
    formControlSwitch: {
      display: "flex",
      paddingLeft: 20,
      justifyContent: "flex-end",
    },
    radioTextInput: {
      margin: theme.spacing(1),
      marginLeft: 35,
    },
    switch: {
      color: "rgba(0,0,0,0.6)",
      fontSize: 14,
    },
    largerBtn: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 40,
      paddingRight: 40,
    },
  }),
  {
    classNamePrefix: "EditAccountForm",
  },
);
