import { authDelete, authGet, authPost, authPut } from "../../lib";
import { UIActions } from "../ui/actions";

const type = {
  DD_ACCOUNT_CREATE_REQUEST: "DD_ACCOUNT_CREATE_REQUEST",
  DD_ACCOUNT_CREATE_FAILURE: "DD_ACCOUNT_CREATE_FAILURE",
  DD_ACCOUNT_CREATE_SUCCESS: "DD_ACCOUNT_CREATE_SUCCESS",

  DD_ACCOUNT_DELETE_REQUEST: "DD_ACCOUNT_DELETE_REQUEST",
  DD_ACCOUNT_DELETE_FAILURE: "DD_ACCOUNT_DELETE_FAILURE",
  DD_ACCOUNT_DELETE_SUCCESS: "DD_ACCOUNT_DELETE_SUCCESS",

  DD_ACCOUNT_GET_REQUEST: "DD_ACCOUNT_GET_REQUEST",
  DD_ACCOUNT_GET_FAILURE: "DD_ACCOUNT_GET_FAILURE",
  DD_ACCOUNT_GET_SUCCESS: "DD_ACCOUNT_GET_SUCCESS",

  DD_ACCOUNT_UPDATE_REQUEST: "DD_ACCOUNT_UPDATE_REQUEST",
  DD_ACCOUNT_UPDATE_FAILURE: "DD_ACCOUNT_UPDATE_FAILURE",
  DD_ACCOUNT_UPDATE_SUCCESS: "DD_ACCOUNT_UPDATE_SUCCESS",

  DD_ACCOUNT_UPDATE_LIST_REQUEST: "DD_ACCOUNT_UPDATE_LIST_REQUEST",
  DD_ACCOUNT_UPDATE_LIST_FAILURE: "DD_ACCOUNT_UPDATE_LIST_FAILURE",
  DD_ACCOUNT_UPDATE_LIST_SUCCESS: "DD_ACCOUNT_UPDATE_LIST_SUCCESS",

  DD_ACCOUNTS_LIST_REQUEST: "DD_ACCOUNTS_LIST_REQUEST",
  DD_ACCOUNTS_LIST_FAILURE: "DD_ACCOUNTS_LIST_FAILURE",
  DD_ACCOUNTS_LIST_SUCCESS: "DD_ACCOUNTS_LIST_SUCCESS",
};

export const DDErrors = {
  accountPercentGreaterThan100:
    "Deposit amounts should not add up to greater than 100%.",
  accountPercentLessThan100:
    "Deposit amounts do not add up to 100%. " +
    "The system will issue a physical check for any net leftover after " +
    "processing through all applicable bank accounts.",
  multipleAccountsRemaining: "Only one account should be marked remaining.",
};

export const DDActions = {
  type,

  /**
   * Creates a new direct-deposit account.
   * @param {DDAccount} account
   * @return {{ data:CreateDDAccountResult }}
   */
  createDDAccount(account) {
    return async dispatch => {
      /** @type {CreateDDAccountData} */
      const postData = {
        account,
      };
      dispatch({ type: type.DD_ACCOUNT_CREATE_REQUEST });
      dispatch(UIActions.setUILoading(true));
      const resp = await authPost(`/api/my/direct-deposits/accounts`, postData);
      const { data, error, errorFields } = resp;
      dispatch(UIActions.setUILoading(false));
      if (error && !errorFields) {
        dispatch(UIActions.showError());
      }
      dispatch({ type: type.DD_ACCOUNT_CREATE_SUCCESS, data, error });
      return resp;
    };
  },
  /**
   * Deletes a direct-deposit account.
   * @param {number} id
   */
  deleteDDAccount(id) {
    return async dispatch => {
      dispatch({ type: type.DD_ACCOUNT_DELETE_REQUEST });
      dispatch(UIActions.setUILoading(true));
      const { error } = await authDelete(
        `/api/my/direct-deposits/accounts/${id}`,
      );
      dispatch(UIActions.setUILoading(false));
      if (error) {
        dispatch(UIActions.showError());
      }
      dispatch({ type: type.DD_ACCOUNT_DELETE_SUCCESS });
    };
  },
  /**
   * Gets a single direct-deposit account from the server.
   * @return {{ data:GetDDAccountData }}
   */
  getDDAccount(id) {
    return async dispatch => {
      dispatch({ type: type.DD_ACCOUNT_GET_REQUEST });
      dispatch(UIActions.setUILoading(true));
      const resp = await authGet(`/api/my/direct-deposits/accounts/${id}`);
      const { data, error } = resp;
      dispatch(UIActions.setUILoading(false));
      if (error) {
        dispatch(UIActions.showError());
      }
      dispatch({
        type: type.DD_ACCOUNT_GET_SUCCESS,
        data,
        error,
      });
      return resp;
    };
  },
  /**
   * Gets the bank for a given routing number.
   * @returns {{ data: {
   *    bank: { name:string, routingNumber:string }
   * }, error: any}}
   */
  getDDBank(routingNumber) {
    return async () => {
      return await authGet(`/api/my/bank/${routingNumber}`);
    };
  },
  /**
   * Loads direct-deposit accounts from the server.
   * @return {{ data:LoadDDAccountsData }}
   */
  listDDAccounts() {
    return async dispatch => {
      dispatch({ type: type.DD_ACCOUNTS_LIST_REQUEST });
      dispatch(UIActions.setUILoading(true));
      const resp = await authGet("/api/my/direct-deposits/accounts");
      const { data, error } = resp;
      dispatch(UIActions.setUILoading(false));
      if (error) {
        dispatch(UIActions.showError());
      }
      dispatch({
        type: type.DD_ACCOUNTS_LIST_SUCCESS,
        data,
        error,
      });
      return resp;
    };
  },
  /**
   * Updates a direct-deposit account.
   * @param {DDAccount} account
   */
  updateDDAccount(account) {
    return async (dispatch, getState) => {
      const { id } = account;
      /** @type {{dd:{accounts:DDAccount[]}}} */
      const {
        dd: { accounts = [] },
      } = getState();
      const original = accounts.find(a => a.id === id);
      if (!original) {
        throw new Error("Cannot update: original account not found.");
      }
      /** @type {UpdateDDAccountData} */
      const data = {
        original,
        updated: account,
      };
      dispatch({ type: type.DD_ACCOUNT_UPDATE_REQUEST });
      dispatch(UIActions.setUILoading(true));
      var resp = await authPut(`/api/my/direct-deposits/accounts/${id}`, data);
      const { error, errorFields } = resp;
      dispatch(UIActions.setUILoading(false));
      if (error && !errorFields) {
        dispatch(UIActions.showError());
        dispatch({ type: type.DD_ACCOUNT_UPDATE_FAILURE, error: resp.error });
      } else {
        dispatch({ type: type.DD_ACCOUNT_UPDATE_SUCCESS });
      }
      return resp;
    };
  },
  /**
   * Updates multiple direct-deposit accounts at once.
   * @param {DDAccount[]} updates
   */
  updateDDAccounts(updates, sorting) {
    return async (dispatch, getState) => {
      const data = {
        updates: [],
        sorting,
      };
      updates.forEach(update => {
        let { id: originalId, priority } = update;
        // Swap id to update if priority changed.
        if (originalId !== priority) {
          update.id = priority;
        }
        /** @type {{dd:{accounts:DDAccount[]}}} */
        const {
          dd: { accounts = [] },
        } = getState();
        const original = accounts.find(a => a.id === originalId);
        data.updates.push({
          original,
          updated: update,
        });
        if (!original) {
          throw new Error("Cannot update: original account not found.");
        }
      });
      dispatch({ type: type.DD_ACCOUNT_UPDATE_LIST_REQUEST });
      dispatch(UIActions.setUILoading(true));
      const resp = await authPut(`/api/my/direct-deposits/accounts`, data);
      dispatch(UIActions.setUILoading(false));
      if (resp.error) {
        if (resp.error.errors?.[0]?.code === "OriginalChanged") {
          dispatch(UIActions.showError("Accounts changed. Please try again."));
          return {};
        } else {
          dispatch(UIActions.showError());
        }
      }
      dispatch({ type: type.DD_ACCOUNT_UPDATE_LIST_SUCCESS });
      return resp;
    };
  },
};

export const DDAccountType = {
  Checking: "Checking",
  Savings: "Savings",
};
/**
 * The DD Split method as stored on the server.
 */
export const DDSplitMethod = {
  Flat: "Flat Split",
  Percent: "Percent Split",

  fromSplitOption(option) {
    return option === DDSplitOption.Flat
      ? DDSplitMethod.Flat
      : DDSplitMethod.Percent;
  },
};
/**
 * The DD Split option as chosen by the user.
 */
export const DDSplitOption = {
  /**
   * Always matches `DDSplitMethod.Flat`.
   */
  Flat: "Flat",
  /**
   * Matches `DDSplitMethod.Percent` when the DD account `amount` is less than
   * `1`.
   */
  Percent: "Percent",
  /**
   * Matches `DDSplitMethod.Percent` when the DD account `amount` is `1`
   * (i.e. `100%`).
   */
  Remaining: "Remaining",

  /**
   * Returns the `DDSplitOption` to use for the given account.
   * In cases where `Remaining` is returned and there is only one account, the
   * calling code may decide to display `100%` instead.
   * @param {DDAccount} account
   */
  fromAccount(account) {
    if (account.split === DDSplitMethod.Flat) {
      return DDSplitOption.Flat;
    }
    if (account.amount === 1) {
      return DDSplitOption.Remaining;
    }
    return DDSplitOption.Percent;
  },
  /**
   * Returns an amount to save to an account based on the chosen split option.
   * @param {"Flat" | "Percent" | "Remaining"} value
   * @param {string | number} amountFlat
   * @param {string | number} amountPercent
   */
  toAmount(value, amountFlat, amountPercent) {
    return value === DDSplitOption.Flat
      ? parseFloat(amountFlat)
      : value === DDSplitOption.Percent
      ? parseFloat(amountPercent) / 100
      : 1; // Remaining
  },
};

export const DDAccountStatus = {
  Active: "Active",
  PreNote: "Pre-Note",
};

export const DDValidAccountIds = [1, 2, 3, 4];

/**
 * @typedef {object} DDAccount
 * @property {string} accountNumber
 * @property {"Checking" | "Savings" | "None"} accountType See `DDAccountType`
 * @property {number} amount A flat dollar amount or a percentage in decimal
 * format, depending on the value of the `split` field. For example, `100%`
 * is formatted as `1.00` and `35%"` is `0.35`.
 * @property {string} bankName
 * @property {boolean} enabled True if the account is enabled.
 * @property {number} id Id of the account (1 - 4).
 * @property {number} lastDeposit Amount of last deposit in numeric form.
 * @property {string} lastDepositDate Date of last deposit in ISO-8601 format.
 * @property {number} lastPayroll Last payroll id.
 * @property {string} name User-assigned name of the account.
 * @property {number} priority Priority number of the account (1 - 4).
 * @property {string} routingNumber
 * @property {"Percent Split" | "Flat Split"} split See `DDSplitMethod`
 * @property {"Pre-Note" | "Active"} status See `DDAccountStatus`
 *
 * @typedef {object} CreateDDAccountData
 * @property {DDAccount} account
 *
 * @typedef {object} CreateDDAccountResult
 * @property {number} id
 *
 * @typedef {object} GetDDAccountData
 * @property {DDAccount} account
 *
 * @typedef {object} LoadDDAccountsData
 * @property {DDAccount[]} accounts
 *
 * @typedef {object} UpdateDDAccountData
 * @property {DDAccount} original
 * @property {DDAccount} updated
 *
 */
