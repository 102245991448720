import React from "react";
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
// Local
import { DialogSaveCancelButtons, ErrorIcon } from "../../../../components";
import {
  decimalToPercent,
  mapToArray,
  useEditByIdState,
  useFocus,
  useInputValue,
  useOnMount,
} from "../../../../lib";
import {
  connectView,
  ddAccounts,
  ddAccountsTotalPercent,
  DDActions,
  DDErrors,
  DDSplitMethod,
  DDSplitOption,
} from "../../../../state";
import { useMobile } from "../../../../themes";
import { useInputStyles, useStyles } from "./EditAmountsForm.styles";

/** @type {import("../../../../lib").UseInputValueOptions<string>} */
const flatInputValueOptions = {
  mapValue(value) {
    var n = parseInt(value);
    return isNaN(n) ? "" : n < 0 ? undefined : value;
  },
};

/** @type {import("../../../../lib").UseInputValueOptions<string>} */
const percentInputValueOptions = {
  mapValue(value) {
    var n = parseInt(value);
    return isNaN(n) ? "" : n < 0 || n > 100 ? undefined : value;
  },
};

const AmountEditor = React.memo(
  /**
   * @typedef {object} AmountEditorProps
   * @property {import("../../../../state").DDAccount} account
   * @property {(id:number, account:import("../../../../state").DDAccount)=>void} updateAccount
   * @property {number} remainingAccountId
   *
   * @param {AmountEditorProps} props
   */
  function AmountEditor({ account /*, remainingAccountId */, updateAccount }) {
    const isMobile = useMobile();
    const classes = useInputStyles();
    // ## State
    const loaded = React.useRef(false);
    const initialSplit = DDSplitOption.fromAccount(account);
    const [initialValues] = React.useState({
      flat: initialSplit === DDSplitOption.Flat ? "" + account.amount : "",
      percent:
        initialSplit === DDSplitOption.Percent
          ? "" + decimalToPercent(account.amount)
          : "",
    });
    const [splitOption, onChangeSplitOption, setSplitOption] = useInputValue(
      initialSplit,
      // This works, but decided to use an error message instead:
      // {
      //   mapValue: React.useCallback(
      //     value => {
      //       // Return `undefined` to disable selection of Remaining when an
      //       // account with Remaining selected already exists...
      //       return value === DDSplitOption.Remaining && remainingAccountId
      //         ? undefined
      //         : value;
      //     },
      //     [remainingAccountId],
      //   ),
      // },
    );
    const [amountFlat, onChangeAmountFlat, setAmountFlat] = useInputValue(
      initialValues.flat,
      flatInputValueOptions,
    );
    const [
      amountPercent,
      onChangeAmountPercent,
      setAmountPercent,
    ] = useInputValue(initialValues.percent, percentInputValueOptions);

    const amountFlatEl = React.useRef();
    const amountPercentEl = React.useRef();

    const [amountFlatHasFocus, amountFlatFocusProps] = useFocus();
    const [amountPercentHasFocus, amountPercentFocusProps] = useFocus();

    // # Effects

    // When split option changes...
    React.useEffect(() => {
      if (splitOption === DDSplitOption.Remaining) {
        setAmountPercent("");
        setAmountFlat("");
      } else if (loaded.current) {
        /** @type {HTMLInputElement} */
        let el;
        if (splitOption === DDSplitOption.Flat) {
          el = amountFlatEl.current;
        } else if (splitOption === DDSplitOption.Percent) {
          el = amountPercentEl.current;
        }
        if (el) {
          el.focus();
        }
      }
    }, [splitOption, setAmountFlat, setAmountPercent]);
    // When flat amount input gets focus...
    React.useEffect(() => {
      if (amountFlatHasFocus) {
        setSplitOption(DDSplitOption.Flat);
        setAmountPercent("");
      }
    }, [amountFlatHasFocus, setSplitOption, setAmountPercent, initialValues]);
    // When percent amount input gets focus...
    React.useEffect(() => {
      if (amountPercentHasFocus) {
        setSplitOption(DDSplitOption.Percent);
        setAmountFlat("");
      }
    }, [amountPercentHasFocus, setSplitOption, setAmountFlat, initialValues]);
    // When any value changes...
    React.useEffect(() => {
      updateAccount(account.id, {
        ...account,
        split: DDSplitMethod.fromSplitOption(splitOption),
        amount: DDSplitOption.toAmount(splitOption, amountFlat, amountPercent),
      });
      loaded.current = true;
    }, [account, updateAccount, splitOption, amountFlat, amountPercent]);

    return (
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="Split Option"
          name="splitOption"
          onChange={onChangeSplitOption}
          row={!isMobile}
          value={splitOption}
        >
          <FormControlLabel
            control={<Radio color="primary" />}
            label={
              <TextField
                label=""
                className={classes.amountFlatText}
                margin="normal"
                value={amountFlat}
                onChange={onChangeAmountFlat}
                type="number"
                InputProps={{
                  inputRef: amountFlatEl,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  ...amountFlatFocusProps,
                }}
                inputProps={{
                  min: 0,
                }}
              />
            }
            labelPlacement="end"
            className={classes.amountFlatCtrl}
            value={DDSplitOption.Flat}
          />

          <FormControlLabel
            control={<Radio color="primary" />}
            label={
              <TextField
                className={classes.amountPercentText}
                label=""
                margin="normal"
                onChange={onChangeAmountPercent}
                type="number"
                value={amountPercent}
                InputProps={{
                  inputRef: amountPercentEl,
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                  ...amountPercentFocusProps,
                }}
                inputProps={{
                  min: 0,
                  max: 100,
                }}
              />
            }
            labelPlacement="end"
            className={classes.amountPercentCtrl}
            value={DDSplitOption.Percent}
          />
          <FormControlLabel
            control={<Radio color="primary" />}
            label="Remaining"
            labelPlacement="end"
            className={classes.amountRemainingCtrl}
            value={DDSplitOption.Remaining}
          />
        </RadioGroup>
      </FormControl>
    );
  },
);

/**
 * @typedef {object} EditAmountsFormProps
 * @property {typeof import("../../../../state").DDActions} actions
 * @property {import("../../../../state").DDAccount[]} ddAccounts
 * @property {function} onCancel
 * @property {function} onComplete
 *
 * @param {EditAmountsFormProps} props
 */
function _EditAmountsForm({
  actions: { listDDAccounts, updateDDAccounts },
  ddAccounts,
  onCancel,
  onComplete,
}) {
  const classes = useStyles();

  const [error, setError] = React.useState("");

  const [accounts, { update: updateAccount }] = useEditByIdState();

  // const remainingAccountId = hasAccountRemainingId(accounts);

  async function onSave(e) {
    e.preventDefault();
    await updateDDAccounts(mapToArray(accounts));
    onComplete();
  }

  React.useEffect(() => {
    const total = getTotalPercentage(accounts);
    if (total < 1) {
      setError(DDErrors.accountPercentLessThan100);
    } else if (total > 1) {
      setError(DDErrors.accountPercentGreaterThan100);
    } else if (hasMultipleRemaining(accounts)) {
      setError(DDErrors.multipleAccountsRemaining);
    } else {
      setError("");
    }
  }, [accounts, setError]);

  React.useEffect(() => {
    if (ddAccounts.filter(row => row.enabled).length < 1) {
      setError(value => {
        return value || "No accounts. Please add or enable an account.";
      });
    }
  }, [ddAccounts]);

  useOnMount(() => {
    listDDAccounts();
  });

  return (
    <form onSubmit={onSave}>
      <Grid container>
        <Grid item xs={12}>
          <Typography className={classes.subtitle}>
            Set up your accounts so they add up to 100%
          </Typography>
          <br />
        </Grid>
        <Grid item xs={12}>
          <Table className={classes.table}>
            <TableBody>
              {ddAccounts.map(
                row =>
                  row.enabled && (
                    <TableRow key={row.id} className={classes.tableRow}>
                      <TableCell className={classes.nameCell}>
                        <Typography>
                          <strong>
                            <em>{row.name}</em>
                          </strong>
                          <br />
                          {row.bankName && (
                            <strong>
                              <em>{row.bankName}</em>
                              <br />
                            </strong>
                          )}
                          <em>{row.accountType}</em>
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.inputCell}>
                        <AmountEditor
                          account={row}
                          // remainingAccountId={remainingAccountId}
                          updateAccount={updateAccount}
                        />
                      </TableCell>
                    </TableRow>
                  ),
              )}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.errorContent}>
            {error ? (
              <>
                <ErrorIcon />
                &nbsp;
                <span>{error}</span>
              </>
            ) : (
              <span>&nbsp;</span>
            )}
          </div>
        </Grid>
        <DialogSaveCancelButtons onCancel={onCancel} />
        <Grid item xs={12}>
          <p>&nbsp;</p>
        </Grid>
      </Grid>
    </form>
  );
}

/**
 * @param {{ [id:string]:{
 *  id:number,
 *  amount:number,
 *  split:string
 * }}} accounts
 */
function getTotalPercentage(accounts) {
  // NOTE: Transforming editable accounts for selector...
  return ddAccountsTotalPercent({
    dd: {
      accounts: mapToArray(accounts),
    },
  }).ddAccountsTotalPercent;
}

// function hasAccountRemainingId(accounts) {
//   return Object.keys(accounts).reduce(
//     (id, key) =>
//       id ||
//       (DDSplitOption.fromAccount(accounts[key]) === DDSplitOption.Remaining
//         ? accounts[key].id
//         : 0),
//     0,
//   );
// }

function hasMultipleRemaining(accounts) {
  return (
    Object.keys(accounts).reduce(
      (count, key) =>
        count +
        (DDSplitOption.fromAccount(accounts[key]) === DDSplitOption.Remaining
          ? 1
          : 0),
      0,
    ) > 1
  );
}

export const EditAmountsForm = connectView(
  _EditAmountsForm,
  state => ({
    ...ddAccounts(state),
  }),
  [DDActions],
);

EditAmountsForm.defaultProps = {
  title: "Edit amounts",
  maxWidth: "md",
};
