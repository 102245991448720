import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  theme => ({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
      paddingLeft: 20,
    },
  }),
  {
    classNamePrefix: "PaystubTaxTable",
  },
);

export const usePanelStyles = makeStyles(
  theme => ({
    root: {
      border: "none",
      boxShadow: "0px 1px 1px -1px rgba(0,0,0,0.5)",
      margin: 2,
      paddingBottom: 10,
      paddingTop: 10,
    },
    expanded: {
      margin: "2px !important",
    },
  }),
  {
    classNamePrefix: "PaystubTaxTablePanel",
  },
);

export const usePanelSummaryStyles = makeStyles(
  theme => ({
    root: {
      minHeight: 7 * 4,
      "&$expanded": {
        margin: 0,
        paddingRight: 12,
        minHeight: 7 * 4,
      },
    },
    content: {
      margin: "4px 0",
      "&$expanded": {
        margin: "4px 0",
      },
    },
    expanded: {
      margin: 0,
    },
    expandIcon: {
      padding: 3,
      marginLeft: 20,
    },
  }),
  {
    classNamePrefix: "PaystubTaxTableSummary",
  },
);

export const usePanelDetailStyles = makeStyles(
  theme => ({
    root: {
      padding: 0,
      margin: 0,
    },
  }),
  {
    classNamePrefix: "PaystubTaxTableDetails",
  },
);
