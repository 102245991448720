import React from "react";
import { Box, Chip, Grid, Typography } from "@material-ui/core";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
// Local
import {
  DialogSaveCancelButtons,
  DragIndicatorIcon,
} from "../../../../components";
import { addIf, arrayMove, maskUpToLast4, useOnMount } from "../../../../lib";
import {
  connectView,
  ddAccounts,
  DDActions,
  renderDDAmount,
} from "../../../../state";
import { useMobile } from "../../../../themes";
import { useAccountRowStyles, useStyles } from "./ReorderAccountsForm.styles";

function _DragHandle() {
  return <DragIndicatorIcon color="disabled" />;
}

const DragHandle = SortableHandle(React.memo(_DragHandle));

function _AccountRow({ renderDDAmount, row }) {
  const classes = useAccountRowStyles();
  /** @type {string} */
  const amount = renderDDAmount(row);
  return (
    <Grid item xs={12}>
      <Box boxShadow={3} mt={2} p={2} className={classes.row}>
        {/* <Box className={classes.cellPriority}>{row.id}</Box> */}
        <Box className={classes.cellBank}>
          {addIf(row.bankName, " / ")}
          {row.accountType}
        </Box>
        <Box className={classes.cellName}>{row.name}</Box>
        <Box className={classes.cellNum}>
          {maskUpToLast4(row.accountNumber, 2)}
        </Box>
        <Box className={classes.cellAmt}>
          <div className={classes.textAmt}>{amount}</div>
          {!row.enabled ? (
            <Chip label="disabled" />
          ) : (
            <div className={classes.noChip}>&nbsp;</div>
          )}
        </Box>
        <Box className={classes.cellBlank1}></Box>
        <Box className={classes.cellHandle}>
          <DragHandle />
        </Box>
      </Box>
    </Grid>
  );
}
const AccountRow = SortableElement(_AccountRow);

function _AccountList({ renderDDAmount, rows }) {
  return (
    <Grid item container>
      {rows.map((row, index) => (
        <AccountRow
          key={row.id}
          index={index}
          renderDDAmount={renderDDAmount}
          row={row}
        />
      ))}
    </Grid>
  );
}
const AccountList = SortableContainer(_AccountList);

/**
 * @typedef {object} ReorderAccountsFormProps
 * @property {typeof import("../../../../state").DDActions} actions
 * @property {import("../../../../state").DDAccount[]} ddAccounts
 * @property {function} onCancel
 * @property {function} onComplete
 *
 * @param {ReorderAccountsFormProps} props
 */
function _ReorderAccountsForm({
  actions: { listDDAccounts, updateDDAccounts },
  ddAccounts,
  onCancel,
  onComplete,
  renderDDAmount,
}) {
  const classes = useStyles();
  const isMobile = useMobile();

  const [accounts, updateAccounts] = React.useState([]);

  /** @param {React.SyntheticEvent<HTMLButtonElement} e */
  const onSave = React.useCallback(
    async e => {
      e.preventDefault();
      const { error } = await updateDDAccounts(accounts, true);
      if (!error) {
        onComplete();
      }
    },
    [accounts, updateDDAccounts, onComplete],
  );

  const onSortEnd = React.useCallback(
    ({ oldIndex, newIndex }) => {
      const updated = arrayMove(accounts, oldIndex, newIndex);
      updated.forEach((acct, i) => {
        acct.priority = i + 1;
      });
      updateAccounts(updated);
    },
    [accounts, updateAccounts],
  );

  React.useEffect(() => {
    updateAccounts(ddAccounts.map(acct => ({ ...acct })));
  }, [ddAccounts]);

  useOnMount(() => {
    listDDAccounts();
  });

  return (
    <form onSubmit={onSave}>
      <Grid container>
        <Grid item xs={12}>
          <Typography>
            Drag and drop accounts to change their priority.
          </Typography>
          <br />
        </Grid>
        <Grid item xs={12}>
          <AccountList
            renderDDAmount={renderDDAmount}
            rows={accounts}
            helperClass={classes.sort}
            onSortEnd={onSortEnd}
            useDragHandle={isMobile}
          />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.errorContent}>
            <span>&nbsp;</span>
          </div>
        </Grid>
        <DialogSaveCancelButtons onCancel={onCancel} />
        <Grid item xs={12}>
          <p>&nbsp;</p>
        </Grid>
      </Grid>
    </form>
  );
}

export const ReorderAccountsForm = connectView(
  _ReorderAccountsForm,
  state => ({
    ...ddAccounts(state),
    ...renderDDAmount(state),
  }),
  [DDActions],
);

ReorderAccountsForm.defaultProps = {
  title: "Prioritize accounts",
  maxWidth: "md",
};
