import React from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
// Local
import {
  connectView,
  employeeReadOnly,
  renderAmount,
  taxesLocal,
  taxesWorkState,
} from "../../../state";
import { EditStateW4Wizard } from "./EditStateW4Wizard";

function _StateTaxCard({
  classes,
  employeeReadOnly,
  renderAmount,
  taxesLocal,
  taxesWorkState,
}) {
  const [updating, setUpdating] = React.useState(false);

  function onClickUpdate() {
    setUpdating(true);
  }
  function onCancelUpdate() {
    setUpdating(false);
  }
  function onCompletedUpdate() {
    setUpdating(false);
  }
  return (
    <Box boxShadow={3} className={classes.w4box}>
      <div className={classes.subtitlebar}>
        <h3 className={classes.subtitle}>State</h3>
        <h3 className={classes.subtitle}>{taxesWorkState.stateAbbr}</h3>
      </div>
      <Table className={classes.table}>
        <TableBody>
          <TableRow>
            <TableCell>Filing status</TableCell>
            <TableCell className={classes.valueCell}>
              {taxesWorkState.filingStatus}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Withholding status</TableCell>
            <TableCell className={classes.valueCell}>
              {taxesWorkState.isExempt ? "Exempt" : "Not Exempt"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Total allowances</TableCell>
            <TableCell className={classes.valueCell}>
              {taxesWorkState.dependents}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Additional amount withheld</TableCell>
            <TableCell className={classes.valueCell}>
              {renderAmount(taxesWorkState.amountExtra)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {taxesLocal.map(item => (
        <React.Fragment key={item.localId}>
          <div className={classes.subtitlebar}>
            <h3 className={classes.subtitle}>Local</h3>
            <h3 className={classes.subtitle}>{item.name}</h3>
          </div>
          <Table className={classes.table}>
            <TableBody>
              <TableRow>
                <TableCell>Dependents</TableCell>
                <TableCell className={classes.valueCell}>
                  {item.dependents}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Additional amount withheld</TableCell>
                <TableCell className={classes.valueCell}>
                  {renderAmount(item.amountExtra)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </React.Fragment>
      ))}
      {!employeeReadOnly && (
        <Button
          color="primary"
          className={classes.updateButton}
          onClick={onClickUpdate}
        >
          UPDATE
        </Button>
      )}
      <EditStateW4Wizard
        open={updating}
        onCancel={onCancelUpdate}
        onComplete={onCompletedUpdate}
      />
    </Box>
  );
}
export const StateTaxCard = connectView(_StateTaxCard, state => ({
  ...employeeReadOnly(state),
  ...renderAmount(state),
  ...taxesLocal(state),
  ...taxesWorkState(state),
}));
