import React from "react";
import ReactPieChart from "react-minimal-pie-chart";

function _MiniDonutChart({ gross, net, deds, taxes }) {
  /** @type {import("react-minimal-pie-chart").PieChartData[]} */
  const chartData = [
    {
      value: (net / gross) * 100,
      color: "#1CF2E2",
    },
    {
      value: (deds / gross) * 100,
      color: "#2d12a3",
    },
    {
      value: (taxes / gross) * 100,
      color: "#dae1e9",
    },
  ];
  return (
    <div style={{ width: 34, height: 34 }}>
      <ReactPieChart
        data={chartData}
        lineWidth={30}
        startAngle={-90}
        totalValue={100}
      />
    </div>
  );
}

export const MiniDonutChart = React.memo(_MiniDonutChart);
