import React from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Switch,
} from "@material-ui/core";
// Local
import { ButtonSwitch, DialogSaveCancelButtons } from "../../../../components";
import {
  decimalToPercent,
  useInputCheck,
  useInputValue,
  useOnMount,
  useTimeout,
} from "../../../../lib";
import {
  connectView,
  DDAccountType,
  DDActions,
  DDSplitMethod,
  DDSplitOption,
} from "../../../../state";
import { useMobile } from "../../../../themes";
import { useStyles } from "./EditAccountForm.styles";

const notFoundBankInfo = {
  name: "",
  routingNumber: "",
};

/**
 * @typedef {object} EditAccountFormProps
 * @property {typeof import("../../../../state").DDActions} actions
 * @property {number} id
 * @property {function} onCancel
 * @property {function} onComplete
 *
 * @param {EditAccountFormProps} props
 */
function _EditAccountForm({
  actions: { createDDAccount, getDDAccount, getDDBank, updateDDAccount },
  id,
  onCancel,
  onComplete,
}) {
  const classes = useStyles();

  const loaded = React.useRef(false);
  const [bankInfo, setBankInfo] = React.useState(null);
  const [routingNum, onChangeRoutingNum, setRoutingNum] = useInputValue("");
  const [acctNum, onChangeAcctNum, setAcctNum] = useInputValue("");
  const [
    confirmAcctNum,
    onChangeConfirmAcctNum,
    setConfirmAcctNum,
  ] = useInputValue("");
  const [splitOption, onChangeSplitOption, setSplitOption] = useInputValue(
    DDSplitOption.Remaining,
  );
  const [amountFlat, onChangeAmountFlat, setAmountFlat] = useInputValue("");
  const [
    amountPercent,
    onChangeAmountPercent,
    setAmountPercent,
  ] = useInputValue("");
  const [acctType, onChangeAcctType, setAcctType] = useInputValue(
    DDAccountType.Checking,
  );
  const [acctName, onChangeAcctName, setAcctName] = useInputValue("");
  const [enabled, onChangeEnabled, setEnabled] = useInputCheck(true);
  const [errFields, setErrFields] = React.useState({});

  const isMobile = useMobile();

  const amountFlatEl = React.useRef();
  const amountPercentEl = React.useRef();

  const searchedBank = bankInfo !== null;

  useTimeout(
    async () => {
      const num = ("" + routingNum).trim();
      if (num.length >= 9) {
        if (bankInfo && bankInfo.routingNumber === num) {
          return;
        }
        const { data } = await getDDBank(num);
        setBankInfo(data.bank || notFoundBankInfo);
      } else if (num.length > 0) {
        setBankInfo(notFoundBankInfo);
      }
    },
    [routingNum],
    1000,
  );

  React.useEffect(() => {
    if (!loaded.current) {
      return;
    }
    /** @type {HTMLInputElement} */
    let el;
    if (splitOption === DDSplitOption.Flat) {
      el = amountFlatEl.current;
    } else if (splitOption === DDSplitOption.Percent) {
      el = amountPercentEl.current;
    }
    if (el) {
      el.focus();
    }
  }, [splitOption]);

  async function onSave(e) {
    e.preventDefault();
    if (acctNum !== confirmAcctNum) {
      setErrFields(state => ({
        ...state,
        accountNumber: "Account numbers do not match.",
        confirmAcctNum: "Account numbers do not match.",
      }));
      return;
    }
    /** @type {import("../../../../state").DDAccount} */
    const accountToSave = {
      routingNumber: routingNum,
      accountNumber: acctNum,
      split:
        splitOption === DDSplitOption.Flat
          ? DDSplitMethod.Flat
          : DDSplitMethod.Percent,
      amount:
        splitOption === DDSplitOption.Flat
          ? parseFloat(amountFlat)
          : splitOption === DDSplitOption.Percent
          ? parseFloat(amountPercent) / 100
          : 1,
      accountType: acctType,
      name: acctName,
      enabled,
    };
    var resp;
    if (id === 0) {
      // const {
      //   data: { id: createdId },
      // } =
      resp = await createDDAccount(accountToSave);
      // console.log("Created DD Account with Id: ", createdId);
    } else {
      accountToSave.id = id;
      resp = await updateDDAccount(accountToSave);
      // console.log("Updated DD Account with Id: ", id);
    }
    const { errorFields, error } = resp;
    if (errorFields) {
      setErrFields(errorFields);
    } else if (!error) {
      onComplete();
    }
  }

  async function loadAccount() {
    const {
      data: { account },
    } = await getDDAccount(id);
    // console.log("Loaded DD Account: ", account);
    setBankInfo({
      name: account.bankName,
      routingNumber: account.routingNumber,
    });
    setRoutingNum(account.routingNumber || "");
    setAcctNum(account.accountNumber || "");
    setConfirmAcctNum(account.accountNumber || "");
    const split = DDSplitOption.fromAccount(account);
    setSplitOption(split);
    if (split === DDSplitOption.Flat) {
      setAmountFlat(account.amount || "");
    } else if (split === DDSplitOption.Percent) {
      setAmountPercent(decimalToPercent(account.amount) || "");
    }
    setAcctType(account.accountType);
    setAcctName(account.name || "");
    setEnabled(account.enabled || false);
    loaded.current = true;
  }

  useOnMount(() => {
    if (id > 0) {
      loadAccount();
    }
  });

  if (id === null || id === undefined) {
    return null;
  }
  return (
    <form onSubmit={onSave}>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.formControlSwitch}>
            <FormControlLabel
              className={classes.switch}
              control={
                <Switch
                  color="primary"
                  checked={enabled}
                  onChange={onChangeEnabled}
                />
              }
              label="Enabled"
              labelPlacement="start"
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.formControl100}>
            <InputLabel style={{ marginBottom: 10 }}>Account type</InputLabel>
            <ButtonSwitch
              aria-label="Account type"
              name="accountType"
              onChange={onChangeAcctType}
              value={acctType}
            >
              <Button value={DDAccountType.Checking} size="small">
                Checking
              </Button>
              <Button value={DDAccountType.Savings} size="small">
                Savings
              </Button>
            </ButtonSwitch>
          </div>
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoFocus={!isMobile}
            className={classes.formControl100}
            label="Account nickname"
            margin="normal"
            onChange={onChangeAcctName}
            value={acctName}
            inputProps={{
              maxLength: 50,
            }}
            error={!!errFields.name}
            helperText={errFields.name}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl100}
            label="Routing number"
            margin="normal"
            onChange={onChangeRoutingNum}
            value={routingNum}
            inputProps={{
              maxLength: 9,
            }}
            error={!!errFields.routingNumber}
            helperText={
              errFields.routingNumber ||
              (searchedBank && bankInfo ? bankInfo.name || "" : "")
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl100}
            label="Account number"
            margin="normal"
            onChange={onChangeAcctNum}
            value={acctNum}
            inputProps={{
              maxLength: 17,
            }}
            error={!!errFields.accountNumber}
            helperText={errFields.accountNumber}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl100}
            label="Confirm account number"
            margin="normal"
            onChange={onChangeConfirmAcctNum}
            value={confirmAcctNum}
            error={!!errFields.confirmAcctNum}
            helperText={errFields.confirmAcctNum}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" className={classes.formControl}>
            <RadioGroup
              aria-label="Split Option"
              name="splitOption"
              onChange={onChangeSplitOption}
              value={splitOption}
            >
              <FormControlLabel
                control={<Radio color="primary" />}
                label="Dollar Amount"
                value={DDSplitOption.Flat}
              />
              {splitOption === DDSplitOption.Flat && (
                <TextField
                  className={classes.radioTextInput}
                  label=""
                  margin="normal"
                  onChange={onChangeAmountFlat}
                  type="number"
                  value={amountFlat}
                  InputProps={{
                    inputRef: amountFlatEl,
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  error={!!errFields.amount}
                  helperText={errFields.amount}
                />
              )}
              <FormControlLabel
                control={<Radio color="primary" />}
                label="Percentage"
                value={DDSplitOption.Percent}
              />
              {splitOption === DDSplitOption.Percent && (
                <TextField
                  className={classes.radioTextInput}
                  label=""
                  margin="normal"
                  onChange={onChangeAmountPercent}
                  type="number"
                  value={amountPercent}
                  InputProps={{
                    inputRef: amountPercentEl,
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  error={!!errFields.amount}
                  helperText={errFields.amount}
                />
              )}
              <FormControlLabel
                control={<Radio color="primary" />}
                label="Entire Remaining Net"
                value={DDSplitOption.Remaining}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <p>&nbsp;</p>
        </Grid>
        <DialogSaveCancelButtons onCancel={onCancel} />
        <Grid item xs={12}>
          <p>&nbsp;</p>
        </Grid>
      </Grid>
    </form>
  );
}

export const EditAccountForm = connectView(_EditAccountForm, [DDActions]);

EditAccountForm.defaultProps = {
  title: "Edit account",
};
