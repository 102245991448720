import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(
  theme => ({
    root: {
      display: "flex",
      flexDirection: "row",
    },
    /* Styles applied to each dot if `variant="dots"`. */
    dot: {
      backgroundColor: theme.palette.action.disabled,
      borderRadius: "50%",
      width: 16,
      height: 16,
      margin: "0 4px",
    },
    /* Styles applied to a dot if `variant="dots"` and this is the active step. */
    dotActive: {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  {
    classNamePrefix: "ProgressDots",
  },
);
/**
 * @typedef {object} ProgressDotsProps
 * @property {number} activeStep
 * @property {string} [className]
 * @property {number} steps
 * @property {React.StyleHTMLAttributes} [style]
 *
 * @param {ProgressDotsProps} props
 */
function _ProgressDots({ activeStep, className, steps, style }) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)} style={style}>
      <div style={{ flex: 1 }}></div>
      {[...new Array(steps)].map((_, index) => (
        <div
          key={index}
          className={clsx(classes.dot, {
            [classes.dotActive]: index === activeStep,
          })}
        />
      ))}
      <div style={{ flex: 1 }}></div>
    </div>
  );
}

export const ProgressDots = React.memo(_ProgressDots);
